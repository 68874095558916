import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import './StatusModal.css';
import '../../resources/StandardPage.css';
import Modal from 'react-modal';
import { googleVerificationService } from '../../services/GoogleVerificationService';
import ReCAPTCHA from 'react-google-recaptcha';
import { infoFormService } from '../../services/InfoFormService';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(13, 8, 32, 0.9)',
    overflow: 'scroll'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto'
  }
};

class StatusModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      googleRecapchaValue: '',
      disableSubmit: false
    };

    this.recaptchaRefStatusModal = React.createRef();
  }

  updateField = event => {
    let id = this.state.id;
    id = event.target.value;
    this.setState({
      id
    });
  };

  onChange = value => {
    this.setState({ googleRecapchaValue: value });
  };

  resetState = () => {
    this.setState({ id: '' });
    this.recaptchaRefStatusModal.current.reset();
  };

  checkStatus = event => {
    event.preventDefault();
    this.setState({ disableSubmit: true });

    googleVerificationService
      .verifyToken(this.state.googleRecapchaValue, true)
      .then(response => {
        if (response.data.success) {
          infoFormService
            .statusCheckEmail(this.state.id)
            .then(response => {
              this.props.notificationSystem.addNotification({
                message:
                  'O sa primiti un email cu statusul cererii dumneavoastra.',
                level: 'success',
                position: 'tc'
              });
              this.setState({ disableSubmit: false });
              this.resetState();
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.props.notificationSystem.addNotification({
                  message: error.response.data.message,
                  level: 'error',
                  position: 'tc'
                });
              } else {
                this.props.notificationSystem.addNotification({
                  message:
                    'A aparut o problema la adaugare, faceti refresh la pagina si incercati din nou sau contactati direct primaria daca problema persista.',
                  level: 'error',
                  position: 'tc'
                });
              }

              this.setState({ disableSubmit: false });
              this.resetState();
            });
        } else {
          this.props.notificationSystem.addNotification({
            message: 'Va rugam completati ReCAPTCHA.',
            level: 'error',
            position: 'tc'
          });
          this.setState({ disableSubmit: false });
        }
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        ariaHideApp={false}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Status Modal"
      >
        <div className="solicitation-modal status-modal">
          <h2 className="standard__main-title status-modal__title ">
            VERIFICĂ STATUSUL CERERII TALE
          </h2>
          <form>
            <div className="status-modal__id-box">
              <h3 className="status-modal__general-info">
                Introduceți id-ul cererii dumneavoastră:
              </h3>
              <input
                required
                className="status-modal__id-field"
                type="number"
                name="id"
                onChange={this.updateField}
                value={this.state.id}
                placeholder="ID*"
              />
            </div>
            <div className="status-modal__recapcha">
              <ReCAPTCHA
                ref={this.recaptchaRefStatusModal}
                sitekey={process.env.REACT_APP_PP_GOOGLE_VERIFICATION_V2}
                onChange={this.onChange}
              />
            </div>
            <button
              type="submit"
              className="info-form__button status-modal__submit"
              disabled={this.state.disableSubmit}
              onClick={event => this.checkStatus(event)}
            >
              <h5 className="info-form__button-writing">Află statusul</h5>
            </button>
          </form>
        </div>
      </Modal>
    );
  }
}

StatusModal.propTypes = {
  modalIsOpen: bool.isRequired,
  closeModal: func.isRequired
};

export default StatusModal;
