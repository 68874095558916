import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const socialAidService = {
  getAll,
  deleteSocialAid
};

function getAll(pageNumber) {
  const requestOptions = {
    method: 'GET',
    headers: Object.assign(
      {},
      {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    )
  };

  let url = `${process.env.REACT_APP_API_URL}/api/social_aids/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function deleteSocialAid(socialAidId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/social_aids/${socialAidId}`;

  return axios.delete(url, requestOptionsDelete);
}
