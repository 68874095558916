import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './LoginForm.css';

class LoginForm extends Component {
  render() {
    return (
      <Card className="login-form">
        <form action="/" onSubmit={this.props.onSubmit}>
          <h2 className="login-form__title">Admin</h2>

          {this.props.errors.summary && (
            <p className="error-message">{this.props.errors.summary}</p>
          )}

          <div className="login-form__field">
            <TextField
              required
              label="Email"
              name="email"
              margin="normal"
              onChange={this.props.onChange}
              error={this.props.errors.email}
              value={this.props.user.email}
            />
          </div>

          <div className="login-form__field">
            <TextField
              required
              label="Password"
              type="password"
              name="password"
              margin="normal"
              onChange={this.props.onChange}
              error={this.props.errors.password}
              value={this.props.user.password}
            />
          </div>

          {this.props.twoFARequired ? (
            <div className="login-form__field">
              <TextField
                required
                label="PIN"
                type="number"
                name="pin"
                margin="normal"
                onChange={this.props.onChange}
                error={this.props.errors.password}
                value={this.props.user.pin}
              />
            </div>
          ) : null}

          <div className="login-form__submit">
            <Button type="submit" variant="contained" color="primary">
              Log in
            </Button>
          </div>
        </form>
      </Card>
    );
  }
}

export default LoginForm;
