import React, { Component } from 'react';
import './Legislation.css';
import '../StandardPage/StandardPage.css';
import { Link } from 'react-router-dom';
import legislationMenu from './legislationMenu.json';

class Legislation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      legislationDocs: legislationMenu
    };
  }

  render() {
    return (
      <div className="legislation standard-page">
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Legislație</h4>
          </div>
          <h2 className="standard__main-title">Legislație</h2>
        </div>
        <div className="legislation__content">
          <ul className="legislation__list">
            {this.state.legislationDocs &&
              this.state.legislationDocs.map(legislationItem => (
                <div
                  key={legislationItem.id}
                  className="legislation__items-container"
                >
                  <img
                    src="/images/icons/law.svg"
                    className="legislation__svg"
                    alt="Legislation"
                  />
                  <li key={legislationItem.id} className="legislation__item">
                    <Link
                      to={legislationItem.url}
                      className="legislation__link"
                      target="_blank"
                    >
                      {legislationItem.img}
                      <h4 className="standard__general-description legislation__item">
                        {legislationItem.title}
                      </h4>
                    </Link>
                  </li>
                </div>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Legislation;
