import React, { Component } from 'react';
import localConcilors from './LocalConcilorsPeople.json';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import './LocalConcilors.css';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

class LocalConcilors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leadList: localConcilors,
      search: {
        query: '',
        queryYear: '2020'
      }
    };
  }

  updateField = event => {
    const field = event.target.name;
    const search = this.state.search;
    search[field] = event.target.value;

    this.setState({
      search
    });
  };

  search = () => {
    let leadList = localConcilors;

    if (!this.state.search.query) {
      this.setState({ leadList });

      return;
    }

    leadList = leadList.filter(leadPerson => {
      return (
        leadPerson.name
          .toLowerCase()
          .indexOf(this.state.search.query.toLowerCase()) !== -1
      );
    });

    this.setState({ leadList });
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Consilieri Locali</title>
          <meta
            name="description"
            content="Consilieri Locali Vicovu de Jos. Aici sunt prezentati cosilierii locali din Vicovu de Jos."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Consilieri Locali" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Consiliul Local</h4>
          </div>
          <h2 className="standard__main-title">Consilieri Locali</h2>
        </div>

        <div className="mariage-publications">
          <div className="mariage-publications__page__search standard__search-general-area">
            <div className="mariage-publications__search-area">
              <div className="standard__input-box mariage-publications__input-box">
                <input
                  className="standard__input mariage-publications__input"
                  placeholder="Cautare"
                  name="query"
                  onChange={this.updateField}
                  value={this.state.search.query}
                />
              </div>

              <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                <FormControl>
                  <NativeSelect
                    value={this.state.search.queryYear}
                    onChange={this.updateField}
                    inputProps={{
                      name: 'queryYear'
                    }}
                  >
                    <option value="2018">2018</option>
                    <option value="2020">2020</option>
                  </NativeSelect>
                </FormControl>
              </div>

              <button
                className="standard__button mariage-publications__button"
                id="concilors-search-button"
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => this.search()}
              >
                <h5 className="standard__button-writing mariage-publications__button-writing">
                  {' '}
                  CAUTARE
                </h5>
              </button>
            </div>
          </div>
          <div className="mariage-publications__search-results standard__search-general-area">
            <table className="mariage-publications__table local-concilors__table">
              <thead>
                <tr>
                  <td
                    width="100px"
                    className="mariage-publications__table-title"
                  >
                    Nume functionar
                  </td>
                  <td
                    width="100px"
                    className="mariage-publications__table-title"
                  >
                    Declaratie de avere
                  </td>
                </tr>
              </thead>
              <tbody>
                {this.state.leadList.map(leadPerson => (
                  <tr key={leadPerson.id}>
                    <td className="mariage-publications__table-title">
                      <h4 className="mariage-publications__wed-name">
                        {leadPerson.name}
                      </h4>
                    </td>
                    <td className="mariage-publications__table-title mariage-publications__view-more">
                      {leadPerson.statements &&
                        leadPerson.statements.map(leadStatement =>
                          leadStatement.year === this.state.search.queryYear &&
                          leadStatement.statementUrl.length ? (
                            <h4
                              key={leadStatement.id}
                              className="mariage-publications__view-more"
                              onClick={() =>
                                window.open(leadStatement.statementUrl)
                              }
                            >
                              {' '}
                              Vizualizeaza
                            </h4>
                          ) : (
                            <div key={leadStatement.id} />
                          )
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default LocalConcilors;
