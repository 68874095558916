import { responseHandleService } from './ResponseHandleService';
import axios from 'axios';
import AuthenticationService from './AuthenticationService';

export const infoFormService = {
  create,
  getAll,
  updateStatus,
  statusCheckEmail,
  statusUpdateEmail
};

function getAll(pageNumber) {
  const requestOptions = {
    method: 'GET',
    headers: Object.assign(
      {},
      {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    )
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  let url = `${process.env.REACT_APP_API_URL}/api/info_forms/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return axios.get(url, requestOptions);
}

function create(newInfoSolicitation) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newInfoSolicitation)
  };

  const url = `${process.env.REACT_APP_API_URL}/api/info_forms`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function updateStatus(newInfoSolicitation) {
  const headersOptions = {
    headers: {
      Accept: 'application/json'
    }
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    headersOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/info_forms/${newInfoSolicitation.id}`;

  return axios.put(
    url,
    { info_forms: { ...newInfoSolicitation } },
    headersOptions
  );
}

function statusUpdateEmail(newInfoSolicitation) {
  const headersOptions = {
    headers: {
      Accept: 'application/json'
    }
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    headersOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/info_forms/email_status_update`;

  return axios.post(
    url,
    { info_form: { ...newInfoSolicitation } },
    headersOptions
  );
}

function statusCheckEmail(id) {
  const url = `${process.env.REACT_APP_API_URL}/api/info_forms/email_check_status/${id}`;

  return axios.post(url);
}

function createBodyRequest(infoSolicitation) {
  let formData = new FormData();

  formData.append('info_form[name]', infoSolicitation.name);
  formData.append('info_form[address]', infoSolicitation.address);
  formData.append('info_form[email]', infoSolicitation.email);
  formData.append('info_form[phone]', infoSolicitation.phone);
  formData.append(
    'info_form[requested_information]',
    infoSolicitation.requestedInformation
  );

  const document = infoSolicitation.document;

  if (document) {
    if (document.url) {
      formData.append(`info_form[document]`, document.url);
    } else {
      formData.append(`info_form[document]`, document, document.name);
    }
  }

  return formData;
}
