import React, { Component } from 'react';
import './Anouncements.css';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AuthenticationService from '../../../services/AuthenticationService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import { announcementsService } from '../../../services/AnnouncementsService';
import ReactPaginate from 'react-paginate';

class AdminAnouncements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      announcement: {
        title: '',
        important: false,
        description: '',
        document: ''
      },
      existingAnnouncements: [],
      deleteAnnouncement: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    announcementsService
      .getAll()
      .then(jsonResponse => {
        this.setAnnouncementsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  updateField = event => {
    const field = event.target.name;

    let announcement = { ...this.state.announcement };
    announcement[field] = event.target.value;

    this.setState({
      announcement
    });
  };

  handlePageClick = selectedPage => {
    announcementsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setAnnouncementsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setAnnouncementsState = response => {
    let existingAnnouncements = { ...this.state.existingAnnouncements };
    let pagination = { ...this.state.pagination };
    existingAnnouncements = response.announcements;
    pagination = response.pagination;

    this.setState({ existingAnnouncements });
    this.setState({ pagination });
  };

  onDropDocument = documents => {
    let announcement = { ...this.state.announcement };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      announcement.document = documents[0];

      self.setState({ announcement });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let announcement = { ...this.state.announcement };
    announcement.document = null;

    this.setState({ announcement });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let announcement = { ...this.state.announcement };
    announcement.title = '';
    announcement.description = '';
    announcement.document = '';
    announcement.important = false;

    this.setState({ announcement });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.announcement.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/announcements/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/announcements/${this.state.announcement.id}`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData(this.state.announcement);

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingAnnouncements = this.state.existingAnnouncements.slice();

        let index = existingAnnouncements.findIndex(
          announcement => announcement.id === jsonResponse.id
        );

        if (index !== -1) {
          existingAnnouncements[index] = jsonResponse;
        } else {
          existingAnnouncements.push(jsonResponse);
        }

        this.setState({ existingAnnouncements });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Anuntul a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Anuntul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append('announcement[title]', this.state.announcement.title);
    formData.append(
      'announcement[description]',
      this.state.announcement.description
    );
    formData.append(
      'announcement[important]',
      this.state.announcement.important
    );

    if (this.state.announcement.document) {
      let document = this.state.announcement.document;
      formData.append(`announcement[document]`, document, document.name);
    }

    return formData;
  }

  setAnnouncementForEdit = editAnnouncement => {
    let announcement = { ...this.state.announcement };
    announcement = Object.assign({}, editAnnouncement);
    this.setState({ announcement });

    if (announcement.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteAnnouncement = { ...this.state.deleteAnnouncement };
    deleteAnnouncement = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteAnnouncement });
  };

  closeDeleteDialog = () => {
    let deleteAnnouncement = { ...this.state.deleteAnnouncement };
    deleteAnnouncement = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteAnnouncement });
  };

  handleDelete = () => {
    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/announcements/${this.state.deleteAnnouncement.deleteId}`;
    fetch(url, {
      method: 'DELETE',
      headers
    })
      .then(this.handleErrors)
      .then(() => {
        let existingAnnouncements = this.state.existingAnnouncements.slice();
        existingAnnouncements = existingAnnouncements.filter(
          announcement =>
            announcement.id !== this.state.deleteAnnouncement.deleteId
        );
        this.setState({ existingAnnouncements });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Anuntul a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  toggleCkeck = () => {
    var announcement = { ...this.state.announcement };
    announcement.important = !announcement.important;
    this.setState({ announcement });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Noutati
          </h3>
          <h1 className="admin-switch-title default-title">Anunturi</h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adauga un anunt</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu anunt*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.announcement.title}
                  />

                  <h4 className="label-title label-title-provisions">
                    Descriere anunt*
                  </h4>
                  <textarea
                    required
                    rows="5"
                    className="general-field title-field"
                    type="text"
                    name="description"
                    onChange={this.updateField}
                    value={this.state.announcement.description}
                  />
                </div>
                <div className="admin-form__checkbox">
                  <h4 className="label-title label-title-provisions">
                    Anunt important:
                  </h4>
                  <input
                    type="checkbox"
                    className="checkbox-input announcement-checkbox"
                    name="important"
                    onChange={this.toggleCkeck}
                    checked={this.state.announcement.finished}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document anunt:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.announcement.document}
                    documentFilename={this.state.announcement.document_filename}
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.announcement.id
                        ? 'Editează Anunt'
                        : 'Adaugă Anunt'}
                    </button>
                  </div>
                  {Object.values(this.state.announcement) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingAnnouncements.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Anunturi existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingAnnouncements.map(announcement => (
                      <div
                        className="existing-items__content-card"
                        key={announcement.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {announcement.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setAnnouncementForEdit(announcement)
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(announcement.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteAnnouncement.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest anunt?"
                    aditionalText="Dupa stergere, anuntul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminAnouncements;
