import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { publicDebatesInfoService } from '../../../services/PublicDebatesInfoService';
import ReactPaginate from 'react-paginate';

class PublicDebatesInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newInfoDocument: {
        title: '',
        document: '',
        date:''
      },
      existingInfoDocuments: [],
      deleteInfoDocuments: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    publicDebatesInfoService
      .getAll()
      .then(jsonResponse => {
        this.setInfoDocumentState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    publicDebatesInfoService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setInfoDocumentState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setInfoDocumentState = response => {
    let existingInfoDocuments = { ...this.state.existingInfoDocuments };
    let pagination = { ...this.state.pagination };
    existingInfoDocuments = response.public_debates_informations;
    pagination = response.pagination;

    this.setState({ existingInfoDocuments });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newInfoDocument = this.state.newInfoDocument;
    newInfoDocument[field] = event.target.value;

    this.setState({
      newInfoDocument
    });
  };

  onDropDocument = documents => {
    let newInfoDocument = { ...this.state.newInfoDocument };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newInfoDocument.document = documents[0];

      self.setState({ newInfoDocument });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newInfoDocument = { ...this.state.newInfoDocument };
    newInfoDocument.document = null;

    this.setState({ newInfoDocument });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newInfoDocument = { ...this.state.newInfoDocument };
    newInfoDocument = {
      title: '',
      document: ''
    };

    this.setState({ newInfoDocument });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newInfoDocument.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${
      process.env.REACT_APP_API_URL
    }/api/public_debates_informations/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/public_debates_informations/${
        this.state.newInfoDocument.id
      }`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingInfoDocuments = this.state.existingInfoDocuments.slice();

        let index = existingInfoDocuments.findIndex(
          existingregisterDocument =>
            existingregisterDocument.id === jsonResponse.id
        );

        if (index !== -1) {
          existingInfoDocuments[index] = jsonResponse;
        } else {
          existingInfoDocuments.push(jsonResponse);
        }

        this.setState({ existingInfoDocuments });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Documentul a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Documentul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'public_debates_information[title]',
      this.state.newInfoDocument.title
    );
    formData.append(
      'public_debates_information[add_date]',
      this.state.newInfoDocument.date
    );

    if (this.state.newInfoDocument.document) {
      let document = this.state.newInfoDocument.document;
      formData.append(
        `public_debates_information[document]`,
        document,
        document.name
      );
    }

    return formData;
  }

  setRegisterDocumentForEdit = newRegisterDoc => {
    let newInfoDocument = { ...this.state.newInfoDocument };
    newInfoDocument = Object.assign({}, newRegisterDoc);
    this.setState({ newInfoDocument });

    if (newInfoDocument.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteInfoDocuments = { ...this.state.deleteInfoDocuments };
    deleteInfoDocuments = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteInfoDocuments });
  };

  closeDeleteDialog = () => {
    let deleteInfoDocuments = { ...this.state.deleteInfoDocuments };
    deleteInfoDocuments = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteInfoDocuments });
  };

  handleDelete = () => {
    publicDebatesInfoService
      .deleteInfoDocuments(this.state.deleteInfoDocuments.deleteId)
      .then(() => {
        let existingInfoDocuments = this.state.existingInfoDocuments.slice();
        existingInfoDocuments = existingInfoDocuments.filter(
          registerDocument =>
            registerDocument.id !== this.state.deleteInfoDocuments.deleteId
        );
        this.setState({ existingInfoDocuments });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Documentul a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Interes Public
          </h3>
          <h1 className="admin-switch-title default-title admin-long-title">
            {' '}
            Informarea asupra problemelor de interes public care urmează să fie
            dezbătute de autoritățile administrației publice locale
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adauga un document nou</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">Titlu*</h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newInfoDocument.title}
                  />
                </div>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">Data*</h4>
                  <input
                    required
                    className="general-field title-field"
                    type="date"
                    name="date"
                    onChange={this.updateField}
                    value={this.state.newInfoDocument.date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newInfoDocument.document}
                    documentFilename={
                      this.state.newInfoDocument.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newInfoDocument.id ? 'Editează ' : 'Adaugă '}
                    </button>
                  </div>
                  {Object.values(this.state.newInfoDocument) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingInfoDocuments.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Documente existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingInfoDocuments.map(registerDocument => (
                      <div
                        className="existing-items__content-card"
                        key={registerDocument.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {registerDocument.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setRegisterDocumentForEdit(registerDocument)
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(registerDocument.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteInfoDocuments.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest document?"
                    aditionalText="Dupa stergere, documentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default PublicDebatesInfo;
