import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const publicInterestDocumentService = {
  getAll,
  createOrUpdate,
  deletePIDocument
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/public_interests/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function createOrUpdate(newPublicInterestDocument) {
  const createRequestOptions = {
    method: newPublicInterestDocument.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newPublicInterestDocument)
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    createRequestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/public_interests${
    newPublicInterestDocument.id ? `/${newPublicInterestDocument.id}` : ''
  }`;

  return fetch(url, createRequestOptions).then(
    responseHandleService.handleResponse
  );
}

function createBodyRequest(publicInterestDocument) {
  let formData = new FormData();

  formData.append('public_interest[title]', publicInterestDocument.title);

  const document = publicInterestDocument.document;

  if (document) {
    if (document.url) {
      formData.append(`public_interest[document]`, document.url);
    } else {
      formData.append(`public_interest[document]`, document, document.name);
    }
  }

  return formData;
}

function deletePIDocument(piDocumentId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/public_interests/${piDocumentId}`;

  return axios.delete(url, requestOptionsDelete);
}
