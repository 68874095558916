import React, { Component } from 'react';
import './ConstructionPermits.css';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { pugConstructionService } from '../../services/PugConstructionService';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

class ConstructionPermits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeQuestion: {
        question1: false,
        question2: false,
        question3: false
      },
      permits: [],
      pagination: {}
    };
  }
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  toggleAnswer = questionNumber => {
    let activeQuestion = { ...this.state.activeQuestion };

    activeQuestion[questionNumber] = !activeQuestion[questionNumber];

    this.setState({ activeQuestion });
  };
  componentWillMount() {
    pugConstructionService
      .getAll()
      .then(jsonResponse => {
        this.setPugConstructionState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    pugConstructionService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setPugConstructionState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setPugConstructionState = response => {
    let permits = { ...this.state.permits };
    let pagination = { ...this.state.pagination };
    permits = response.construction_permits;
    pagination = response.pagination;

    this.setState({ permits });
    this.setState({ pagination });
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Autorizatii de construire</title>
          <meta
            name="description"
            content="Autorizatii de construire Primaria Vicovu de Jos. Aici sunt prezentate detaliile cu privire la autorizatiile de construire."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Autorizatii de construire" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Urbanism </h4>
          </div>
          <h2 className="standard__main-title">Autorizații de construire</h2>
        </div>

        <div className="standard__wrapper allocations-wrapper">
          <div className="heating-aids">
            <div
              className="heating-aids__left-side"
              id="permits-page__left-side"
            >
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-main-title"
              >
                Documente necesare
              </h3>
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-side-title"
              >
                Documente necesare pentru eliberarea Autorizației de
                Construire/Desființare
              </h3>
              <ol className="standard__document-list sa-list">
                <li className="standard__list-item">
                  Proiect pentru Autorizație de Construire / desfiinţare
                </li>
                <li className="standard__list-item">
                  Avizele solicitate prin Certificatul de Urbanism
                </li>
                <li className="standard__list-item">
                  Plata taxei pentru eliberarea Autorizației de Construire /
                  desfiinţare ( 0,5% din valoarea lucrărilor autorizate pentru
                  locuinţe )
                </li>
                <li className="standard__list-item">
                  Certificat fiscal cu valoare impozabilă ( pt. desfiinţare)
                </li>
                <li className="standard__list-item">
                  Plata taxelor de 0,1% și 0,5 % către I.S.C. – Suceava
                </li>
                <li className="standard__list-item">
                  Alte documentatii (dupa caz )
                </li>
              </ol>
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-side-title"
              >
                Documente necesare pentru eliberarea unui Certificat de atestare
                a edificării construcțiilor
              </h3>
              <ol className="standard__document-list  sa-list">
                <li className="standard__list-item">
                  Buletin / Carte Identitate - copie
                </li>
                <li className="standard__list-item">
                  Cerere pentru eliberarea Certificatului de atestare a
                  edificării construcțiilor
                </li>
                <li className="standard__list-item">
                  Plan de amplasament și delimitare imobil (actualizat)
                </li>
              </ol>
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-side-title"
              >
                Documente necesare pentru efectuarea Recepției la terminarea
                lucrărilor de construire
              </h3>
              <ol className="standard__document-list  sa-list">
                <li className="standard__list-item">
                  Cerere pentru eliberarea Procesului Verbal de Recepție
                </li>
                <li className="standard__list-item">
                  Plan de amplasament actualizat
                </li>
                <li className="standard__list-item">
                  Certificat de performanță energetică pentru locuinte și anexe
                  (cu destinația de locuit)
                </li>
                <li className="standard__list-item">
                  Referatul proiectantului
                </li>
                <li className="standard__list-item">
                  Adeverință - Inspectoratul Județean în Construcții – Suceava
                  <ol>
                    <li className="standard__list-item">
                      Cerere tip pentru eliberarea adeverinței privind plata
                      cotelor 0,1% si 0,5 %
                    </li>
                    <li className="standard__list-item">
                      Comunicare privind încheierea lucrărilor de construire
                    </li>
                    <li className="standard__list-item">
                      Declarația privind valoarea finală a lucrărilor de
                      construire
                    </li>
                    <li className="standard__list-item">
                      Certificat fiscal cu valoarea impozabilă a
                      imobilului/imobilelor
                    </li>
                    <li className="standard__list-item">
                      Autorizația de Construire (conform cu originalul)
                    </li>
                    <li className="standard__list-item">
                      Copia chitanțelor privind achitarea cotelor de 0,1% si 0,5
                      %
                    </li>
                    <li className="standard__list-item">
                      Buletin / Carte identitate – copie
                    </li>
                  </ol>
                </li>
                <li className="standard__list-item">
                  Diferenţă taxă autorizare ( ca urmare a declararii valoarii
                  finale a lucrărilor )
                </li>
              </ol>
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-side-title"
              >
                Documente necesare pentru intabularea unei construcții noi
              </h3>
              <ol className="standard__document-list  sa-list">
                <li className="standard__list-item">
                  Buletin / Carte identitate – copie
                </li>
                <li className="standard__list-item">
                  Autorizație de Construire – conform cu originalul
                </li>
                <li className="standard__list-item">
                  Plan de amplasament actualizat
                </li>
                <li className="standard__list-item">
                  Proces verbal de Recepție la terminarea lucrărilor
                </li>
                <li className="standard__list-item">
                  Certificat de atestare a edificării constructiilor
                </li>
              </ol>
            </div>
            <div className="heating-aids__right-side">
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-side-title"
              >
                Autorizatii de contruire/desfiintare
              </h3>
              <div className="standard__cards" id="permits-cards">
                {this.state.permits.map(authorization => (
                  <div
                    className="standard__individual-card"
                    id="permits-card"
                    key={authorization.id}
                  >
                    <h3
                      className="standard__card-title"
                      id="permits-card-title"
                    >
                      {authorization.title}
                    </h3>
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${authorization.document.url}`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                    <img
                      src="/images/icons/file.svg"
                      className="standard__card-img"
                      alt="file"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${authorization.document.url}`
                        )
                      }
                    />
                  </div>
                ))}
              </div>
              <div
                className="standard__wrapper uq__wrapper"
                id="permits-questions-wrapper"
              >
                <h2 className="standard__main-title" id="questions-title">
                  Întrebari frecvente
                </h2>
                <div
                  className="uq__box"
                  id="permits-question"
                  onClick={e => this.toggleAnswer('question1')}
                >
                  <div className="uq__question-box">
                    <h4 className="standard__general-description uq__question">
                      Ce trebuie să facem dacă intentionam să construim?
                    </h4>
                    {this.state.activeQuestion.question1 ? (
                      <img
                        src="/images/icons/up-arrow.png"
                        className="uq__arrow-img"
                        alt="arrow-img"
                      />
                    ) : (
                      <img
                        src="/images/icons/down-arrow.png"
                        className="uq__arrow-img uq__down-arrow"
                        alt="arrow-img"
                      />
                    )}
                  </div>
                  {this.state.activeQuestion.question1 ? (
                    <div className="uq__answer-box" id="permits-answer">
                      <h4 className="standard__general-description uq__answer">
                        Dacă intenționat să construiți este necesar să vă
                        adresați Primăriei - respectiv compartimentului de
                        urbanism - pentru a obține certificatul de urbanism și
                        autorizația de construire.
                      </h4>
                      <h4 className="standard__general-description uq__answer">
                        Autorizatia de construire este actul care confera
                        cetatenilor dreptul de a construi, precum si de a
                        consolida sau repara o constructie deja existenta. Acest
                        act este eliberat de primarie in baza unui dosar ce
                        cuprinde actele de proprietate , proiectul construcţiei
                        , o serie de avize si acorduri și taxele aferentez
                      </h4>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className="uq__box"
                  id="permits-question"
                  onClick={e => this.toggleAnswer('question2')}
                >
                  <div className="uq__question-box">
                    <h4 className="standard__general-description uq__question">
                      De ce este necesară autorizația de construire?
                    </h4>
                    {this.state.activeQuestion.question2 ? (
                      <img
                        src="/images/icons/up-arrow.png"
                        className="uq__arrow-img"
                        alt="arrow-img"
                      />
                    ) : (
                      <img
                        src="/images/icons/down-arrow.png"
                        className="uq__arrow-img uq__down-arrow"
                        alt="arrow-img"
                      />
                    )}
                  </div>
                  {this.state.activeQuestion.question2 ? (
                    <div className="uq__answer-box" id="permits-answer">
                      <h4 className="standard__general-description uq__answer">
                        Orice construcție trebuie să respecte niște reguli
                        specifice privind alinierea ,retragerea față de limitele
                        de proprietate amplasarea față de rețelele de utilități
                        ,regimul de înălțime , indicatorii urbanistici ,
                        arhitectura ,volumetria , cromatica s.a . Și nu doar
                        acestea ci și de siguranța în exploatare ,rezistență și
                        stabilitate , mediu , PSI etc.
                      </h4>
                      <h4 className="standard__general-description uq__answer">
                        O altă regulă este a zonificării funcționale ( nu se
                        poate construi orice și oriunde) . Toate aceste elemente
                        sunt prevăzute în legislația specifică ,regulamentele de
                        urbanism și Planul urbanistic general ( PUG ) al
                        localității.
                      </h4>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className="uq__box"
                  id="permits-question"
                  onClick={e => this.toggleAnswer('question3')}
                >
                  <div className="uq__question-box">
                    <h4 className="standard__general-description uq__question">
                      Ce se întâmplă dacă construim fără autorizație?
                    </h4>
                    {this.state.activeQuestion.question3 ? (
                      <img
                        src="/images/icons/up-arrow.png"
                        className="uq__arrow-img"
                        alt="arrow-img"
                      />
                    ) : (
                      <img
                        src="/images/icons/down-arrow.png"
                        className="uq__arrow-img uq__down-arrow"
                        alt="arrow-img"
                      />
                    )}
                  </div>
                  {this.state.activeQuestion.question3 ? (
                    <div className="uq__answer-box" id="permits-answer">
                      <h4 className="standard__general-description uq__answer">
                        -Din start dorim să precizăm că îi considerăm de bună
                        credință și îi sprijinim pe toți ce care intenționează
                        să construiască și să își aducă aportul la dezvoltarea
                        localități şi îmbunătățirea mediului de viață în oraș.
                      </h4>
                      <h4 className="standard__general-description uq__answer">
                        Totuși există cazuri când unii beneficiari nu au
                        respectat legislația privind autorizarea construcțiilor
                        . Legea 50/1991 prevede în astfel de cazuri sancțiuni :
                        amendă , sistarea lucrărilor, existând cazuri de
                        readucere la starea inițială. Astfel de situații nu sunt
                        de dorit , nu ajută nimănui și trebuie evitate.
                      </h4>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <Link
                  to="/urbanism-intrebari-frecvente"
                  id="permits-link"
                  onClick={this.scrollToTop}
                >
                  <button id="pug-questions-button">
                    <h4 id="pug-button-writing">
                      Mai multe întrebări și răspunsuri
                    </h4>
                  </button>
                </Link>
              </div>
            </div>
            {this.state.pagination.last_page > 1 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.pagination.last_page}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ConstructionPermits;
