import React, { Component } from 'react';
import '../../resources/AdminStandardPage.css';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import AuthenticationService from '../../services/AuthenticationService';
import QRCode from 'qrcode.react';
import './SecondFactorActivation.css';
import { secondFactorService } from '../../services/SecondFactorService';

class SecondFactorActivation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qrCodeProcedure: false,
      twoFaActive: false,
      qrCode: {
        uri: '',
        pin: '',
        currentPassword: ''
      }
    };

    this.authenticationService = new AuthenticationService();
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;

    secondFactorService
      .twoFaStatus()
      .then(response => {
        this.setState({ twoFaActive: response.data.active });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message: error.response.data.error,
          level: 'error',
          position: 'bl'
        });
      });
  }

  activateQRCodeProcedure = () => {
    secondFactorService
      .generateQrCodeSecret()
      .then(response => {
        let qrCode = { ...this.state.qrCode };
        qrCode.uri = response.data.uri;
        this.setState({ qrCode });
        this.setState({ qrCodeProcedure: true });
      })
      .catch(error => {
        if (error.response.status === 422) {
          this._notificationSystem.addNotification({
            message: error.response.data.error,
            level: 'error',
            position: 'bl'
          });
        }
      });
  };

  updateField = event => {
    const field = event.target.name;
    const qrCode = { ...this.state.qrCode };
    qrCode[field] = event.target.value;

    this.setState({
      qrCode
    });
  };

  activateQRCode = event => {
    event.preventDefault();

    secondFactorService
      .activateSecondFactor(
        this.state.qrCode.pin,
        this.state.qrCode.currentPassword
      )
      .then(response => {
        console.log(response);
        this.setState({ twoFaActive: true });

        let qrCode = { ...this.state.qrCode };
        qrCode.uri = '';
        qrCode.pin = '';
        qrCode.currentPassword = '';

        this.setState({ qrCode });

        this._notificationSystem.addNotification({
          message: response.data.success,
          level: 'success',
          position: 'bl'
        });
      })
      .catch(error => {
        if (error.response.status === 422) {
          this._notificationSystem.addNotification({
            message: error.response.data.error,
            level: 'error',
            position: 'bl'
          });
        }
      });
  };

  deactivateQRCode = event => {
    event.preventDefault();

    secondFactorService
      .deactivateSecondFactor()
      .then(response => {
        this.setState({ twoFaActive: false });

        this._notificationSystem.addNotification({
          message: response.data.success,
          level: 'success',
          position: 'bl'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'bl'
        });
      });
  };

  render() {
    return (
      <div className="change-password-page qr-code-page">
        <div className="change-password-page__content">
          <h2 className="change-password-page__title">
            Autentificare in doi pasi
          </h2>
          {!this.state.twoFaActive ? (
            <div>
              <button
                type="button"
                className="general-button change-password-button"
                onClick={this.activateQRCodeProcedure}
              >
                Incepe activarea
              </button>
              {this.state.qrCodeProcedure ? (
                <div className="change-password-page__content">
                  <div className="qr-code-page__steps">
                    <h3 className="qr-code-page__title-steps">
                      Pasi Necesari:
                    </h3>
                    <h4 className="qr-code-page__step">
                      1. Instaleaza aplicatia de citire QR Code Google
                      Authenticator:
                    </h4>
                    <h4 className="qr-code-page__step qr-code-page__sub-step">
                      1.1. Link Android:{' '}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ro"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Autheticator
                      </a>{' '}
                    </h4>
                    <h4 className="qr-code-page__step qr-code-page__sub-step">
                      1.2. Link iOS:{' '}
                      <a
                        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Autheticator
                      </a>{' '}
                    </h4>
                    <h4 className="qr-code-page__step">
                      2. Scaneaza cu Google Authenticator codul afisat mai jos:
                    </h4>
                    <div className="qr-code-page__code">
                      <QRCode
                        value={this.state.qrCode.uri}
                        size={200}
                        level="H"
                      />
                    </div>
                    <h4 className="qr-code-page__step">
                      3. Introdu codul din aplicatia Google Autheticator in
                      campul de mai jos:
                    </h4>
                    <form
                      className="qr-code-page__form"
                      onSubmit={this.activateQRCode}
                    >
                      <div className="admin-form__field-box">
                        <h4 className="label-title label-title-password">
                          PIN QR CODE*
                        </h4>
                        <div className="admin-form__field change-password-form">
                          <input
                            required
                            className="change-password-field general-field"
                            label="PIN QR CODE"
                            name="pin"
                            margin="normal"
                            type="number"
                            onChange={this.updateField}
                            value={this.state.qrCode.pin}
                            style={{ width: '50%' }}
                          />
                        </div>
                      </div>
                      <div className="admin-form__field-box">
                        <h4 className="label-title label-title-password">
                          Parola curentă*
                        </h4>
                        <div className="admin-form__field change-password-form">
                          <input
                            required
                            className="change-password-field general-field"
                            label="Parola Curenta"
                            name="currentPassword"
                            margin="normal"
                            type="password"
                            autoComplete="new-password"
                            onChange={this.updateField}
                            value={this.state.qrCode.currentPassword}
                            style={{ width: '50%' }}
                          />
                        </div>
                      </div>
                      <div className="admin-form__submit">
                        <button
                          type="submit"
                          className="general-button change-password-button"
                        >
                          Activeaza QR Code
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="change-password-page__content">
              <h3 className="qr-code-page__activated">
                Autentificarea in doi pasi este activa pentru acest cont.
              </h3>
              <button
                type="button"
                className="general-button change-password-button"
                onClick={this.deactivateQRCode}
              >
                Dezactiveaza Autentificarea
              </button>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default SecondFactorActivation;
