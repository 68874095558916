import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class TwinCities extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Bivolărie - Cesson, France, Orase Infratite</title>
          <meta
            name="description"
            content="Bivolărie - Cesson, France, Orase Infratite."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta
            property="og:title"
            content="Bivolărie - Cesson, France, Orase Infratite"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Despre Vicov > </h4>
            <h4 className="standard__generic">Prezentare Generala </h4>
          </div>
          <h2 className="standard__main-title">Orase Infratite</h2>
        </div>

        <div className="standard__wrapper">
          <h3 className=" standard__submitted-title">
            Bivolărie - Cesson, France
          </h3>
          <h4 className="standard__general-description standard__mariage-record-left">
            Localitatea componentă a orașului Vicovu de Jos – Bivolăria, este
            înfrățită cu orașul Cesson din Franța din anul 1995. Acest
            parteneriat constă în schimbul de experiență în domeniile:
            învațământ, educație, tradiții și cultură.
          </h4>
          <h3 className=" standard__submitted-title">Bivolărie</h3>
          <h4 className="standard__general-description standard__mariage-record-left">
            Bivolărie (în germană Biwoleria, anterior Bivolăria Împărătească)
            este o localitate componentă a orașului Vicovu de Jos din județul
            Suceava, Bucovina, România. Populația localității Bivolărie este de
            2959 locuitori.
          </h4>
          <h3 className=" standard__submitted-title">Cesson, France</h3>
          <h4 className="standard__general-description standard__mariage-record-left">
            Cesson este o municipalitate franceză situată în departamentul
            Seine-et-Marne, în regiunea Ile-de-France. Locuitorii săi sunt
            numiți Cessonnais. La ultimul recensământ din 2015, comuna avea
            10.033 de locuitori.
          </h4>
        </div>
      </div>
    );
  }
}

export default TwinCities;
