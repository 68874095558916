// @flow

import React, { Component } from 'react';
import './Menu.css';
import menuJson from '../menu.json';
import SubMenu from '../SubMenu/SubMenu';
import { Link } from 'react-router-dom';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: menuJson,
      openMenuById: 0
    };
  }

  enterButton = id => {
    this.setState({ openMenuById: id });
    document.body.classList.add('has-active-menu');
  };

  leaveButton = () => {
    this.setState({ openMenuById: 0 });
    document.body.classList.remove('has-active-menu');
  };

  render() {
    return (
      <div className="menu">
        <ul className="menu__links">
          {this.state.menu.map(menuItem => (
            <li key={menuItem.id} className="menu__link">
              {menuItem.subMenu ? (
                <div
                  onMouseEnter={() => this.enterButton(menuItem.id)}
                  onMouseLeave={this.leaveButton}
                >
                  <span className="title-item">{menuItem.title}</span>
                  <SubMenu
                    menu={menuItem}
                    buttonHovered={this.state.openMenuById}
                    closeMenu={this.leaveButton}
                  />
                </div>
              ) : (
                <Link
                  to={menuItem.url}
                  onClick={() => this.leaveButton()}
                  className="title-item"
                >
                  {menuItem.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Menu;
