import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import './MariagePublications.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import { mariagePublicationsService } from '../../../services/MariagePublicationsService';
import ReactPaginate from 'react-paginate';

class MariagePublications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newMariagePublication: {
        husband: '',
        wife: '',
        document: '',
        registration_date: '',
        mariage_date: ''
      },
      existingPublications: [],
      disabledDocuments: false,
      deleteMariagePublication: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    mariagePublicationsService
      .getAll()
      .then(jsonResponse => {
        this.setMariagePublicationState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    mariagePublicationsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setMariagePublicationState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setMariagePublicationState = response => {
    let existingPublications = { ...this.state.existingPublications };
    let pagination = { ...this.state.pagination };
    existingPublications = response.mariage_publications;
    pagination = response.pagination;

    this.setState({ existingPublications });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newMariagePublication = this.state.newMariagePublication;
    newMariagePublication[field] = event.target.value;

    this.setState({
      newMariagePublication
    });
  };

  onDropDocument = documents => {
    let newMariagePublication = { ...this.state.newMariagePublication };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newMariagePublication.document = documents[0];

      self.setState({ newMariagePublication });
      self.setState({ disabledDocuments: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newMariagePublication = { ...this.state.newMariagePublication };
    newMariagePublication.picture = null;

    this.setState({ newMariagePublication });
    this.setState({ disabledDocuments: false });
  };

  resetForm = () => {
    let newMariagePublication = { ...this.state.newMariagePublication };
    newMariagePublication = {
      husband: '',
      wife: '',
      document: '',
      registration_date: '',
      mariage_date: ''
    };

    this.setState({ newMariagePublication });
    this.setState({ disabledDocuments: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newMariagePublication.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/mariage_publications/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/mariage_publications/${this.state.newMariagePublication.id}`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData(this.state.newMariagePublication);

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingPublications = this.state.existingPublications.slice();

        let index = existingPublications.findIndex(
          mariagePublication => mariagePublication.id === jsonResponse.id
        );

        if (index !== -1) {
          existingPublications[index] = jsonResponse;
        } else {
          existingPublications.push(jsonResponse);
        }

        this.setState({ existingPublications });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Publicatia a fost editata cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Publicatia a fost adaugata cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'mariage_publication[husband]',
      this.state.newMariagePublication.husband
    );

    formData.append(
      'mariage_publication[wife]',
      this.state.newMariagePublication.wife
    );

    formData.append(
      'mariage_publication[registration_date]',
      this.state.newMariagePublication.registration_date
    );

    formData.append(
      'mariage_publication[mariage_date]',
      this.state.newMariagePublication.mariage_date
    );
    if (this.state.newMariagePublication.document) {
      let picture = this.state.newMariagePublication.document;
      formData.append(`mariage_publication[document]`, picture, picture.name);
    }

    return formData;
  }

  setEventForEdit = mariagePublication => {
    let newMariagePublication = { ...this.state.newMariagePublication };
    newMariagePublication = Object.assign({}, mariagePublication);
    this.setState({ newMariagePublication });
    this.setState({
      disabledDocuments: !!this.state.newMariagePublication.document.url
    });
  };

  openDeleteDialog = id => {
    let deleteMariagePublication = { ...this.state.deleteMariagePublication };
    deleteMariagePublication = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteMariagePublication });
  };

  closeDeleteDialog = () => {
    let deleteMariagePublication = { ...this.state.deleteMariagePublication };
    deleteMariagePublication = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteMariagePublication });
  };

  handleDelete = () => {
    mariagePublicationsService
      .deleteMarriagePublication(this.state.deleteMariagePublication.deleteId)
      .then(() => {
        let existingPublications = this.state.existingPublications.slice();
        existingPublications = existingPublications.filter(
          mariagePublication =>
            mariagePublication.id !==
            this.state.deleteMariagePublication.deleteId
        );
        this.setState({ existingPublications });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Publicatia a fost stearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Stare civila
          </h3>
          <h1 className="admin-switch-title default-title">
            Publicatii de casatorie
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adauga o publicatie noua de casatorie
                </h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">Sot*</h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="husband"
                    onChange={this.updateField}
                    value={this.state.newMariagePublication.husband}
                  />

                  <h4 className="label-title label-title-provisions">Sotie*</h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="wife"
                    onChange={this.updateField}
                    value={this.state.newMariagePublication.wife}
                  />

                  <h4 className="label-title label-title-provisions">
                    Dată inregistrare*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="registration_date"
                    onChange={this.updateField}
                    value={this.state.newMariagePublication.registration_date}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată casatorie*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="mariage_date"
                    onChange={this.updateField}
                    value={this.state.newMariagePublication.mariage_date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Adauga publicatie:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocuments}
                    document={this.state.newMariagePublication.document}
                    documentFilename={
                      this.state.newMariagePublication.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newMariagePublication.id
                        ? 'Editează Publicatie'
                        : 'Adaugă Publicatie'}
                    </button>
                  </div>
                  {Object.values(this.state.newMariagePublication) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingPublications.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Publicatii de casatorie existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingPublications.map(publication => (
                      <div
                        className="existing-items__content-card"
                        key={publication.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {publication.wife + ' si ' + publication.husband}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setEventForEdit(publication)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(publication.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteMariagePublication.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti aceasta publicatie?"
                    aditionalText="Dupa stergere, publicatia nu va mai putea fi recuperata."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default MariagePublications;
