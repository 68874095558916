import React, { Component } from 'react';

class ImageNextGen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      srcImage: ''
    };

    this.setImageProperSrc();
  }

  setImageProperSrc = () => {
    window.Modernizr.on('webp', result => {
      if (result) {
        this.setState({ srcImage: this.props.imageUrlWebp });
      }
    });
    window.Modernizr.on('jpeg2000', result => {
      if (result) {
        this.setState({ srcImage: this.props.imageUrlJpeg2000 });
      }
    });
  };

  render() {
    return (
      <img
        className={this.props.class}
        src={this.state.srcImage}
        alt={this.props.imageUrl}
      />
    );
  }
}

export default ImageNextGen;
