import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const electionsService = {
  getAll,
  createOrUpdate,
  deleteElectionDocument,
  search
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/elections/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return axios.get(url, requestOptions);
}

function createOrUpdate(newElection) {
  const requestOptions = {
    method: newElection.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newElection)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/elections${
    newElection.id ? `/${newElection.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function search(searchElection, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/elections/search?title=${searchElection.title}&archiveYear=${
    searchElection.archiveYear
  }&page[number]=${pageNumber ? pageNumber : 1}`;

  return axios.get(searchUrl, requestOptions);
}

function deleteElectionDocument(electionId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/elections/${electionId}`;

  return axios.delete(url, requestOptionsDelete);
}

function createBodyRequest(election) {
  let formData = new FormData();

  formData.append('election[title]', election.title);
  formData.append('election[add_date]', election.add_date);
  formData.append('election[year]', election.year);

  const document = election.document;

  if (document) {
    if (document.url) {
      formData.append(`election[document]`, document.url);
    } else {
      formData.append(`election[document]`, document, document.name);
    }
  }

  return formData;
}
