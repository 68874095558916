import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const organizationRegulamentService = {
  getAll,
  createOrUpdate,
  deleteRegulament
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/organization_regulations/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newRegulament) {
  const requestOptions = {
    method: newRegulament.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newRegulament)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/organization_regulations${
    newRegulament.id ? `/${newRegulament.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(regulament) {
  let formData = new FormData();

  formData.append('organization_regulation[title]', regulament.title);

  const document = regulament.document;

  if (document) {
    if (document.url) {
      formData.append(`organization_regulation[document]`, document.url);
    } else {
      formData.append(
        `organization_regulation[document]`,
        document,
        document.name
      );
    }
  }

  return formData;
}
function deleteRegulament(regulamentId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/organization_regulations/${regulamentId}`;

  return axios.delete(url, requestOptionsDelete);
}
