const style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: '25px 0',
      textAlign: 'center'
    }
  }
};

export default style;
