import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { localBudgetService } from '../../services/LocalBudgetService';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class FinancialSituation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localBudgets: [],
      searchBudget: {
        title: '',
        archiveYear: ''
      },
      archiveYears: archiveJson,
      localBudgetsPagination: {}
    };
  }

  componentWillMount() {
    localBudgetService
      .getAll()
      .then(jsonResponse => {
        this.setLocalBugetsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  setLocalBugetsState = response => {
    let localBudgets = { ...this.state.localBudgets };
    let localBudgetsPagination = { ...this.state.localBudgetsPagination };
    localBudgets = response.local_budgets;
    localBudgetsPagination = response.pagination;

    this.setState({ localBudgets });
    this.setState({ localBudgetsPagination });
  };

  handleLocalBudgetPageClick = selectedPage => {
    localBudgetService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setLocalBugetsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };
  updateField = event => {
    const field = event.target.name;
    const searchBudget = this.state.searchBudget;
    searchBudget[field] = event.target.value;

    this.setState(
      {
        searchBudget
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    localBudgetService
      .search(this.state.searchBudget, pageNumber)
      .then(jsonResponse => {
        this.setLocalBugetsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Documente si Informatii Financiare</title>
          <meta
            name="description"
            content="Informatii Financiare Primaria Vicovu de Jos. Aici sunt prezentate informatiile financiare din Vicovu de Jos."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Informatii Financiare" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Buget</h4>
          </div>
          <h2 className="standard__main-title">
            Documente si Informatii Financiare
          </h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchBudget.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchBudget.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div
            className={
              'standard__cards ' +
              (this.state.localBudgets.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.localBudgets.length > 0 ? (
              this.state.localBudgets.map(localBudget => (
                <div
                  className="standard__individual-card  standard__card-local-link"
                  key={localBudget.id}
                >
                  <h3 className="standard__card-title">{localBudget.title}</h3>
                  <div className="mariage-publications-page__publication-visualize">
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${localBudget.document.url}`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                    <img
                      src="/images/icons/file.svg"
                      className="standard__card-img"
                      alt="file"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${localBudget.document.url}`
                        )
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
          {this.state.localBudgetsPagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.localBudgetsPagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handleLocalBudgetPageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FinancialSituation;
