import React from 'react';
import '../../../resources/AdminStandardPage.css';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import { balanceSheetsService } from '../../../services/BalanceSheetsService';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';

class BalanceSheets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledDocument: false,
      newBalanceSheet: {
        title: '',
        document: '',
        date:''
      },
      existingBalanceSheets: [],
      deleteBalanceSheet: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };
  }

  componentWillMount() {
    balanceSheetsService
      .getAll()
      .then(response => {
        this.setBalanceSheetState(response);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setBalanceSheetState = response => {
    let existingBalanceSheets = this.state.existingBalanceSheets;
    existingBalanceSheets = response.balance_sheets;
    this.setState({
      existingBalanceSheets
    });
    let pagination = { ...this.state.pagination };
    pagination = response.pagination;
    this.setState({
      pagination
    });
  };

  handlePageClick = currentPage => {
    balanceSheetsService
      .getAll(currentPage.selected + 1)
      .then(response => {
        this.setBalanceSheetState(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  updateField = event => {
    const field = event.target.name;
    const newBalanceSheet = this.state.newBalanceSheet;
    newBalanceSheet[field] = event.target.value;
    this.setState({
      newBalanceSheet
    });
  };

  handleFormSubmit = event => {
    event.preventDefault();

    balanceSheetsService
      .createOrUpdate(this.state.newBalanceSheet)
      .then(response => {
        this.resetForm();
        let existingBalanceSheets = this.state.existingBalanceSheets;

        let index = existingBalanceSheets.findIndex(
          existingBalanceSheet => existingBalanceSheet.id === response.id
        );

        if (index !== -1) {
          existingBalanceSheets[index] = response;
        } else {
          existingBalanceSheets.push(response);
        }

        this.setState({
          existingBalanceSheets
        });

        this._notificationSystem.addNotification({
          message: `Bilantul a fost ${
            this.state.newBalanceSheet.id ? 'editat' : 'adaugat'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  resetForm = () => {
    let newBalanceSheet = { ...this.state.newBalanceSheet };
    newBalanceSheet = {
      title: '',
      document: ''
    };

    this.setState({ newBalanceSheet });
    this.setState({ disabledDocument: false });
  };

  onDropDocument = documents => {
    let newBalanceSheet = { ...this.state.newBalanceSheet };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newBalanceSheet.document = documents[0];

      self.setState({ newBalanceSheet });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newBalanceSheet = { ...this.state.newBalanceSheet };
    newBalanceSheet.document = null;

    this.setState({ newBalanceSheet });
    this.setState({ disabledDocument: false });
  };

  setBalanceSheetForEdit = balanceSheet => {
    let newBalanceSheet = { ...this.state.newBalanceSheet };
    newBalanceSheet = Object.assign({}, balanceSheet);
    this.setState({ newBalanceSheet });

    if (newBalanceSheet.document && newBalanceSheet.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteBalanceSheet = { ...this.state.deleteBalanceSheet };
    deleteBalanceSheet = {
      openedDialog: true,
      deleteId: id
    };
    this.setState({
      deleteBalanceSheet
    });
  };

  closeDeleteDialog = () => {
    let deleteBalanceSheet = { ...this.state.deleteBalanceSheet };
    deleteBalanceSheet = {
      openedDialog: false,
      deleteId: 0
    };
    this.setState({
      deleteBalanceSheet
    });
  };

  handleDelete = () => {
    balanceSheetsService
      .deleteBalanceSheet(this.state.deleteBalanceSheet.deleteId)
      .then(() => {
        let existingBalanceSheets = this.state.existingBalanceSheets.slice();
        existingBalanceSheets = existingBalanceSheets.filter(
          balanceSheet =>
            balanceSheet.id !== this.state.deleteBalanceSheet.deleteId
        );
        this.setState({
          existingBalanceSheets
        });
        this.closeDeleteDialog();
        this._notificationSystem.addNotification({
          message: 'Strategia a fost stearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <h1 className="admin-switch-title default-title">
          Bilanturi contabile
        </h1>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adaugă un bilant contabil</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu bilant contabil*
                  </h4>
                  <input
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newBalanceSheet.title}
                  />
                </div>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Data bilant contabil*
                  </h4>
                  <input
                    className="general-field title-field"
                    type="date"
                    name="date"
                    onChange={this.updateField}
                    value={this.state.newBalanceSheet.date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document bilant contabil:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newBalanceSheet.document}
                    documentFilename={
                      this.state.newBalanceSheet.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newBalanceSheet.id
                        ? 'Editează Bilant'
                        : 'Adaugă Bilant'}
                    </button>
                  </div>
                  <button
                    type="button"
                    className="general-button reset-button"
                    onClick={() => this.resetForm()}
                  >
                    Resetează câmpurile
                  </button>
                </div>
              </form>
            </div>
          </div>
          {this.state.existingBalanceSheets &&
            this.state.existingBalanceSheets.length > 0 && (
              <div className="admin-standard-page__existing-items">
                <div className="card">
                  <div className="existing-items">
                    <h2 className="existing-items__title label-title">
                      Bilanturi contabile existente
                    </h2>
                    <div className="existing-items__content">
                      {this.state.existingBalanceSheets.map(balanceSheet => (
                        <div
                          className="existing-items__content-card"
                          key={balanceSheet.id}
                        >
                          <div className="card-content provisions-title-content">
                            <h3 className="major-provisions-title">
                              {balanceSheet.title}
                            </h3>
                          </div>
                          <div className="existing-items__actions-card">
                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.setBalanceSheetForEdit(balanceSheet)
                              }
                            >
                              <h4 className="action-title"> Editează</h4>
                            </div>

                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.openDeleteDialog(balanceSheet.id)
                              }
                            >
                              <h4 className="action-title delete-button">
                                {' '}
                                Șterge
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <DeletePUGCertificates
                      opened={this.state.deleteBalanceSheet.openedDialog}
                      handleClose={this.closeDeleteDialog}
                      handleDelete={this.handleDelete}
                      headline="Sunteti sigur ca doriti sa stergeti acest bilant contabil?"
                      aditionalText="Dupa stergere, acest bilant contabil nu va mai putea fi recuperat."
                    />
                  </div>
                  {this.state.pagination.last_page > 1 && (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={this.state.pagination.last_page}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                </div>
              </div>
            )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default BalanceSheets;
