import AuthenticationService from './AuthenticationService';

export const errorService = {
  handleUnauthorized
};

function handleUnauthorized() {
  const authenticationService = new AuthenticationService();

  authenticationService.logout();
}
