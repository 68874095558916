import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import './PUG.css';
import MetaTags from 'react-meta-tags';
import DownloadCard from '../../components/DownloadCard/DownloadCard';

class PUG extends Component {
  openImageInNewTab = imageUrl => {};

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Plan Urbanistic General</title>
          <meta
            name="description"
            content="Plan Urbanistic General Vicovu de Jos. Aici sunt prezentate documentele legate de planul urbanistic general din Vicovu de Jos."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Plan Urbanistic General" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Urbanism</h4>
          </div>
          <h2 className="standard__main-title">Plan Urbanistic General</h2>
        </div>

        <div className="pug-page">
          <div className="standard__wrapper">
            <h3 className="standard__submitted-title">
              Regulament de urbanism Vicovu de Sus
            </h3>
            <div
              className="standard__cards standard__projects-wrapper-few"
              id="pug-individual-card"
            >
              <div
                className="standard__card pug-page__card"
                id="pug-individual-card"
              >
                <DownloadCard
                  flexClass="pug-page__individual-card"
                  title="Regulament Local de Urbanism"
                  url="/documents/Regulament-de-urbanism-RLU-VICOVU-DE-SUS.pdf"
                />
              </div>
            </div>

            <h3 className="standard__submitted-title pug-page__title-section">
              Documentație Grafică
            </h3>
            <div className="standard__cards standard__projects-wrapper-few">
              <div className="pug-page__graphic-documentation">
                <div className="pug-page__left-side">
                  <h3 className="standard__submitted-title" id="pug-side-title">
                    Vicovu de Sus
                  </h3>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Regulament Local de Urbanism"
                      url="/documents/Regulament-de-urbanism-RLU-VICOVU-DE-SUS.pdf"
                    />
                  </div>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Vicovu de Sus - PAT"
                      url="/images/pug/Vicov_PAT_2300_910.png"
                    />
                  </div>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Vicovu de Sus - RET"
                      url="/images/pug/Vicov_RET_2300_910.png"
                    />
                  </div>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Vicovu de Sus - RUZ"
                      url="/images/pug/Vicov_RUZ_2300_910.png"
                    />
                  </div>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Vicovu de Sus - SED"
                      url="/images/pug/Vicov_SED_2300_910.png"
                    />
                  </div>
                </div>
                <div className="pug-page__right-side">
                  <h3 id="pug-side-title" className="standard__submitted-title">
                    Vicovu de Sus - Bivolarie
                  </h3>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Vicovu de Sus - Bivolaria - PAT"
                      url="/images/pug/Bivolaria_PAT_1400_910.png"
                    />
                  </div>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Vicovu de Sus - Bivolarie - RET"
                      url="/images/pug/Bivolaria_RET_1400_910.png"
                    />
                  </div>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Vicovu de Sus - Bivolaria - RUZ"
                      url="/images/pug/Bivolaria_RUZ_1400_910.png"
                    />
                  </div>
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Vicovu de Sus - Bivolarie - SED"
                      url="/images/pug/Bivolaria_SED_1400_910.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PUG;
