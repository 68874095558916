import React, { Component } from 'react';
import '../../resources/AdminStandardPage.css';
import { usersService } from '../../services/UsersService';
import { errorService } from '../../services/ErrorService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: '',
        email: ''
      }
    };
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  updateField = event => {
    const field = event.target.name;
    const user = this.state.user;
    user[field] = event.target.value;

    this.setState({
      user
    });
  };

  handleNewUser = event => {
    event.preventDefault();

    usersService
      .createOrUpdate(this.state.user)
      .then(response => {
        this._notificationSystem.addNotification({
          message: 'Utilizatorul a fost creat cu success.',
          level: 'success',
          position: 'tc'
        });

        let user = { ...this.state.user };
        user.username = '';
        user.email = '';

        this.setState({ user });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la stergere, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });

        if (error.response.status === 401) {
          errorService.handleUnauthorized();
          this.props.history.push('/login');
        }

        if (error.response.status === 403) {
          this.props.history.push('/user');
        }
      });
  };

  render() {
    return (
      <div className="change-password-page">
        <div className="change-password-page__content">
          <h3 className="change-password-page__title general-subtitle">
            Creează utilizator nou
          </h3>
          <form onSubmit={this.handleNewUser}>
            <div className="admin-form__field-box">
              <h4 className="label-title label-title-password">
                Nume utilizator*
              </h4>
              <div className="admin-form__field change-password-form">
                <input
                  required
                  className="change-password-field general-field"
                  label="Nume Utilizator"
                  name="username"
                  margin="normal"
                  type="text"
                  onChange={this.updateField}
                  value={this.state.user.username}
                  style={{ width: '50%' }}
                />
              </div>
            </div>
            <div className="admin-form__field-box">
              <h4 className="label-title label-title-password">Email*</h4>
              <div className="admin-form__field">
                <input
                  required
                  className="change-password-field general-field"
                  label="email"
                  name="email"
                  margin="normal"
                  type="email"
                  onChange={this.updateField}
                  value={this.state.user.email}
                  style={{ width: '50%' }}
                />
              </div>
            </div>

            <div className="admin-form__submit">
              <button
                type="submit"
                className="general-button change-password-button"
              >
                Creează utilizator
              </button>
            </div>
          </form>
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default CreateUser;
