import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class AnimalDamages extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>
            Informații privind procedura de întocmire a dosarelor privind
            pagubele produse de animale sălbatice
          </title>
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">
              Pagube produse de animale sălbatice
            </h4>
          </div>
          <h2 className="standard__main-title">
            Procedura de întocmire a dosarelor și actele necesare privind
            pagubele produse de animale sălbatice
          </h2>
        </div>
        <div className="standard__wrapper wrapper-no-search">
          <div className="standard__cards standard__projects-wrapper-few">
            <div className="standard__individual-card">
              <h3 className="standard__card-title">
                Informații privind procedura de întocmire a dosarelor privind
                pagubele produse de animale sălbatice culturilor agricole și
                animalelor domestice
              </h3>
              <h4
                className="standard__see-project"
                onClick={() =>
                  window.open(
                    '/legislation/Hotararea_nr_1679_2008.pdf',
                    '_blank'
                  )
                }
              >
                Vizualizează
              </h4>
              <img
                src="/images/icons/file.svg"
                className="standard__card-img standard__card-img-local"
                alt="file"
                onClick={() =>
                  window.open(
                    '/legislation/Hotararea_nr_1679_2008.pdf',
                    '_blank'
                  )
                }
              />
            </div>
            <div className="standard__individual-card">
              <h3 className="standard__card-title">
                Actele care trebuie incluse în dosarul privind pagubele produse
                de animalele sălbatice
              </h3>
              <h4
                className="standard__see-project"
                onClick={() =>
                  window.open('/legislation/Legea_407_2006.pdf', '_blank')
                }
              >
                Vizualizează
              </h4>
              <img
                src="/images/icons/file.svg"
                className="standard__card-img standard__card-img-local"
                alt="file"
                onClick={() =>
                  window.open('/legislation/Legea_407_2006.pdf', '_blank')
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnimalDamages;
