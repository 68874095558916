import React from 'react';
import './UrbanismQuestions.css';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class UrbanismQuestions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeQuestion: {
        question1: false,
        question2: false,
        question3: false,
        question4: false,
        question5: false,
        question6: false
      }
    };
  }
  toggleAnswer = questionNumber => {
    let activeQuestion = { ...this.state.activeQuestion };

    activeQuestion[questionNumber] = !activeQuestion[questionNumber];

    this.setState({ activeQuestion });
  };
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Certificate de Urbanism</title>
          <meta
            name="description"
            content="Certificate de Urbanism Vicovu de Sus. Aici sunt prezentate documentele necesare pentru certificatele de urbanism, dar si listele certificatelor de urbanism."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Sus, Vicovu de Sus, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Certificate de Urbanism" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Urbanism </h4>
          </div>
          <h2 className="standard__main-title">Intrebari frecvente</h2>
        </div>
        <div className="standard__wrapper uq__wrapper">
          <div
            className="uq__box"
            onClick={e => this.toggleAnswer('question1')}
          >
            <div className="uq__question-box">
              <h4 className="standard__general-description uq__question">
                Ce trebuie să facem dacă intentionam să construim?
              </h4>
              {this.state.activeQuestion.question1 ? (
                <img
                  src="/images/icons/up-arrow.png"
                  className="uq__arrow-img"
                  alt="arrow-img"
                />
              ) : (
                <img
                  src="/images/icons/down-arrow.png"
                  className="uq__arrow-img uq__down-arrow"
                  alt="arrow-img"
                />
              )}
            </div>
            {this.state.activeQuestion.question1 ? (
              <div className="uq__answer-box">
                <h4 className="standard__general-description uq__answer">
                  Dacă intenționat să construiți este necesar să vă adresați
                  Primăriei - respectiv compartimentului de urbanism - pentru a
                  obține certificatul de urbanism și autorizația de construire.
                </h4>
                <h4 className="standard__general-description uq__answer">
                  Autorizatia de construire este actul care confera cetatenilor
                  dreptul de a construi, precum si de a consolida sau repara o
                  constructie deja existenta. Acest act este eliberat de
                  primarie in baza unui dosar ce cuprinde actele de proprietate
                  , proiectul construcţiei , o serie de avize si acorduri și
                  taxele aferentez
                </h4>
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            className="uq__box"
            onClick={e => this.toggleAnswer('question2')}
          >
            <div className="uq__question-box">
              <h4 className="standard__general-description uq__question">
                De ce este necesară autorizația de construire?
              </h4>
              {this.state.activeQuestion.question2 ? (
                <img
                  src="/images/icons/up-arrow.png"
                  className="uq__arrow-img"
                  alt="arrow-img"
                />
              ) : (
                <img
                  src="/images/icons/down-arrow.png"
                  className="uq__arrow-img uq__down-arrow"
                  alt="arrow-img"
                />
              )}
            </div>
            {this.state.activeQuestion.question2 ? (
              <div className="uq__answer-box">
                <h4 className="standard__general-description uq__answer">
                  Orice construcție trebuie să respecte niște reguli specifice
                  privind alinierea ,retragerea față de limitele de proprietate
                  amplasarea față de rețelele de utilități ,regimul de înălțime
                  , indicatorii urbanistici , arhitectura ,volumetria ,
                  cromatica s.a . Și nu doar acestea ci și de siguranța în
                  exploatare ,rezistență și stabilitate , mediu , PSI etc.
                </h4>
                <h4 className="standard__general-description uq__answer">
                  O altă regulă este a zonificării funcționale ( nu se poate
                  construi orice și oriunde) . Toate aceste elemente sunt
                  prevăzute în legislația specifică ,regulamentele de urbanism
                  și Planul urbanistic general ( PUG ) al localității.
                </h4>
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            className="uq__box"
            onClick={e => this.toggleAnswer('question3')}
          >
            <div className="uq__question-box">
              <h4 className="standard__general-description uq__question">
                Ce se întâmplă dacă construim fără autorizație?
              </h4>
              {this.state.activeQuestion.question3 ? (
                <img
                  src="/images/icons/up-arrow.png"
                  className="uq__arrow-img"
                  alt="arrow-img"
                />
              ) : (
                <img
                  src="/images/icons/down-arrow.png"
                  className="uq__arrow-img uq__down-arrow"
                  alt="arrow-img"
                />
              )}
            </div>
            {this.state.activeQuestion.question3 ? (
              <div className="uq__answer-box">
                <h4 className="standard__general-description uq__answer">
                  -Din start dorim să precizăm că îi considerăm de bună credință
                  și îi sprijinim pe toți ce care intenționează să construiască
                  și să își aducă aportul la dezvoltarea localități şi
                  îmbunătățirea mediului de viață în oraș.
                </h4>
                <h4 className="standard__general-description uq__answer">
                  Totuși există cazuri când unii beneficiari nu au respectat
                  legislația privind autorizarea construcțiilor . Legea 50/1991
                  prevede în astfel de cazuri sancțiuni : amendă , sistarea
                  lucrărilor, existând cazuri de readucere la starea inițială.
                  Astfel de situații nu sunt de dorit , nu ajută nimănui și
                  trebuie evitate.
                </h4>
              </div>
            ) : (
              ''
            )}
          </div>

          <div
            className="uq__box"
            onClick={e => this.toggleAnswer('question4')}
          >
            <div className="uq__question-box">
              <h4 className="standard__general-description uq__question">
                Documente necesare pentru efectuarea Recepției la terminarea
                lucrărilor de construire
              </h4>
              {this.state.activeQuestion.question4 ? (
                <img
                  src="/images/icons/up-arrow.png"
                  className="uq__arrow-img"
                  alt="arrow-img"
                />
              ) : (
                <img
                  src="/images/icons/down-arrow.png"
                  className="uq__arrow-img uq__down-arrow"
                  alt="arrow-img"
                />
              )}
            </div>
            {this.state.activeQuestion.question4 ? (
              <div className="uq__answer-box">
                <ul className="standard__document-list">
                  <li className="standard__list-item">
                    Cerere pentru eliberarea Procesului Verbal de Recepție
                  </li>
                  <li className="standard__list-item">
                    Plan de amplasament actualizat
                  </li>
                  <li className="standard__list-item">
                    Certificat de performanță energetică pentru locuinte și
                    anexe
                  </li>
                  <li className="standard__list-item">
                    Referatul proiectantului
                  </li>
                  <li className="standard__list-item">
                    Adeverință - Inspectoratul Județean în Construcții – Suceava
                  </li>
                  <ol className="uq__sublist">
                    <li className="uq__sublist-item">
                      Cerere tip pentru eliberarea adeverinței privind plata
                      cotelor 0,1% si 0,5 %
                    </li>
                    <li className="uq__sublist-item">
                      Comunicare privind încheierea lucrărilor de construire
                    </li>
                    <li className="uq__sublist-item">
                      Declarația privind valoarea finală a lucrărilor de
                      construire
                    </li>
                    <li className="uq__sublist-item">
                      Certificat fiscal cu valoarea impozabilă a
                      imobilului/imobilelor
                    </li>
                    <li className="uq__sublist-item">
                      Autorizația de Construire (conform cu originalul)
                    </li>
                    <li className="uq__sublist-item">
                      Copia chitanțelor privind achitarea cotelor de 0,1% si 0,5
                      %
                    </li>
                    <li className="uq__sublist-item">
                      {' '}
                      Buletin / Carte identitate – copie
                    </li>
                  </ol>
                  <li className="standard__list-item">
                    Diferenţă taxă autorizare ( ca urmare a declararii valoarii
                    finale a lucrărilor )
                  </li>
                </ul>
              </div>
            ) : (
              ''
            )}
          </div>

          <div
            className="uq__box"
            onClick={e => this.toggleAnswer('question5')}
          >
            <div className="uq__question-box">
              <h4 className="standard__general-description uq__question">
                Documente necesare pentru eliberarea unui Certificat de atestare
                a edificării construcțiilor
              </h4>
              {this.state.activeQuestion.question5 ? (
                <img
                  src="/images/icons/up-arrow.png"
                  className="uq__arrow-img"
                  alt="arrow-img"
                />
              ) : (
                <img
                  src="/images/icons/down-arrow.png"
                  className="uq__arrow-img uq__down-arrow"
                  alt="arrow-img"
                />
              )}
            </div>
            {this.state.activeQuestion.question5 ? (
              <div className="uq__answer-box">
                <ul className="standard__document-list">
                  <li className="standard__list-item">
                    Buletin / Carte Identitate - copie
                  </li>
                  <li className="standard__list-item">
                    Cerere pentru eliberarea Certificatului de atestare a
                    edificării construcțiilor
                  </li>
                  <li className="standard__list-item">
                    Plan de amplasament și delimitare imobil (actualizat)
                  </li>
                  <li className="standard__list-item">
                    Procesul Verbal de Recepție la terminarea lucrărilor de
                    construire
                  </li>
                </ul>
              </div>
            ) : (
              ''
            )}
          </div>

          <div
            className="uq__box"
            onClick={e => this.toggleAnswer('question6')}
          >
            <div className="uq__question-box">
              <h4 className="standard__general-description uq__question">
                Documente necesare pentru intabularea unei construcții noi
              </h4>
              {this.state.activeQuestion.question6 ? (
                <img
                  src="/images/icons/up-arrow.png"
                  className="uq__arrow-img"
                  alt="arrow-img"
                />
              ) : (
                <img
                  src="/images/icons/down-arrow.png"
                  className="uq__arrow-img uq__down-arrow"
                  alt="arrow-img"
                />
              )}
            </div>
            {this.state.activeQuestion.question6 ? (
              <div className="uq__answer-box">
                <ul className="standard__document-list">
                  <li className="standard__list-item">
                    Buletin / Carte identitate – copie
                  </li>
                  <li className="standard__list-item">
                    Autorizație de Construire – conform cu originalul
                  </li>
                  <li className="standard__list-item">
                    Plan de amplasament actualizat
                  </li>
                  <li className="standard__list-item">
                    Proces verbal de Recepție la terminarea lucrărilor
                  </li>
                  <li className="standard__list-item">
                    Certificat de atestare a edificării constructiilor ,
                    Procesul Verbal de Recepție la terminarea lucrărilor de
                    construire
                  </li>
                </ul>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UrbanismQuestions;
