import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import './ContactModal.css';
import '../../resources/StandardPage.css';
import Modal from 'react-modal';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(13, 8, 32, 0.9)',
    overflow: 'scroll'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: 'auto'
  }
};

class ContactModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        ariaHideApp={false}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Event Modal"
      >
        <div className="contact-modal">
          <h2 className="standard__main-title contact-modal__title">
            MESAJ NR. 2646
          </h2>
          <div className="contact-modal__info-box">
            <h3 className="contact-modal__general-info">Nume solicitant:</h3>
            <h3 className="contact-modal__specific-info">
              {this.props.eventObject.name}
            </h3>
          </div>
          <div className="contact-modal__info-box">
            <h3 className="contact-modal__general-info">
              Adresa de email solicitant:
            </h3>
            <h3 className="contact-modal__specific-info">
              {this.props.eventObject.email}
            </h3>
          </div>
          <div className="contact-modal__info-box">
            <h3 className="contact-modal__general-info">Număr de telefon:</h3>
            <h3 className="contact-modal__specific-info">
              {this.props.eventObject.phone}
            </h3>
          </div>
          <div className="contact-modal__info-box">
            <h3 className="contact-modal__general-info">Mesaj:</h3>
            <h3 className="contact-modal__specific-info contact-modal__message">
              {this.props.eventObject.message}
            </h3>
          </div>
          <div className="solicitation-modal__info-box">
            <h3 className="solicitation-modal__general-info solicitation-modal__status">
              Status solicitare:
            </h3>
            <FormControl className="solicitation-modal__status">
              <NativeSelect
                value={this.props.eventObject.status}
                onChange={event => this.props.updateStatusValue(event)}
                inputProps={{
                  name: 'status'
                }}
              >
                <option value="">Selecteaza Status</option>
                <option value="nou">Nou</option>
                <option value="desfasurare">In desfasurare</option>
                <option value="rezolvat">Rezolvat</option>
                <option value="respins">Respins</option>
              </NativeSelect>
            </FormControl>
            <div className="admin-form__submit">
              <button
                type="submit"
                className="general-button solicitation-modal__button"
                onClick={this.props.updateStatus}
              >
                Updateaza Status
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ContactModal.propTypes = {
  modalIsOpen: bool.isRequired,
  closeModal: func.isRequired
};

export default ContactModal;
