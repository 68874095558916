import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const publicDebatesService = {
  getAll,
  createOrUpdate,
  deleteDebate,
  search
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/public_debates/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function search(searchDebate, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/public_debates/search?title=${searchDebate.title}&archiveYear=${
    searchDebate.archiveYear
  }&page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}
function createOrUpdate(newDebate) {
  const requestOptions = {
    method: newDebate.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newDebate)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/public_debates${
    newDebate.id ? `/${newDebate.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function createBodyRequest(debate) {
  let formData = new FormData();

  formData.append('public_debate[title]', debate.title);
  formData.append('public_debate[add_date]', debate.date);
  formData.append('public_debate[responsable]', debate.responsable);

  const documentAnnouncement = debate.document_announcement;

  if (documentAnnouncement) {
    if (documentAnnouncement.url) {
      formData.append(
        `public_debate[document_announcement]`,
        documentAnnouncement.url
      );
    } else {
      formData.append(
        `public_debate[document_announcement]`,
        documentAnnouncement,
        documentAnnouncement.name
      );
    }
  }
  const documentProcedure = debate.document_procedure;

  if (documentProcedure) {
    if (documentProcedure.url) {
      formData.append(
        `public_debate[document_procedure]`,
        documentProcedure.url
      );
    } else {
      formData.append(
        `public_debate[document_procedure]`,
        documentProcedure,
        documentProcedure.name
      );
    }
  }
  const documentMinute = debate.document_minute;

  if (documentMinute) {
    if (documentMinute.url) {
      formData.append(`public_debate[document_minute]`, documentMinute.url);
    } else {
      formData.append(
        `public_debate[document_minute]`,
        documentMinute,
        documentMinute.name
      );
    }
  }
  const documentCentralizator = debate.document_centralizator;

  if (documentCentralizator) {
    if (documentCentralizator.url) {
      formData.append(
        `public_debate[document_centralizator]`,
        documentCentralizator.url
      );
    } else {
      formData.append(
        `public_debate[document_centralizator]`,
        documentCentralizator,
        documentCentralizator.name
      );
    }
  }

  return formData;
}
function deleteDebate(debateId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/public_debates/${debateId}`;

  return axios.delete(url, requestOptionsDelete);
}
