
import React, { Component } from 'react';
import './Admin.scss';
import AuthenticationService from '../../../services/AuthenticationService';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import ChangePassword from '../../../components/ChangePassword/ChangePassword';
import SecondFactorActivation from '../../../components/SecondFactorActivation/SecondFactorActivation';
import CreateUser from '../../../components/CreateUser/CreateUser';
import Users from '../../../components/Users/Users.jsx';
import { usersService } from '../../../services/UsersService';
import { errorService } from '../../../services/ErrorService';

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
      currentUser: {},
      isAdmin: false
    };

    this.authenticationService = new AuthenticationService();
  }

  componentDidMount() {
    usersService
      .getCurrentUser()
      .then(jsonResponse => {
        this.setState({ currentUser: jsonResponse.data.user });

        usersService
          .checkAdmin()
          .then(response => {
            this.setState({ isAdmin: true });
          })
          .catch(error => {
            this.setState({ isAdmin: false });

            if (error.response.status === 401) {
              errorService.handleUnauthorized();
              this.props.history.push('/admin/login');
            }
          });
      })
      .catch(error => {
        if (error.response.status === 401) {
          errorService.handleUnauthorized();
          this.props.history.push('/admin/login');
        }
      });
  }

  handleLogout = () => {
    this.authenticationService.logout();
    this.props.history.push('/admin/login');
  };

  handleChange = (event, tabValue) => {
    this.setState({
      tabValue
    });
  };

  handleChangeIndex = index => {
    this.setState({
      tabValue: index
    });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-page-header">
          <h2 className="admin-page__profile-title">
            Bine ai venit, {this.state.currentUser.username}!
          </h2>

          <button
            className="general-button log-out-button"
            onClick={() => this.handleLogout()}
          >
            Deconectează-te
          </button>
        </div>
        <Paper className="paper">
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleChange}
            name="tabValue"
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Profil Utilizator" />
            <Tab label="Parteneri" />
            <Tab label="Rapoarte" />
            <Tab label="Utilizatori" />
          </Tabs>
        </Paper>
        <SwipeableViews
          axis="x"
          index={this.state.tabValue}
          onChangeIndex={this.handleChangeIndex}
        >
          <div className="admin-page__profile-content">
            <ChangePassword currentUser={this.state.currentUser} />
            <SecondFactorActivation currentUser={this.state.currentUser} />
          </div>
          <div className="admin-page__soon">
            <p>In Curând</p>
          </div>
          <div className="admin-page__soon">
            <p>In Curând</p>
          </div>
          {this.state.isAdmin ? (
            <div className="admin-page__profile-content">
              <CreateUser />
              <Users />
            </div>
          ) : (
            <div className="admin-page__soon">
              <p>Nu aveti access la aceasta pagina</p>
            </div>
          )}
        </SwipeableViews>
      </div>
    );
  }
}

export default withRouter(Admin);
