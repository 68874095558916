import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const transparentEarningService = {
  getAll,
  createOrUpdate,
  deletePIDocument
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/transparent_earnings/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function createOrUpdate(newDocument) {
  const createRequestOptions = {
    method: newDocument.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newDocument)
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    createRequestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/transparent_earnings${
    newDocument.id ? `/${newDocument.id}` : ''
  }`;

  return fetch(url, createRequestOptions).then(
    responseHandleService.handleResponse
  );
}

function createBodyRequest(transparentEarningDocument) {
  let formData = new FormData();

  formData.append(
    'transparent_earnings[title]',
    transparentEarningDocument.title
  );

  formData.append(
    'transparent_earnings[add_date]',
    transparentEarningDocument.add_date
  );

  const document = transparentEarningDocument.document;

  if (document) {
    if (document.url) {
      formData.append(`transparent_earnings[document]`, document.url);
    } else {
      formData.append(
        `transparent_earnings[document]`,
        document,
        document.name
      );
    }
  }

  return formData;
}
function deletePIDocument(piDocumentId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/transparent_earnings/${piDocumentId}`;

  return axios.delete(url, requestOptionsDelete);
}
