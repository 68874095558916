import React from 'react';
import '../../../resources/AdminStandardPage.css';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import { publicInterestDocumentService } from '../../../services/PublicInterestDocumentsService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import ReactPaginate from 'react-paginate';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';

class PublicInterestDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      publicInterestDocument: {
        title: '',
        document: '',
        add_date:''
      },
      existingPublicInterestDocuments: [],
      deletePublicInterestDocument: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };
  }

  componentWillMount() {
    publicInterestDocumentService
      .getAll()
      .then(response => {
        this.setPublicInterestDocumentState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setPublicInterestDocumentState = response => {
    let existingPublicInterestDocuments = this.state
      .existingPublicInterestDocuments;
    let pagination = { ...this.state.pagination };

    existingPublicInterestDocuments = response.public_interests;
    pagination = response.pagination;

    this.setState({
      existingPublicInterestDocuments
    });
    this.setState({
      pagination
    });
  };

  handlePageClick = selectedPage => {
    publicInterestDocumentService
      .getAll(selectedPage, selectedPage + 1)
      .then(jsonResponse => {
        this.setPublicInterestDocumentState(jsonResponse);
      })
      .catch(error => {
        console.log(error);
      });
  };

  updateField = event => {
    const field = event.target.name;
    let publicInterestDocument = { ...this.state.publicInterestDocument };
    publicInterestDocument[field] = event.target.value;
    this.setState({
      publicInterestDocument
    });
  };

  resetForm = () => {
    let publicInterestDocument = { ...this.state.publicInterestDocument };
    publicInterestDocument = {
      title: '',
      document: ''
    };
    this.setState({ publicInterestDocument });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    publicInterestDocumentService
      .createOrUpdate(this.state.publicInterestDocument)
      .then(response => {
        this.resetForm();

        let existingPublicInterestDocuments = this.state
          .existingPublicInterestDocuments;

        let index = existingPublicInterestDocuments.findIndex(
          existingPublicInterestDocument =>
            existingPublicInterestDocument.id === response.id
        );

        if (index !== -1) {
          existingPublicInterestDocuments[index] = response;
        } else {
          existingPublicInterestDocuments.push(response);
        }

        this.setState({
          existingPublicInterestDocuments
        });

        this._notificationSystem.addNotification({
          message: `Documentul a fost ${
            this.state.publicInterestDocument.id ? 'editat' : 'adaugat'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  onDropDocument = documents => {
    let publicInterestDocument = { ...this.state.publicInterestDocument };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      publicInterestDocument.document = documents[0];

      self.setState({ publicInterestDocument });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let publicInterestDocument = { ...this.state.publicInterestDocument };
    publicInterestDocument.document = null;

    this.setState({ publicInterestDocument });
    this.setState({ disabledDocument: false });
  };

  openDeleteDialog = id => {
    let deletePublicInterestDocument = {
      ...this.state.deletePublicInterestDocument
    };
    deletePublicInterestDocument = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deletePublicInterestDocument });
  };

  closeDeleteDialog = () => {
    let deletePublicInterestDocument = { ...this.state.publicInterestDocument };
    deletePublicInterestDocument = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deletePublicInterestDocument });
  };

  setPublicInterestDocumentForEdit = publicInterest => {
    let publicInterestDocument = { ...this.state.publicInterestDocument };
    publicInterestDocument = Object.assign({}, publicInterest);
    this.setState({ publicInterestDocument });

    if (
      publicInterestDocument.document &&
      publicInterestDocument.document.url
    ) {
      this.setState({ disabledDocument: true });
    }
  };

  handleDelete = () => {
    publicInterestDocumentService
      .deletePIDocument(this.state.deletePublicInterestDocument.deleteId)
      .then(() => {
        let existingPublicInterestDocuments = this.state.existingPublicInterestDocuments.slice();
        existingPublicInterestDocuments = existingPublicInterestDocuments.filter(
          piDocument =>
            piDocument.id !== this.state.deletePublicInterestDocument.deleteId
        );
        this.setState({ existingPublicInterestDocuments });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Documentul a fost sters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h1 className="admin-switch-title default-title">
            Documente de interes public
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adauga un document de interes public:
                </h2>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu document *
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.publicInterestDocument.title}
                  />
                </div>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Data document *
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="date"
                    name="add_date"
                    onChange={this.updateField}
                    value={this.state.publicInterestDocument.add_date}
                  />
                </div>

                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.publicInterestDocument.document}
                    documentFilename={
                      this.state.publicInterestDocument.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.publicInterestDocument.id
                        ? 'Editează Document'
                        : 'Adaugă Document'}
                    </button>
                  </div>
                  {Object.values(this.state.publicInterestDocument) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingPublicInterestDocuments.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Documente de interes public existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingPublicInterestDocuments.map(
                      piDocument => (
                        <div
                          className="existing-items__content-card"
                          key={piDocument.id}
                        >
                          <div className="card-content provisions-title-content">
                            <h3 className="major-provisions-title">
                              {piDocument.title}
                            </h3>
                          </div>
                          <div className="existing-items__actions-card">
                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.setPublicInterestDocumentForEdit(
                                  piDocument
                                )
                              }
                            >
                              <h4 className="action-title"> Editează</h4>
                            </div>

                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.openDeleteDialog(piDocument.id)
                              }
                            >
                              <h4 className="action-title delete-button">
                                {' '}
                                Șterge
                              </h4>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <DeletePUGCertificates
                    opened={
                      this.state.deletePublicInterestDocument.openedDialog
                    }
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest document??"
                    aditionalText="Dupa stergere, documentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default PublicInterestDocuments;
