import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGConstruction from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import { pugConstructionService } from '../../../services/PugConstructionService';
import ReactPaginate from 'react-paginate';

class PUGConstruction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocuments: false,
      newAuthorization: {
        title: '',
        document: '',
        date:''
      },
      existingPermits: [],
      deleteAuthorization: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    pugConstructionService
      .getAll()
      .then(jsonResponse => {
        this.setPugConstructionState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    pugConstructionService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setPugConstructionState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setPugConstructionState = response => {
    let existingPermits = { ...this.state.existingPermits };
    let pagination = { ...this.state.pagination };
    existingPermits = response.construction_permits;
    pagination = response.pagination;

    this.setState({ existingPermits });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newAuthorization = this.state.newAuthorization;
    newAuthorization[field] = event.target.value;

    this.setState({
      newAuthorization
    });
  };

  onDropDocument = documents => {
    let newAuthorization = { ...this.state.newAuthorization };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAuthorization.document = documents[0];

      self.setState({ newAuthorization });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocuments = () => {
    let newAuthorization = { ...this.state.newAuthorization };
    newAuthorization.document = null;

    this.setState({ newAuthorization });
    this.setState({ disabledDocuments: false });
  };

  resetForm = () => {
    let newAuthorization = { ...this.state.newAuthorization };
    newAuthorization = {
      title: '',
      document: ''
    };

    this.setState({ newAuthorization });
    this.setState({ disabledDocuments: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newAuthorization.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/construction_permits/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/construction_permits/${this.state.newAuthorization.id}`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData(this.state.newAuthorization);

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingPermits = this.state.existingPermits.slice();

        let index = existingPermits.findIndex(
          authorization => authorization.id === jsonResponse.id
        );

        if (index !== -1) {
          existingPermits[index] = jsonResponse;
        } else {
          existingPermits.push(jsonResponse);
        }

        this.setState({ existingPermits });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Autorizatia a fost editata cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Autorizatia a fost adaugata cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'construction_permit[title]',
      this.state.newAuthorization.title
    );

    formData.append(
      'construction_permit[add_date]',
      this.state.newAuthorization.date
    );

    if (this.state.newAuthorization.document) {
      let document = this.state.newAuthorization.document;
      formData.append(`construction_permit[document]`, document, document.name);
    }

    return formData;
  }

  setAuthorizationForEdit = authorization => {
    let newAuthorization = { ...this.state.newAuthorization };
    newAuthorization = Object.assign({}, authorization);
    this.setState({ newAuthorization });
    this.setState({ disabledDocuments: true });
  };

  openDeleteDialog = id => {
    let deleteAuthorization = { ...this.state.deleteAuthorization };
    deleteAuthorization = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteAuthorization });
  };

  closeDeleteDialog = () => {
    let deleteAuthorization = { ...this.state.deleteAuthorization };
    deleteAuthorization = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteAuthorization });
  };

  handleDelete = () => {
    pugConstructionService
      .deletePugConstruction(this.state.deleteAuthorization.deleteId)
      .then(() => {
        let existingPermits = this.state.existingPermits.slice();
        existingPermits = existingPermits.filter(
          authorization =>
            authorization.id !== this.state.deleteAuthorization.deleteId
        );
        this.setState({ existingPermits });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Autorizatia a fost stearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Plan Urbanistic General
          </h3>
          <h1 className="admin-switch-title default-title">
            Autorizatii de Constructie
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adauga o autorizatie noua</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu autorizatie*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newAuthorization.title}
                  />
                </div>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Data autorizatie*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="date"
                    name="date"
                    onChange={this.updateField}
                    value={this.state.newAuthorization.date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document Autorizatie:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newAuthorization.document}
                    documentFilename={
                      this.state.newAuthorization.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newAuthorization.id
                        ? 'Editează autorizatie'
                        : 'Adaugă autorizatie'}
                    </button>
                  </div>
                  {Object.values(this.state.newAuthorization) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingPermits.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Autorizatii de construire/desfiintare existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingPermits.map(authorization => (
                      <div
                        className="existing-items__content-card"
                        key={authorization.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {authorization.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setAuthorizationForEdit(authorization)
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(authorization.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGConstruction
                    opened={this.state.deleteAuthorization.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti aceasta autorizatie?"
                    aditionalText="Dupa stergere, autorizatia nu va mai putea fi recuperata."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default PUGConstruction;
