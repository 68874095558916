import React, { Component } from 'react';
import './HeatingAids.css';
import MetaTags from 'react-meta-tags';
import '../../resources/StandardPage.css';
import DownloadCard from '../../components/DownloadCard/DownloadCard';

class HeatingAids extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Ajutoare incalzire</title>
          <meta
            name="description"
            content="Ajutoare incalzire Vicovu de Jos. Aici sunt prezentate detaliile legale si cerintele pentru ajutoarele de incalzire."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Ajutoare incalzire" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Asistență socială </h4>
          </div>
          <h2 className="standard__main-title">Ajutoare de incalzire</h2>
        </div>
        <div className="heating-aids">
          <div className="heating-aids__left-side">
            <div className="heating-aids__left-side-small">
              <h2 className="standard__main-title">Hotărâri de guvern</h2>
              <h4 className="standard__card-title standard__ordinary-title heating-aids__subtitle">
                SEZONUL RECE REPREZINTĂ PERIOADA DE 5 LUNI , CUPRINSĂ ÎNTRE DATA
                DE : 1 NOIEMBRIE 2018 – 31 MARTIE 2019
              </h4>
              <h4 className="standard__card-description standard__general-description heating-aids__info">
                LA STABILIREA VENITULUI NET MEDIU LUNAR PE MEMBRU DE FAMILIE SE
                IAU ÎN CALCUL TOATE VENITURILE NETE REALIZATE ÎN LUNA ANTERIOARĂ
                LUNII ÎN CARE SE SOLICITĂ DREPTUL.
              </h4>
              <p className="standard__card-description standard__general-description heating-aids__info">
                Cu exceptia ajutorului social, alocaţiei pentru susţinerea
                familiei prevăzute de Legea nr. 277/2010 privind alocaţia pentru
                susţinerea familiei, republicată, cu modificările ulterioare, a
                bugetului personal complementar prevăzut de Legea nr. 448/2006
                privind protecţia şi promovarea drepturilor persoanelor cu
                handicap, republicată, cu modificările şi completările
                ulterioare, a ajutoarelor de stat acordate în baza Ordonanţei
                Guvernului nr. 14/2010 privind măsuri financiare pentru
                reglementarea ajutoarelor de stat acordate producătorilor
                agricoli, începând cu anul 2010, aprobată cu modificări şi
                completări prin Legea nr. 74/2010, cu completările ulterioare, a
                burselor de studiu şi a burselor sociale, a sprijinului
                financiar prevăzut de Hotărârea Guvernului nr. 1.488/2004
                privind aprobarea criteriilor şi a cuantumului sprijinului
                financiar ce se acordă elevilor în cadrul Programului naţional
                de protecţie socială «Bani de liceu», cu modificările şi
                completările ulterioare,turil precum şi venie obţinute din
                activităţile cu caracter ocazional desfăşurate de zilieri în
                condiţiile Legii nr. 52/2011 privind exercitarea unor activităţi
                cu caracter ocazional desfăşurate de zilieri, cu modificările şi
                completările ulterioare.
              </p>
            </div>
            <div className="standard__cards">
              <DownloadCard
                requiredWidth="sa-required-width"
                title="DOCUMENTE NECESARE AJUTOR INCALZIRE, ALOCATIE DE SUSTINERE A FAMILIEI ȘI VENIT MINIM GARANTAT"
                url="/documents/Documente_ajutor_incalzire.pdf"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="PLAFOANE AJUTOR INCALZIRE"
                url="/documents/PLAFOANE_AJUTOR_ÎNCALZIRE.pdf"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="Cerere solicitare ajutor incalzire"
                url="/documents/cerereSolicitareAjutorSocial.pdf"
              />
            </div>
          </div>
          <div className="heating-aids__right-side">
            <h2 className="heating-aids__main-title" id="right-main-title">
              Acte necesare
            </h2>
            <ol className="standard__document-list" id="heating-aids-list">
              <li className="standard__list-item">
                Actele de identitate ale membrilor familiei (CI/BI, certificate
                de naştere copii sub 14 ani)
              </li>
              <li className="standard__list-item">
                Copie hotărâre judecătorească pentru divorţ (unde este cazul)
              </li>
              <li className="standard__list-item">
                Copie hotărâre încredinţare minor ( unde este cazul)
              </li>
              <li className="standard__list-item">
                Copie certificat deces (unde este cazul)
              </li>
              <li className="standard__list-item">
                Copie certificat căsătorie(unde este cazul)
              </li>
              <li className="standard__list-item">
                Declaraţie notarială pentru persoanele care se declară
                despărţite în fapt
              </li>
              <li className="standard__list-item">
                Adeverinţe de elev/student
              </li>
              <li className="standard__list-item">Adeverinţe salariat</li>
              <li className="standard__list-item">
                Adeverinţe de venit agricol pentru toţi membrii majori din
                familie
              </li>
              <li className="standard__list-item">
                Adeverinţe de la Administraţia Financiară pentru toţi membrii
                majori
              </li>
              <li className="standard__list-item">
                Cupoane de: pensii, somaj, îndemnizaţii creştere copil,
                indemnizaţii de handicap, alocaţii de stat
              </li>
              <li className="standard__list-item">
                Copie după actul de deţinere în proprietate sau folosinţă a
                locuinţei
              </li>
              <li className="standard__list-item">
                Copii după actele de identitate ale autoturismelor,
                motoretelor/motocicletelor, autovehicolelor, utilajelor agricole
                – aflate în stare de funcţionare
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

export default HeatingAids;
