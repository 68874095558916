import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import './AgriculturalRegister.css';
import DownloadCard from '../../components/DownloadCard/DownloadCard';
import MetaTags from 'react-meta-tags';

class AgriculturalRegister extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Registrul Agricol</title>
          <meta
            name="description"
            content="Registrul agricol a primariei Vicovu de Jos. Aici sunt prezentate legile in vigoare ce tin de registrul agricol."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Registrul Agricol" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Agricultură </h4>
          </div>
          <h2 className="standard__main-title">Registrul Agricol</h2>
        </div>
        <div className="heating-aids">
          <div className="heating-aids__left-side agricultural-register-side">
            <h2 className="standard__main-title">
              Norme tehnice privind modul de completare a registrului agricol
              pentru perioada 2015-2019
            </h2>
            <h4 className="standard__card-description standard__general-description">
              <b>Registrul agricol</b> constituie sursa administrativa de date
              pentru sistemul informational statistic, respectiv: statistica
              oficiala, pregatirea, organizarea si producerea de statistici
              pentru recensamantul cladirilor si al populatiei, ale unor anchete
              - pilot, organizarea unui sistem de observari statistice prin
              sondaj, pentru actualizarea Registrului statistic al
              exploatatiilor agricole si altele asemenea.
            </h4>
            <h4 className="standard__card-description standard__general-description">
              <b>Registrul agricol</b> asigura baza de date pentru realizarea cu
              ajutorul sistemelor electronice de calcul a verificarilor
              incrucisate intre datele din registrul agricol si datele inscrise
              in registre specifice tinute de alte institutii.
            </h4>
            <h4 className="standard__card-description standard__general-description">
              <b>
                Incepand cu data de 1 ianuarie 2018, registrul agricol se
                intocmeste si se tine la zi in format electronic.
              </b>
            </h4>
            <h4 className="standard__card-description standard__general-description">
              <b>
                (Art. 11) Termenele la care persoanele fizice și juridice au
                obligația să declare datele pentru înscrierea în registrul
                agricol
              </b>
            </h4>
            <h4 className="standard__card-description standard__general-description">
              Intre <b>5 ianuarie și ultima zi lucrătoare a lunii februarie</b>,
              pentru datele anuale privind membrii gospodăriei, terenul aflat în
              proprietate/folosință, clădirile și mijloacele de transport cu
              tracțiune animală și mecanică, mașinile, utilajele și instalațiile
              pentru agricultură și silvicultură, efectivele de animale
              existente în gospodărie/unitatea cu personalitate juridică la
              începutul fiecărui an, precum și modificările intervenite în
              cursul anului precedent în efectivele de animale pe care le dețin,
              ca urmare a vânzării-cumpărării, a produșilor obținuți, a morții
              sau a sacrificării animalelor ori a altor intrări-ieșiri;
            </h4>
            <h4 className="standard__card-description standard__general-description">
              Intre <b>1 și ultima zi lucrătoare a lunii mai</b>, pentru datele
              privind categoria de folosință a terenului, suprafețele cultivate,
              numărul pomilor în anul agricol respectiv;
            </h4>
            <h4 className="standard__card-description standard__general-description">
              <b>
                Persoanele fizice și juridice au obligația să declare date,
                pentru a fi înscrise în registrul agricol, și în afara
                termenelor prevăzute la lit. a) și b), în termen de 30 zile de
                la apariția oricărei modificări.
              </b>
            </h4>
          </div>
          <div className="heating-aids__right-side agricultural-register-right-side agricultural-register-side">
            <h4 className="standard__card-description standard__general-description agricultural-register-switch">
              Perioadele in care persoanele fizice si juridice au obligatia sa
              declare datele pentru inscrierea in registrul agricol sunt cele
              prevazute la art. 11 din Ordonanata Guvernului nr 28/2008
            </h4>
            <h4 className="standard__card-description standard__general-description agricultural-register-switch">
              Modelul declaratiei privind inregistrarea/modificarea datelor in
              registrul agricol este prevazut in Declaratie inregistrarea
              datelor in registurl agricol la prezentele norme tehnice.
            </h4>
            <div className="agricultural-register-boxes">
              <DownloadCard
                title="Declaratie inregistrarea datelor in registurl agricol"
                url="/documents/inregistrarea-datelor-registrul-agricol.pdf"
                titleColor="allocations-download-title"
                backgroundColor="allocations-download-card"
                flexClass="allocations-card-width"
              />
              <DownloadCard
                title="Nota de constatare"
                url="/documents/nota-de-constatare-registrul-agricol.pdf"
                titleColor="allocations-download-title"
                backgroundColor="allocations-download-card"
                flexClass="allocations-card-width"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgriculturalRegister;
