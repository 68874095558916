import axios from 'axios';
import AuthenticationService from './AuthenticationService';

export const usersService = {
  checkAdmin,
  getCurrentUser,
  getUsers,
  deleteUser,
  createOrUpdate
};

function getUsers() {
  const requestOptionsGet = {
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsGet.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  let url = `${process.env.REACT_APP_API_URL}/api/users`;

  return axios.get(url, requestOptionsGet);
}

function getCurrentUser() {
  const requestOptionsGet = {
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsGet.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  let url = `${process.env.REACT_APP_API_URL}/api/users/current_user`;

  return axios.get(url, requestOptionsGet);
}

function checkAdmin() {
  const requestOptionsGet = {
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsGet.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  let url = `${process.env.REACT_APP_API_URL}/api/users/check_admin`;

  return axios.get(url, requestOptionsGet);
}

function deleteUser(id) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/users/${id}`;

  return axios.delete(url, requestOptionsDelete);
}

function createOrUpdate(userData) {
  this.authentication = new AuthenticationService();

  const data = {
    user: {
      email: userData.email,
      username: userData.username
    }
  };

  userData.id
    ? (data.user.id = userData.id)
    : (data.user.password = userData.password);

  let url = `${process.env.REACT_APP_API_URL}/api/users/${
    userData.id ? userData.id : ''
  }`;

  const requestOptions = {
    method: userData.id ? 'PUT' : 'POST',
    url,
    headers: {
      Accept: 'application/json'
    },
    data
  };

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  return axios(requestOptions);
}
