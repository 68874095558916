// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class Environment extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Mediul Inconjurator
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h3 className="standard__submitted-title" id="environment-title">
                Calitatea solului
              </h3>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Conform art. 1. al Ordinului nr. 1552 din 03/12/2008 pentru
                aprobarea listei localităţilor pe judeţe unde există surse de
                nitraţi din activităţi agricole, judeţul SUCEAVA este cuprins în
                anexa acestui ordin cu 40 de localităţi declarate ca zone
                vulnerabile la poluarea cu nitraţi. Vicovu de Jos este în
                această listă, cu toate că agricultura se practică la o scară
                inferioara la nivelul localității, nivelul de poluare cu nitrați
                se încadrează în limitele admise.
              </h4>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                O sursă de poluare a solului o constituie depozitarea
                necontrolată sau necorespunzătoare a deșeurilor.
              </h4>
              <h3 className="standard__submitted-title" id="environment-title">
                Radioactivitatea mediului
              </h3>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Reţeaua Naţională de Supraveghere a Radioactivităţii Mediului
                (RNSRM) a fost înfiinţată în anul 1962 cu scopul de a
                supraveghea si controla respectarea prevederilor legale privind
                radioprotecţia mediului.
              </h4>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                La nivelul anului 2010, RNSRM a cuprins un număr de 37 de staţii
                din cadrul Agenţiilor pentru protecţia mediului, coordonarea
                ştiinţifica şi metodologică fiind asigurată de Laboratorul
                Naţional de referinţă pentru radioactivitatea mediului din
                cadrul ANPM.
              </h4>
              <h3 className="standard__submitted-title" id="environment-title">
                Managementul deșeurilor
              </h3>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                În acest moment SC MITROFAN SRL desfășoară activități de
                colectare și transport deșeuri menajere, deșeurile colectate de
                la populația din Vicovu de Jos fiind transportate la Rampa
                SUCEAVA , fără a realiza și selectarea acestora. Autoritățile
                locale din orașul Vicovu de Jos fac eforturi în ceea ce priveste
                aplicarea legislației privind protecția mediului, precum și
                oferirea unei educații ecologice tuturor locuitorilor orașului.
              </h4>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Sursele de deșeuri din Vicovu de Jos sunt: deșeuri menajere
                provenite de la populaţie şi agenţii economici, deșeuri
                stradale, deşeuri grădini, spaţii verzi, parcuri, deșeuri
                menajere spitaliceşti.
              </h4>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Compoziţia deșeurilor menajere din orasul Vicovu de Jos :
              </h4>
              <h5>- material organic cu umiditate ridicată – 12 %</h5>
              <h5>- hartie 3%</h5>
              <h5>- metale 0.05%</h5>
              <h5>- sticla 0.13%</h5>
              <h5>- plastic 1.6%</h5>
              <h5>- diverse alte reziduri 8%</h5>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Cantitatea de deșeuri menajere colectate anual in perioada
                2009-2013 – cca. 188 tone/an
              </h4>
              <h3 className="standard__submitted-title" id="environment-title">
                Spațiile verzi
              </h3>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Tipur de spatii verzi:
              </h4>
              <h5>1. Spatii verzi publice cu acces nelimitat</h5>
              <h5>- Parcuri: 5.500 mp</h5>
              <h5>- Alei: 53.550 mp</h5>
              <h5>
                - Spatiu verde rezervat extindere intravilan -- conf. P.U.G. :
                10.000 mp
              </h5>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                2. Spatii verzi publice cu acces limitat
              </h4>
              <h5>- Cimitire penticostale: 13.900 mp</h5>
              <h5>- Cimitire ortodoxe: 33.446 mp</h5>
            </div>
            <div className="about-vicov__right-content">
              <h3 className="standard__submitted-title" id="environment-title">
                Fondul Forestier
              </h3>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">An</div>
                  <div className="text text--final">
                    Suprafata fondului forestier (ha)
                  </div>
                  <div className="text text--final">
                    Volumul de lemn recoltat (mc)
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">2009</div>
                  <div className="text">435300</div>
                  <div className="text">2344200</div>
                </div>
                <div className="table__row">
                  <div className="text">2010</div>
                  <div className="text">435300</div>
                  <div className="text">2299600</div>
                </div>
                <div className="table__row">
                  <div className="text">2011</div>
                  <div className="text">436100</div>
                  <div className="text">2530900</div>
                </div>
                <div className="table__row">
                  <div className="text">2012</div>
                  <div className="text">436400</div>
                  <div className="text">2595300</div>
                </div>
                <div className="table__row">
                  <div className="text">2013</div>
                  <div className="text">436700</div>
                  <div className="text">2489600</div>
                </div>
                <div className="table__row">
                  <div className="text">2014</div>
                  <div className="text">437100</div>
                  <div className="text">1892300</div>
                </div>
              </div>
              <h3 className="standard__submitted-title" id="environment-title">
                Riscuri naturale
              </h3>
              <h4 className="standard__generic" id="gp-description">
                a) Riscuri generate de deșertificare
              </h4>
              <h4 className="standard__generic" id="gp-description">
                b) Riscuri generate de inundaţii
              </h4>
              <h4 className="standard__generic" id="gp-description">
                c) Incendii de pădure, pajişti comunale
              </h4>
              <h4 className="standard__generic" id="gp-description">
                d) Alunecari de teren
              </h4>
              <h4 className="standard__generic" id="gp-description">
                e) Cutremur
              </h4>

              <h3 className="standard__submitted-title" id="environment-title">
                Situaţii de urgenţă
              </h3>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Situaţia de urgenţă reprezinta un eveniment excepţional, cu
                caracter non militar, care prin amploare şi intensitate ameninţă
                viaţa şi sănătatea populaţiei, mediul înconjurător, valorile
                materiale şi culturale importante, iar pentru restabilirea
                stării de normalitate sunt necesare adoptarea de măsuri şi
                acţiuni urgente, alocarea de resurse suplimentare şi
                managementul unitar al forţelor şi mijloacelor implicate.
              </h4>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                În Vicovu de Jos funcţionează servicii de urgenţă profesioniste:
              </h4>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                1. <b>Serviciul Voluntar pentru Situaţii de Urgenţă (SVSU)</b>,
                organizat pe cele două activităţi principale:{' '}
              </h4>
              <h5>- informare publică şi prevenire;</h5>
              <h5>
                - intervenţie operativă directă sau ca forţă de sprijin a
                formaţiunilor profesioniste specializate.
              </h5>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Serviciile de deszăpezire - sunt asigurate de SVSU, cu
                următoarele utilaje:
              </h4>
              <h5>- autogreder</h5>
              <h5>- tractor</h5>
              <h5>- excavator</h5>
              <h5>- camion Mercedes 2641</h5>
              <h5>- Mercedes Unimog plug + sararita</h5>
              <h5>- 2 incarcatoare frontale</h5>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                2. <b>Politia locala a orașului Vicovu de Jos</b>
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Environment;
