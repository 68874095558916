import React from 'react';
import './NewsLetter.css';
import { Link } from 'react-router-dom';
import newsLetterItems from './NewsLetter.json';

class NewsLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsLetterItems: newsLetterItems
    };
  }
  render() {
    return (
      <div className="newsletter">
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Publici > </h4>
            <h4 className="standard__generic">Informații </h4>
          </div>
          <h2 className="standard__main-title">Buletinul Informativ</h2>
        </div>
        <div className="newsletter__wrapper">
          <div className="newsletter__title-box">
            <h2 className="newsletter__title standard__civil-status-title">
              Buletin Informativ
            </h2>
            <h4 className="newsletter__subtitle">
              publicat in conformitate cu prevederile Legii 544/2001 privind
              liberul acces la informatiile de interes public
            </h4>
          </div>
          <ol className="newsletter__list">
            {this.state.newsLetterItems.map(newsLetterItem => (
              <Link
                key={newsLetterItem.id}
                to={newsLetterItem.url}
                target={newsLetterItem.target ? newsLetterItem.target : ''}
                className="newsletter__link"
              >
                <div className="newsletter__box">
                  <img
                    className="newsletter__icon"
                    src="/images/icons/link.svg"
                    alt="NewsLetter"
                  />
                  <li className="newsletter__item">
                    {newsLetterItem.description}
                  </li>
                </div>
              </Link>
            ))}
          </ol>
        </div>
      </div>
    );
  }
}

export default NewsLetter;
