// @flow

import React, { Component } from 'react';
import './HamburgerMenu.css';
import menuJson from '../menu.json';
import HamburgerLink from '../HamburgerLink/HamburgerLink';

class HamburgerMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: false,
      menu: menuJson
    };
  }

  openMenu = () => {
    this.setState({ activeMenu: true });
    document.body.classList.add('has-active-menu');
  };

  closeMenu = () => {
    this.setState({ activeMenu: false });
    document.body.classList.remove('has-active-menu');
    // [...document.getElementsByClassName('hamburger__links active')].map(el =>
    //   el.classList.remove('active')
    // );
  };

  render() {
    return (
      <div className="hamburger">
        <span>
          <img
            onClick={this.openMenu}
            className="hamburger__icon"
            src="/images/icons/hamburger-menu.png"
            alt="hamburgetMenuLogo"
          />
        </span>
        <ul
          className={
            'hamburger__links' + (this.state.activeMenu ? ' active' : '')
          }
        >
          <li className="hamburger__link hamburger__link--menu">Meniu</li>
          {this.state.menu.map(listItem => (
            <HamburgerLink
              key={listItem.id}
              closeMenu={this.closeMenu}
              {...listItem}
            />
          ))}
        </ul>
        <span>
          <img
            onClick={this.closeMenu}
            className={
              'hamburger__close-icon' + (this.state.activeMenu ? ' active' : '')
            }
            src="/images/icons/close-icon.png"
            alt="close Icon"
          />
        </span>
      </div>
    );
  }
}

export default HamburgerMenu;
