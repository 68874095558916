import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const announcementsService = {
  getAll,
  create,
  deleteAnnouncement,
  getLast,
  search
};
const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};
function getAll(pageNumber) {
  const requestOptions = {
    method: 'GET',
    headers: Object.assign(
      {},
      {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    )
  };

  let url = `${process.env.REACT_APP_API_URL}/api/announcements/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function search(searchAnnouncement, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/announcements/search?title=${searchAnnouncement.title}&archiveYear=${
    searchAnnouncement.archiveYear
  }&page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function getLast() {
  const requestOptions = {
    headers: {
      Accept: 'application/json'
    }
  };

  let url = `${process.env.REACT_APP_API_URL}/api/announcements/last_announcement`;

  return axios.get(url, requestOptions);
}

function create(newAnnouncement) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(createBodyRequest(newAnnouncement))
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/announcements`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function createBodyRequest(strategy) {
  return {
    strategy: {
      title: strategy.title
    }
  };
}

function deleteAnnouncement(announcementId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/announcements/${announcementId}`;

  return axios.delete(url, requestOptionsDelete);
}
