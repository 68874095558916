import React, { Component } from 'react';
import SolicitationModal from '../../../../components/SolicitationModal/SolicitationModal';
import { infoFormService } from '../../../../services/InfoFormService';
import ReactPaginate from 'react-paginate';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../../constants/NotificationSystemStyle';

class InfoSolicitations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      solicitationModal: {},
      solicitationModalIsOpen: false,
      showMailButton: false,
      existingInfoSolicitations: [],
      pagination: {}
    };
  }

  componentWillMount() {
    infoFormService
      .getAll()
      .then(jsonResponse => {
        this.setInfoFormState(jsonResponse.data);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    infoFormService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setInfoFormState(jsonResponse.data);
      })
      .catch(error => console.log(error));
  };

  setInfoFormState = response => {
    let existingInfoSolicitations = this.state.existingInfoSolicitations;
    let pagination = { ...this.state.pagination };
    existingInfoSolicitations = response.info_forms;
    pagination = response.pagination;

    this.setState({ existingInfoSolicitations });
    this.setState({ pagination });
  };

  solicitationOpenModal = solicitation => {
    this.setState({ solicitationModalIsOpen: true });
    this.setState({ solicitationModal: solicitation });
  };

  closeModal = () => {
    this.setState({ solicitationModalIsOpen: false });
    this.setState({ showMailButton: false });
  };

  updateStatusValue = event => {
    let solicitationModal = { ...this.state.solicitationModal };

    solicitationModal.status = event.target.value;

    this.setState({ solicitationModal });
  };

  updateStatus = event => {
    event.preventDefault();

    infoFormService
      .updateStatus(this.state.solicitationModal)
      .then(jsonResponse => {
        this._notificationSystem.addNotification({
          message: 'Statusul a fost editat cu success.',
          level: 'success',
          position: 'tc'
        });

        this.setState({ showMailButton: true });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleSendEmail = () => {
    this.closeModal();

    infoFormService
      .statusUpdateEmail(this.state.solicitationModal)
      .then(jsonResponse => {
        this._notificationSystem.addNotification({
          message: 'Email-ul a fost trimis cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div>
        <table className="mariage-publications__table solicitations-table">
          <thead>
            <tr>
              <td width="100px" className="mariage-publications__table-title">
                Nr.crt.
              </td>
              <td width="100px" className="mariage-publications__table-title">
                ID Solicitare
              </td>
              <td width="100px" className="mariage-publications__table-title">
                Nume solicitant
              </td>
              <td width="100px" className="mariage-publications__table-title">
                Document Solicitare
              </td>
              <td width="100px" className="mariage-publications__table-title">
                Status solicitare
              </td>
            </tr>
          </thead>
          <tbody>
            {this.state.existingInfoSolicitations.map(
              (infoSolicitation, index) => (
                <tr key={infoSolicitation.id}>
                  <td className="mariage-publications__table-title">
                    <h4 className="mariage-publications__wed-name">
                      {index + 1}
                    </h4>
                  </td>
                  <td
                    width="100px"
                    className="mariage-publications__table-title solicitations-id"
                    onClick={() => this.solicitationOpenModal(infoSolicitation)}
                  >
                    {infoSolicitation.id}
                  </td>
                  <td className="mariage-publications__table-title">
                    {infoSolicitation.name}
                  </td>
                  {infoSolicitation.document.url ? (
                    <td className="mariage-publications__table-title mariage-publications__view-more">
                      <h4
                        className="mariage-publications__view-more"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}${
                              infoSolicitation.document.url
                            }`
                          )
                        }
                      >
                        Vizualizează Document
                      </h4>
                    </td>
                  ) : (
                    <td className="mariage-publications__table-title">-</td>
                  )}
                  <td className="mariage-publications__table-title">
                    <h4 className="mariage-publications__wed-name">
                      {infoSolicitation.status || '-'}
                    </h4>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
        {this.state.pagination.last_page > 1 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pagination.last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}

        <SolicitationModal
          eventObject={this.state.solicitationModal}
          modalIsOpen={this.state.solicitationModalIsOpen}
          closeModal={this.closeModal}
          updateStatus={this.updateStatus}
          updateStatusValue={this.updateStatusValue}
          showMailButton={this.state.showMailButton}
          handleSendEmail={this.handleSendEmail}
        />
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default InfoSolicitations;
