// @flow

import componentTypes from './componentTypes';

function getComponent(type) {
  return (
    componentTypes[type] ||
    require('../AboutVicov/GeneralPresentation/GeneralPresentation').default
  );
}

export default getComponent;
