import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Banner from '../../components/Banner/Banner';
import MetaTags from 'react-meta-tags';

class PublicAuctions extends Component {
  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Licitatii Publice</title>
          <meta
            name="description"
            content="Licitatii Publice Vicovu de Jos. Aici sunt prezentate documentele legate de licitatiile publice din Vicovu de Jos."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Licitatii Publice" />
        </MetaTags>
        <Banner
          title="Licitatii Publice"
          imageUrl="/images/bannerImages/poza-1-vicovu-de-jos.png"
        />
        <div className="pug-page__content">
          <div className="lawyer__cards">
            <Card className="lawyer__card-content">
              <CardContent>
                <Typography variant="h5" component="h3">
                  Inchiriere pasuni
                </Typography>
                <h4>Documentatie Atribuire Data limita: 17.04.2019</h4>
              </CardContent>
              <CardActions className="urbanism-certificate-page__actions-card">
                <Button
                  color="primary"
                  size="small"
                  onClick={() =>
                    window.open('/documents/licitatiepasune.pdf', '_blank')
                  }
                >
                  Vizualizeaza
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default PublicAuctions;
