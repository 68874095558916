import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const strategiesService = {
  getAll,
  createOrUpdate,
  deleteStrategy
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/strategies/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}

function createOrUpdate(newStrategy) {
  const requestOptions = {
    method: newStrategy.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newStrategy)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/strategies${
    newStrategy.id ? `/${newStrategy.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function createBodyRequest(strategy) {
  let formData = new FormData();

  formData.append('strategy[title]', strategy.title);

  const document = strategy.document;

  if (document) {
    if (document.url) {
      formData.append(`strategy[document]`, document.url);
    } else {
      formData.append(`strategy[document]`, document, document.name);
    }
  }

  return formData;
}

function deleteStrategy(strategyId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/strategies/${strategyId}`;

  return axios.delete(url, requestOptionsDelete);
}
