import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const reportsLaw52Service = {
  getAll,
  createOrUpdate,
  deleteReport
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/aplicability53_reports/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newReport) {
  const requestOptions = {
    method: newReport.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newReport)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/aplicability53_reports${
    newReport.id ? `/${newReport.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(report) {
  let formData = new FormData();

  formData.append('aplicability_report53[title]', report.title);
  formData.append('aplicability_report53[add_date]', report.date);

  const document = report.document;

  if (document) {
    if (document.url) {
      formData.append(`aplicability53_report[document]`, document.url);
    } else {
      formData.append(
        `aplicability53_report[document]`,
        document,
        document.name
      );
    }
  }

  return formData;
}
function deleteReport(reportId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/aplicability53_reports/${reportId}`;

  return axios.delete(url, requestOptionsDelete);
}
