import React, { Component } from 'react';
import { string } from 'prop-types';
import './DownloadCard.css';
import '../../resources/StandardPage.scss';
class DownloadCard extends Component {
  render() {
    return (
      <div
        id={this.props.requiredWidth}
        className={'standard__individual-card ' + this.props.flexClass}
      >
        <h3 className={'standard__card-title ' + this.props.titleColor}>
          {this.props.title}
        </h3>

        <div
          className={
            'standard__card download-card ' + this.props.backgroundColor
          }
        >
          <a href={this.props.url} download>
            <button className="standard__button download-button">
              <h4 className="standard__button-writing">Descarcă</h4>
            </button>
          </a>

          <h4
            className="standard__see-project view-button"
            onClick={() => window.open(this.props.url)}
          >
            Vizualizează
          </h4>
        </div>
      </div>
    );
  }
}

DownloadCard.propTypes = {
  title: string.isRequired,
  url: string.isRequired
};

export default DownloadCard;
