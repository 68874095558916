import React, { Component } from 'react';
import './AccessibilityWebsite.css';
import Icon from '@material-ui/core/Icon';

class AccessibilityWebsite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedMenu: false,
      fontSize: 100,
      highlightedLinks: false,
      normalFont: false,
      blackAndWhite: false,
      invertColors: false
    };
  }

  toggleAccessibilityMenu = () => {
    this.setState({ openedMenu: !this.state.openedMenu });
  };

  enlargeFontSize = () => {
    if (this.state.fontSize === 160) {
      return;
    }

    document.body.classList.remove(
      `accessibility-font-size-${this.state.fontSize}`
    );

    this.setState({ fontSize: this.state.fontSize + 20 }, () => {
      document.body.classList.add(
        `accessibility-font-size-${this.state.fontSize}`
      );
    });
  };

  decreaseFontSize = () => {
    if (this.state.fontSize === 40) {
      return;
    }

    document.body.classList.remove(
      `accessibility-font-size-${this.state.fontSize}`
    );

    this.setState({ fontSize: this.state.fontSize - 20 }, () => {
      document.body.classList.add(
        `accessibility-font-size-${this.state.fontSize}`
      );
    });
  };

  toggleHighlightLinks = () => {
    this.setState({ highlightedLinks: !this.state.highlightedLinks }, () => {
      this.state.highlightedLinks
        ? document.body.classList.add('accessibility-highlighted-links')
        : document.body.classList.remove('accessibility-highlighted-links');
    });
  };

  toggelNormalFont = () => {
    this.setState({ normalFont: !this.state.normalFont }, () => {
      this.state.normalFont
        ? document.body.classList.add('accessibility-normal-font')
        : document.body.classList.remove('accessibility-normal-font');
    });
  };

  toggleBlackAndWhite = () => {
    this.setState({ blackAndWhite: !this.state.blackAndWhite }, () => {
      this.state.blackAndWhite
        ? document.body.classList.add('accessibility-black-and-white')
        : document.body.classList.remove('accessibility-black-and-white');
    });
  };

  toggleInvertColors = () => {
    this.setState({ invertColors: !this.state.invertColors }, () => {
      this.state.invertColors
        ? document.body.classList.add('accessibility-invert-colors')
        : document.body.classList.remove('accessibility-invert-colors');
    });
  };

  resetAccessibilityRules = () => {
    this.setState({ fontSize: 100 });
    this.setState({ invertColors: false });
    this.setState({ blackAndWhite: false });
    this.setState({ normalFont: false });
    this.setState({ highlightedLinks: false });

    document.body.classList.remove(
      'accessibility-highlighted-links',
      'accessibility-normal-font',
      'accessibility-black-and-white',
      'accessibility-invert-colors',
      'accessibility-font-size-40',
      'accessibility-font-size-60',
      'accessibility-font-size-80',
      'accessibility-font-size-120',
      'accessibility-font-size-140',
      'accessibility-font-size-160'
    );
  };

  render() {
    return (
      <div className="accessibility">
        <img
          className="accessibility__icon"
          src="/images/icons/iconfinder_accessibility.png"
          alt="Accessibility Icon"
          onClick={() => this.toggleAccessibilityMenu()}
        />
        <ul
          className={
            this.state.openedMenu
              ? 'accessibility__menu-list accessibility__menu-list--visible'
              : 'accessibility__menu-list'
          }
        >
          <li
            className="accessibility__menu-list--item"
            onClick={() => this.enlargeFontSize()}
          >
            <Icon className="accessibility__menu-list--icon">add_circle</Icon>
            <a className="accessibility__menu-list--link" role="button">
              Mareste Textul
            </a>
          </li>
          <li
            className="accessibility__menu-list--item"
            onClick={() => this.decreaseFontSize()}
          >
            <Icon className="accessibility__menu-list--icon">
              remove_circle
            </Icon>
            <a className="accessibility__menu-list--link" role="button">
              Micsoreaza Textul
            </a>
          </li>
          <li
            className="accessibility__menu-list--item"
            onClick={() => this.toggleHighlightLinks()}
          >
            <Icon className="accessibility__menu-list--icon">
              format_underlined
            </Icon>
            <a className="accessibility__menu-list--link" role="button">
              Evidentiaza Link-urile
            </a>
          </li>
          <li
            className="accessibility__menu-list--item"
            onClick={() => this.toggelNormalFont()}
          >
            <Icon className="accessibility__menu-list--icon">
              font_download
            </Icon>
            <a className="accessibility__menu-list--link" role="button">
              Font Normal
            </a>
          </li>
          <li
            className="accessibility__menu-list--item"
            onClick={() => this.toggleBlackAndWhite()}
          >
            <Icon className="accessibility__menu-list--icon">
              format_color_reset
            </Icon>
            <a className="accessibility__menu-list--link" role="button">
              Alb Negru
            </a>
          </li>
          <li
            className="accessibility__menu-list--item"
            onClick={() => this.toggleInvertColors()}
          >
            <Icon className="accessibility__menu-list--icon">
              invert_colors
            </Icon>
            <a className="accessibility__menu-list--link" role="button">
              Inverseaza culorile
            </a>
          </li>
          <li
            className="accessibility__menu-list--item"
            onClick={() => this.resetAccessibilityRules()}
          >
            <Icon className="accessibility__menu-list--icon">autorenew</Icon>
            <a className="accessibility__menu-list--link" role="button">
              Reseteaza
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default AccessibilityWebsite;
