import React, { Component } from 'react';
import { string } from 'prop-types';
import './Banner.css';
import ImageNextGen from '../ImageNextGen/ImageNextGen';

class Banner extends Component {
  render() {
    return (
      <div className="banner">
        <ImageNextGen
          class="banner__image"
          imageUrl={this.props.imageUrl}
          imageUrlWebp={this.props.imageUrlWebp || this.props.imageUrl}
          imageUrlJpeg2000={this.props.imageUrlJpeg2000 || this.props.imageUrl}
        />
        <ImageNextGen
          class="banner__flag"
          imageUrl="/images/bannerImages/stema-vicovu-de-jos.png"
          imageUrlWebp="/images/bannerImages/stema-vicovu-de-jos.webp"
          imageUrlJpeg2000="/images/bannerImages/stema-vicovu-de-jos.jp2"
        />
        <h1 className="banner__title">{this.props.title}</h1>
      </div>
    );
  }
}

Banner.propTypes = {
  title: string.isRequired,
  imageUrl: string.isRequired
};

export default Banner;
