import React, { Component } from 'react';
import './Contact.css';
import '../StandardPage/StandardPage.css';
import ContactMap from '../../components/GoogleMaps/ContactMap/ContactMap';
import MetaTags from 'react-meta-tags';
import contactJson from './contact.json';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { googleVerificationService } from '../../services/GoogleVerificationService';
import { contactService } from '../../services/ContactService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import ReCAPTCHA from 'react-google-recaptcha';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactJson: contactJson,
      selectedEmail: {},
      contact: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      disableSubmit: false,
      googleRecapchaValue: ''
    };

    this.recaptchaRef = React.createRef();
  }

  componentWillMount() {
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  updateEmail = event => {
    const selectedEmail = JSON.parse(event.target.value);

    this.setState({ selectedEmail });
  };

  updateField = event => {
    const field = event.target.name;
    const contact = { ...this.state.contact };
    contact[field] = event.target.value;
    this.setState({
      contact
    });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    this.setState({ disableSubmit: true });

    googleVerificationService
      .verifyToken(this.state.googleRecapchaValue, true)
      .then(response => {
        if (response.data.success) {
          contactService
            .create(this.state.contact)
            .then(response => {
              this._notificationSystem.addNotification({
                message:
                  'Am primit cererea dumneavoastra. Va vom contacta in cel mai scurt timp.',
                level: 'success',
                position: 'tc'
              });

              this.setState({ disableSubmit: false });
              this.recaptchaRef.current.reset();
            })
            .catch(error => {
              this._notificationSystem.addNotification({
                message:
                  'A aparut o problema la adaugare, faceti refresh la pagina si incercati din nou sau contactati direct primaria daca problema persista.',
                level: 'error',
                position: 'tc'
              });

              this.setState({ disableSubmit: false });
              this.recaptchaRef.current.reset();
            });
        } else {
          this._notificationSystem.addNotification({
            message: 'Va rugam completati ReCAPTCHA.',
            level: 'error',
            position: 'tc'
          });
          this.setState({ disableSubmit: false });
        }
      });
  };

  onChange = value => {
    this.setState({ googleRecapchaValue: value });
  };

  render() {
    return (
      <div className="contact-page">
        <MetaTags>
          <title>Contact Primaria Vicovu de Jos</title>
          <meta name="description" content="Contact Primaria Vicovu de Jos" />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Contact Primaria Vicovu de Jos" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Vicovu de Jos > </h4>
            <h4 className="standard__generic">Contact </h4>
          </div>
          <h2 className="standard__main-title">Contact</h2>
        </div>

        <div className="contact-page__content">
          <div className="contact-page__contact-info contact-page__item">
            <div className="contact-page__item-box contact-page__item-box-left">
              <h3 className="standard__submitted-title contact-page__title">
                Date de Contact
              </h3>
              <div className="contact-page__methods">
                {this.state.contactJson.map(contactItem => (
                  <div
                    className="contact-page__individual-method"
                    key={contactItem.id}
                  >
                    <img
                      src={contactItem.url}
                      className="contact-page__info-img"
                      alt="contact"
                    />
                    {contactItem.link ? (
                      <a
                        className="contact-page__info contact-page__info-link"
                        href={contactItem.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h4>{contactItem.name}</h4>
                      </a>
                    ) : contactItem.emailsList ? (
                      <div className="contact-page__info">
                        <FormControl className="">
                          <NativeSelect onChange={this.updateEmail}>
                            <option value="name">Selecteaza Departament</option>
                            {contactItem.emailsList.map(email => (
                              <option
                                key={email.id}
                                value={JSON.stringify(email)}
                              >
                                {email.name}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                        <h4 className="contact-page__email">
                          <a href={`mail:${this.state.selectedEmail.email}`}>
                            {this.state.selectedEmail.email}
                          </a>
                        </h4>
                      </div>
                    ) : (
                      <h4 className="contact-page__info">{contactItem.name}</h4>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="contact-page__schedule contact-page__item">
            <div className="contact-page__item-box contact-page__item-box-right">
              <h3
                className="standard__submitted-title contact-page__title"
                id="switch"
              >
                Program
              </h3>
              <div className="contact-page__schedule-info">
                <div className="contact-page__schedule-details">
                  <h4 className="standard__generic" id="switch">
                    LUNI-VINERI
                  </h4>
                  <h4 className="standard__generic" id="switch">
                    08:00-16:00
                  </h4>
                </div>
                <div className="contact-page__schedule-details">
                  <h4 className="standard__generic" id="switch">
                    SAMBATA-DUMINICA
                  </h4>
                  <h4 className="standard__generic" id="switch">
                    Inchis
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="contact-page__petition contact-page__item--full-width">
            <div className="contact-page__item-box contact-page__item-box-left--full-width">
              <h3 className="standard__submitted-title contact-page__title">
                Petitii
              </h3>
              <h4 className="standard__generic contact-page__switch">
                Pentru orice petitie ne puteti scrie la adresa:
              </h4>
              <h4 className="standard__generic" id="audience-info">
                office@primariavicovudejos.com
              </h4>
            </div>
          </div>

          <div className="contact-page__form-box contact-page__item">
            <div className="contact-page__item-box contact-page__item-box-left">
              <h3 className="standard__submitted-title contact-page__title">
                Formular de contact
              </h3>
              <h4 className="standard__generic" id="audience-info">
                Pentru orice intrebare sau nelamurire, completati formularul si
                va vom raspunde in cel mai scurt timp!
              </h4>
              <form
                className="contact-page__form"
                onSubmit={this.handleFormSubmit}
              >
                <input
                  required
                  className="contact-page__field"
                  type="text"
                  name="name"
                  onChange={this.updateField}
                  value={this.state.contact.name}
                  placeholder="Nume și prenume *"
                />
                <input
                  required
                  className="contact-page__field"
                  type="email"
                  name="email"
                  onChange={this.updateField}
                  value={this.state.contact.email}
                  placeholder="Adresa de email *"
                />
                <input
                  required
                  className="contact-page__field"
                  type="number"
                  name="phone"
                  onChange={this.updateField}
                  value={this.state.contact.phone}
                  placeholder="Număr de telefon *"
                />
                <textarea
                  required
                  rows="5"
                  className="contact-page__field contact-page__textarea"
                  type="text"
                  name="message"
                  onChange={this.updateField}
                  value={this.state.contact.message}
                  placeholder="Mesaj *"
                />
                <br />
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey={process.env.REACT_APP_PP_GOOGLE_VERIFICATION_V2}
                  onChange={this.onChange}
                />
                <br />
                <button
                  type="submit"
                  className="contact-page__button"
                  disabled={this.state.disableSubmit}
                >
                  <h5 className="contact-page__button-writing">
                    Trimite Mesaj
                  </h5>
                </button>
              </form>
            </div>
          </div>

          <div className="contact-page__form-box contact-page__item">
            <div className="contact-page__item-map">
              <ContactMap />
            </div>
          </div>
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default Contact;
