import React from 'react';
import './InfoForm.css';
import '../Admin/Home/Admin.css';
import AttachmentField from '../../components/AttachmentField/AttachmentField';
import { infoFormService } from '../../services/InfoFormService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import { Link } from 'react-router-dom';
import { googleVerificationService } from '../../services/GoogleVerificationService';
import ReCAPTCHA from 'react-google-recaptcha';
import StatusModal from '../../components/StatusModal/StatusModal';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { dataDeletionService } from '../../services/DataDeletionService';

class InfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoSolicitation: {
        name: '',
        address: '',
        email: '',
        phone: '',
        requestedInformation: '',
        document: ''
      },
      disabledDocument: false,
      disableSubmit: false,
      googleRecapchaValue: '',
      tabValue: 0,
      currentUser: {},
      statusModalIsOpen: false,
      deleteModalIsOpen: false
    };

    this.recaptchaRef = React.createRef();
  }

  openStatusModal = () => {
    this.setState({ statusModalIsOpen: true });
  };
  openDeleteModal = () => {
    this.setState({ deleteModalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ statusModalIsOpen: false });
    this.setState({ deleteModalIsOpen: false });
  };

  componentWillMount() {
    if (this.props.match.params.token) {
      dataDeletionService
        .verifyToken({ token: { value: this.props.match.params.token } })
        .then(response => {
          this._notificationSystem.addNotification({
            message: 'Datele dumneavoastra au fost sterse cu success.',
            level: 'success',
            position: 'tc'
          });

          setTimeout(() => {
            this.props.history.push('/formular-solicitare-informatii');
          }, 10000);
        })
        .catch(error => {
          if (error && error.response && error.response.status === 409) {
            if (error.response.data.token_expired) {
              this._notificationSystem.addNotification({
                message:
                  'Tokenul folosit este expirat, va rugam reluati procesul.',
                level: 'error',
                position: 'tc'
              });
            }

            if (error.response.data.token_invalid) {
              this._notificationSystem.addNotification({
                message:
                  'Tokenul folosit este invalid, va rugam reluati procesul.',
                level: 'error',
                position: 'tc'
              });
            }

            setTimeout(() => {
              this.props.history.push('/formular-solicitare-informatii');
            }, 5000);

            return;
          }

          if (error && error.response && error.response.status === 404) {
            this._notificationSystem.addNotification({
              message:
                'Tokenul folosit nu mai este activ sau adresa de email nu mai este in sistemul nostru.',
              level: 'error',
              position: 'tc'
            });

            setTimeout(() => {
              this.props.history.push('/formular-solicitare-informatii');
            }, 5000);

            return;
          }

          this._notificationSystem.addNotification({
            message:
              'A aparut o problema, va rugam faceti refresh si mai incercati o data. Daca problema persista va rugam sa ne contactati.',
            level: 'error',
            position: 'tc'
          });
        });
    }
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setInfoFormState = response => {
    let infoSolicitation = { ...this.state.infoSolicitation };
    infoSolicitation = response.info_solicitation;
    this.setState({
      infoSolicitation
    });
  };

  updateField = event => {
    const field = event.target.name;
    const infoSolicitation = { ...this.state.infoSolicitation };
    infoSolicitation[field] = event.target.value;
    this.setState({
      infoSolicitation
    });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    this.setState({ disableSubmit: true });

    googleVerificationService
      .verifyToken(this.state.googleRecapchaValue, true)
      .then(response => {
        if (response.data.success) {
          infoFormService
            .create(this.state.infoSolicitation)
            .then(response => {
              this._notificationSystem.addNotification({
                message:
                  'Am primit cererea dumneavoastra. Va vom contacta in cel mai scurt timp.',
                level: 'success',
                position: 'tc'
              });
              this.setState({ disableSubmit: false });
              this.recaptchaRef.current.reset();
            })
            .catch(error => {
              this._notificationSystem.addNotification({
                message:
                  'A aparut o problema la adaugare, faceti refresh la pagina si incercati din nou sau contactati direct primaria daca problema persista.',
                level: 'error',
                position: 'tc'
              });
              this.setState({ disableSubmit: false });
              this.recaptchaRef.current.reset();
            });
        } else {
          this._notificationSystem.addNotification({
            message: 'Va rugam completati ReCAPTCHA.',
            level: 'error',
            position: 'tc'
          });
          this.setState({ disableSubmit: false });
        }
      });
  };

  onDropDocument = documents => {
    let infoSolicitation = { ...this.state.infoSolicitation };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      infoSolicitation.document = documents[0];

      self.setState({ infoSolicitation });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let infoSolicitation = { ...this.state.infoSolicitation };
    infoSolicitation.document = null;

    this.setState({ infoSolicitation });
    this.setState({ disabledDocument: false });
  };

  onChange = value => {
    this.setState({ googleRecapchaValue: value });
  };

  render() {
    return (
      <div className="info-page">
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Solicitare informatii </h4>
          </div>
          <h2 className="standard__main-title">
            Formular pentru solicitare informatii
          </h2>
        </div>
        <h4 className="standard__card-description standard__general-description info-form__label info-form__indication">
          Pentru a face o solicitare de informații de interes public, vă rugăm
          să completați formularul de mai jos:
        </h4>
        <form className="info-form" onSubmit={this.handleFormSubmit}>
          <div className="info-form__field-box">
            <input
              required
              className="contact-page__field"
              type="text"
              name="name"
              onChange={this.updateField}
              value={this.state.infoSolicitation.name}
              placeholder="Nume și prenume *"
            />
            <input
              className="contact-page__field"
              type="text"
              name="address"
              onChange={this.updateField}
              value={this.state.infoSolicitation.address}
              placeholder="Adresa"
            />
            <input
              required
              className="contact-page__field"
              type="email"
              name="email"
              onChange={this.updateField}
              value={this.state.infoSolicitation.email}
              placeholder="Email *"
            />
            <input
              className="contact-page__field"
              type="number"
              name="phone"
              onChange={this.updateField}
              value={this.state.infoSolicitation.phone}
              placeholder="Număr de telefon *"
            />
            <textarea
              required
              rows="5"
              className="contact-page__field contact-page__textarea"
              type="text"
              name="requestedInformation"
              onChange={this.updateField}
              value={this.state.infoSolicitation.requestedInformation}
              placeholder="Informatii de interes public solicitate *"
            />
            <h4 className="info-form__label standard__card-description">
              Adauga un fisier:
            </h4>
            <AttachmentField
              onDrop={this.onDropDocument}
              className="info-form__attachement-field"
              removeDocument={this.removeDocument}
              disabledDocuments={this.state.disabledDocument}
              document={this.state.infoSolicitation.document}
              documentFilename={this.state.document_filename}
              type="application/pdf"
            />
            <Link
              to="/documents/Prelucrarea-datelor-personale.pdf"
              className="info-form__note"
              target="_blank"
            >
              <h4 className="info-form__label info-form__note standard__card-description">
                Nota de informare privind prelucrarea datelor personale
              </h4>
            </Link>
            <div className="info-form__accept-conditions-box">
              <input
                required
                type="checkbox"
                onChange={this.updateField}
                value={this.state.infoSolicitation.accepted}
                name="accepted"
                className="info-form__accept-conditions"
              />
              <h4 className="info-form__label standard__card-description">
                Am citit si sunt de acord cu prelucrarea datelor cu caracter
                personal*
              </h4>
            </div>
            <br />
            <ReCAPTCHA
              ref={this.recaptchaRef}
              sitekey={process.env.REACT_APP_PP_GOOGLE_VERIFICATION_V2}
              onChange={this.onChange}
            />
            <br />
            <button
              type="submit"
              className="info-form__button"
              disabled={this.state.disableSubmit}
            >
              <h5 className="info-form__button-writing">Trimite Solicitare</h5>
            </button>
          </div>
        </form>
        <div className="info-form__actions-box">
          <button
            className="info-form__actions-button"
            onClick={() => this.openStatusModal()}
          >
            <h5 className="info-form__action-button-writing">
              Vezi statusul solicitarii
            </h5>
          </button>
          <button
            className="info-form__actions-button"
            onClick={() => this.openDeleteModal()}
          >
            <h5 className="info-form__action-button-writing">
              Sterge datele personale
            </h5>
          </button>
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
        <StatusModal
          modalIsOpen={this.state.statusModalIsOpen}
          closeModal={this.closeModal}
          notificationSystem={this._notificationSystem}
        />
        <DeleteModal
          modalIsOpen={this.state.deleteModalIsOpen}
          closeModal={this.closeModal}
          notificationSystem={this._notificationSystem}
        />
      </div>
    );
  }
}

export default InfoForm;
