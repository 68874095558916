import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class DeathRecord extends Component {
  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Inregistrarea Decesului</title>
          <meta
            name="description"
            content="Inregistrarea Decesului Primaria Vicovu de Jos."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Inregistrarea Decesului" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Înregistrarea Decesului </h4>
          </div>
          <h2 className="standard__main-title">Înregistrarea Decesului</h2>
        </div>

        <div className="standard__wrapper standard__death-record-wrapper">
          <h4 className="standard__card-description standard__general-description">
            Actul de deces se întocmeşte la Serviciul Public Comunitar Local de
            Evidenţă a Persoanelor sau la primăria unităţii administrativ, în a
            cărei rază teritorială s-a produs decesul, pe baza declaraţiei
            verbale făcute de către membrii familiei decedatului, iar în lipsa
            acestora de către colocatari, vecini, administratorul imobilului,
            medicul sau alt cadru din unitatea sanitară unde s-a produs decesul.
          </h4>
          <h3 className="standard__card-title standard__ordinary-title standard__death-record-title">
            Declararea decesului se face în termen de <b>3 zile</b> de la data
            încetării din viaţă a persoanei.
          </h3>
          <h4 className="standard__card-description standard__general-description">
            Când decesul se datorează unei sinucideri, unui accident sau altor
            cauze violente precum şi în cazul găsirii unui cadavru, declaraţia
            se face în termen de 48 de ore, socotit din momentul decesului sau
            al găsirii cadavrului. În astfel de cazuri, înregistrarea se face pe
            baza certificatului medical constatator al decesului şi a dovezii
            eliberate de poliţie sau de parchet.
          </h4>
          <h4 className="standard__card-description standard__general-description">
            În cazul în care decesul nu a fost declarat în termen, întocmirea
            actului de deces se face cu aprobarea parchetului, iar declaraţia se
            face în scris, arătând motivele întârzierii.
          </h4>
          <h3 className="standard__card-title standard__ordinary-title standard__death-record-title">
            Acte Necesare Inregistrarii Decesului
          </h3>
          <ol className="standard__document-list">
            <li className="standard__list-item">
              Certificatul medical constatator al decesului, întocmit şi semnat
              de medicul sau cadrul mediu sanitar care a făcut constatarea în
              care trebuie consemnată cauza decesului
            </li>
            <li className="standard__list-item">
              Actul de identitate al celui decedat (în original şi copie)
            </li>
            <li className="standard__list-item">
              Livretul militar sau adeverinţa de recrutare, după caz, pentru
              persoanele supuse obligaţiilor militare
            </li>
            <li className="standard__list-item">
              Certificatul de naştere, căsătorie al decedatului (în original şi
              copie)
            </li>
            <li className="standard__list-item">
              Actul de identitate al declarantului (în original şi copie)
            </li>
          </ol>
          <h4 className="standard__card-description standard__general-description">
            În cazul în care declarantul decesului nu poate prezenta
            certificatele de stare civilă şi/sau actul de identitate al
            persoanei decedate, acesta declară în scris, motivul neprezentării
            acestora, precum şi datele de stare civilă ale decedatului.
          </h4>
        </div>
      </div>
    );
  }
}

export default DeathRecord;
