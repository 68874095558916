import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import { majorProvisionsService } from '../../../services/MajorProvisionsService';

class MajorProvisions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newMajorProvision: {
        title: '',
        document: '',
        data_dispozitie: '',
        provision_number: ''
      },
      existingMajorProvisions: [],
      deleteMajorProvision: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    majorProvisionsService
      .getAll()
      .then(jsonResponse => {
        this.setMajorProvisionState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    majorProvisionsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setMajorProvisionState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setMajorProvisionState = response => {
    let existingMajorProvisions = { ...this.state.existingMajorProvisions };
    let pagination = { ...this.state.pagination };
    existingMajorProvisions = response.major_provisions;
    pagination = response.pagination;

    this.setState({ existingMajorProvisions });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newMajorProvision = this.state.newMajorProvision;
    newMajorProvision[field] = event.target.value;

    this.setState({
      newMajorProvision
    });
  };

  onDropDocument = documents => {
    let newMajorProvision = { ...this.state.newMajorProvision };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newMajorProvision.document = documents[0];

      self.setState({ newMajorProvision });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newMajorProvision = { ...this.state.newMajorProvision };
    newMajorProvision.document = null;
    newMajorProvision.document_filename = '';

    this.setState({ newMajorProvision });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newMajorProvision = { ...this.state.newMajorProvision };
    newMajorProvision = {
      title: '',
      document: '',
      data_dispozitie: '',
      provision_number: ''
    };

    this.setState({ newMajorProvision });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newMajorProvision.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/major_provisions/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/major_provisions/${this.state.newMajorProvision.id}`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingMajorProvisions = this.state.existingMajorProvisions.slice();

        let index = existingMajorProvisions.findIndex(
          existingMajorProvisionDocument =>
            existingMajorProvisionDocument.id === jsonResponse.id
        );

        if (index !== -1) {
          existingMajorProvisions[index] = jsonResponse;
        } else {
          existingMajorProvisions.push(jsonResponse);
        }

        this.setState({ existingMajorProvisions });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Dispozitia a fost editata cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Dispozitia a fost adaugata cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'major_provision[title]',
      this.state.newMajorProvision.title
    );

    formData.append(
      'major_provision[data_dispozitie]',
      this.state.newMajorProvision.data_dispozitie
    );
    formData.append(
      'major_provision[provision_number]',
      this.state.newMajorProvision.provision_number
    );

    if (this.state.newMajorProvision.document_filename) {
      formData.append(
        `major_provision[document]`,
        this.state.newMajorProvision.document_filename
      );
    } else {
      if (this.state.newMajorProvision.document) {
        let document = this.state.newMajorProvision.document;
        formData.append(`major_provision[document]`, document, document.name);
      }
    }

    return formData;
  }

  setMajorProvisionDocumentForEdit = newMajorProvisionDoc => {
    let newMajorProvision = { ...this.state.newMajorProvision };
    newMajorProvision = Object.assign({}, newMajorProvisionDoc);
    this.setState({ newMajorProvision });

    if (newMajorProvision.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteMajorProvision = { ...this.state.deleteMajorProvision };
    deleteMajorProvision = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteMajorProvision });
  };

  closeDeleteDialog = () => {
    let deleteMajorProvision = { ...this.state.deleteMajorProvision };
    deleteMajorProvision = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteMajorProvision });
  };

  handleDelete = () => {
    majorProvisionsService
      .deleteMajorProvision(this.state.deleteMajorProvision.deleteId)
      .then(() => {
        let existingMajorProvisions = this.state.existingMajorProvisions.slice();
        existingMajorProvisions = existingMajorProvisions.filter(
          MajorProvision =>
            MajorProvision.id !== this.state.deleteMajorProvision.deleteId
        );
        this.setState({ existingMajorProvisions });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Proiectul a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Administrație
          </h3>
          <h1 className="admin-switch-title default-title">
            Dispozițiile Primarului
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adaugă o dispoziție nouă</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu dispoziție*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field title-field"
                      type="text"
                      name="title"
                      value={this.state.newMajorProvision.title}
                      onChange={this.updateField}
                    />
                  </label>
                  <h4 className="label-title label-title-provisions">
                    Dată eliberare dispoziție*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field date-field"
                      type="date"
                      name="data_dispozitie"
                      value={this.state.newMajorProvision.data_dispozitie}
                      onChange={this.updateField}
                    />
                  </label>
                  <h4 className="label-title label-title-provisions">
                    Număr dispoziție*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field date-field"
                      id="number-field"
                      type="number"
                      name="provision_number"
                      value={this.state.newMajorProvision.provision_number}
                      onChange={this.updateField}
                    />
                  </label>
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document dispoziție:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newMajorProvision.document}
                    documentFilename={
                      this.state.newMajorProvision.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newMajorProvision.id
                        ? 'Editează Dispoziție'
                        : 'Adaugă Dispoziție'}
                    </button>
                  </div>
                  {Object.values(this.state.newMajorProvision) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      size="small"
                      color="secondary"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingMajorProvisions.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items-title label-title ">
                    Dispoziții existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingMajorProvisions.map(majorProvisions => (
                      <div
                        className="existing-items__content-card"
                        key={majorProvisions.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {majorProvisions.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setMajorProvisionDocumentForEdit(
                                majorProvisions
                              )
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(majorProvisions.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteMajorProvision.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti aceasta dispozitie?"
                    aditionalText="Dupa stergere, dispoztitia nu va mai putea fi recuperata."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default MajorProvisions;
