import React from 'react';
import '../StandardPage/StandardPage.css';
import ReactPaginate from 'react-paginate';
import { workAgendaService } from '../../services/WorkAgendaService';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class WorkAgenda extends React.Component {
  constructor(props) {
    super();
    this.state = {
      workAgendas: [],
      searchWorkAgendas: {
        title: '',
        archiveYear: 2023
      },
      archiveYears: archiveJson,
      pagination: {}
    };
  }

  componentWillMount() {
    workAgendaService
      .getAll()
      .then(response => {
        this.setWorkAgendaState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  setWorkAgendaState = response => {
    let workAgendas = { ...this.state.workAgendas };
    workAgendas = response.work_agendas;
    let pagination = { ...this.state.pagination };
    pagination = response.pagination;
    this.setState({
      workAgendas
    });
    this.setState({
      pagination
    });
  };

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  search = (pageNumber = 1) => {
    workAgendaService
      .search(this.state.searchWorkAgendas, pageNumber)
      .then(response => {
        this.setWorkAgendaState(response);
      })
      .catch(error => console.log(error));
  };

  updateField = event => {
    const fieldName = event.target.name;
    const searchWorkAgendas = this.state.searchWorkAgendas;
    searchWorkAgendas[fieldName] = event.target.value;
    this.setState(
      {
        searchWorkAgendas
      },
      () => {
        if (fieldName === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  render() {
    return (
      <div className="standard">
        <title>Agenda de lucru</title>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Agenda de lucru</h4>
          </div>
          <h2 className="standard__main-title">Agenda de lucru</h2>
        </div>

        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchWorkAgendas.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchWorkAgendas.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div className="standard__cards standard__projects-wrapper-few">
            {this.state.workAgendas.length > 0 ? (
              this.state.workAgendas.map(workAgenda => (
                <div key={workAgenda.id} className="standard__individual-card">
                  <h3 className="standard__card-title">
                    {workAgenda.official_name}
                  </h3>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${workAgenda.document.url}`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${workAgenda.document.url}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații în curs de actualizare.
                </p>
              </div>
            )}
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default WorkAgenda;
