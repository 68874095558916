import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { convocationAgendaService } from '../../../services/ConvocationAgendaService';
import ReactPaginate from 'react-paginate';

class AdminConvocationAgenda extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newConvocationAgenda: {
        title: '',
        document: '',
        add_date: ''
      },
      existingConvocationAgendas: [],
      deleteConvocationAgenda: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    convocationAgendaService
      .getAll()
      .then(jsonResponse => {
        this.setConvocationAgendaState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    convocationAgendaService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setConvocationAgendaState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setConvocationAgendaState = response => {
    let existingConvocationAgendas = {
      ...this.state.existingConvocationAgendas
    };
    let pagination = { ...this.state.pagination };
    existingConvocationAgendas = response.convocation_agendas;
    pagination = response.pagination;

    this.setState({ existingConvocationAgendas });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newConvocationAgenda = this.state.newConvocationAgenda;
    newConvocationAgenda[field] = event.target.value;

    this.setState({
      newConvocationAgenda
    });
  };

  onDropDocument = documents => {
    let newConvocationAgenda = { ...this.state.newConvocationAgenda };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newConvocationAgenda.document = documents[0];

      self.setState({ newConvocationAgenda });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newConvocationAgenda = { ...this.state.newConvocationAgenda };
    newConvocationAgenda.document = null;

    this.setState({ newConvocationAgenda });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newConvocationAgenda = { ...this.state.newConvocationAgenda };
    newConvocationAgenda = {
      title: '',
      document: '',
      add_date: ''
    };

    this.setState({ newConvocationAgenda });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newConvocationAgenda.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/convocation_agendas/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/convocation_agendas/${this.state.newConvocationAgenda.id}`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingConvocationAgendas = this.state.existingConvocationAgendas.slice();

        let index = existingConvocationAgendas.findIndex(
          existingConvocationAgenda =>
            existingConvocationAgenda.id === jsonResponse.id
        );

        if (index !== -1) {
          existingConvocationAgendas[index] = jsonResponse;
        } else {
          existingConvocationAgendas.push(jsonResponse);
        }

        this.setState({ existingConvocationAgendas });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Convocarea a fost editata cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Convocarea a fost adaugata cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'convocation_agenda[title]',
      this.state.newConvocationAgenda.title
    );

    formData.append(
      'convocation_agenda[add_date]',
      this.state.newConvocationAgenda.add_date
    );

    if (this.state.newConvocationAgenda.document) {
      let document = this.state.newConvocationAgenda.document;
      formData.append(`convocation_agenda[document]`, document, document.name);
    }

    return formData;
  }

  setConvocationAgendaForEdit = newConvocationAgendaDoc => {
    let newConvocationAgenda = { ...this.state.newConvocationAgenda };
    newConvocationAgenda = Object.assign({}, newConvocationAgendaDoc);
    this.setState({ newConvocationAgenda });

    if (newConvocationAgenda.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteConvocationAgenda = { ...this.state.deleteConvocationAgenda };
    deleteConvocationAgenda = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteConvocationAgenda });
  };

  closeDeleteDialog = () => {
    let deleteConvocationAgenda = { ...this.state.deleteConvocationAgenda };
    deleteConvocationAgenda = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteConvocationAgenda });
  };

  handleDelete = () => {
    convocationAgendaService
      .deleteConvocationAgenda(this.state.deleteConvocationAgenda.deleteId)
      .then(() => {
        let existingConvocationAgendas = this.state.existingConvocationAgendas.slice();
        existingConvocationAgendas = existingConvocationAgendas.filter(
          convocationAgenda =>
            convocationAgenda.id !== this.state.deleteConvocationAgenda.deleteId
        );
        this.setState({ existingConvocationAgendas });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Convocarea a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Administrație
          </h3>
          <h1 className="admin-switch-title default-title">
            Convocare si ordine de zi
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adaugă o convocare nouă</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu convocare*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newConvocationAgenda.title}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată convocare*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="add_date"
                    onChange={this.updateField}
                    value={this.state.newConvocationAgenda.add_date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document convocare sau ordine de zi:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newConvocationAgenda.document}
                    documentFilename={
                      this.state.newConvocationAgenda.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newConvocationAgenda.id
                        ? 'Editează Convocare'
                        : 'Adaugă Convocare'}
                    </button>
                  </div>
                  {Object.values(this.state.newConvocationAgenda) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingConvocationAgendas.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items-title label-title ">
                    Convocari existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingConvocationAgendas.map(
                      convocationAgenda => (
                        <div
                          className="existing-items__content-card"
                          key={convocationAgenda.id}
                        >
                          <div className="card-content provisions-title-content">
                            <h3 className="major-provisions-title">
                              {convocationAgenda.title}
                            </h3>
                          </div>
                          <div className="existing-items__actions-card">
                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.setConvocationAgendaForEdit(
                                  convocationAgenda
                                )
                              }
                            >
                              <h4 className="action-title"> Editează</h4>
                            </div>

                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.openDeleteDialog(convocationAgenda.id)
                              }
                            >
                              <h4 className="action-title delete-button">
                                {' '}
                                Șterge
                              </h4>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteConvocationAgenda.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acesta convocare?"
                    aditionalText="Dupa stergere, acesta convocare nu va mai putea fi recuperata."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminConvocationAgenda;
