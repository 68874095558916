import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import { associationRegisterService } from '../../../services/AssociationRegisterService';

class AdminAssociationRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newRegister: {
        title: '',
        document: '',
        date: ''
      },
      existingRegisters: [],
      deleteRegister: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }
  componentWillMount() {
    associationRegisterService
      .getAll()
      .then(response => {
        this.setRegisterState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setRegisterState = response => {
    let existingRegisters = this.state.existingRegisters;
    existingRegisters = response.associations_registers;
    this.setState({
      existingRegisters
    });
    let pagination = { ...this.state.pagination };
    pagination = response.pagination;
    this.setState({
      pagination
    });
  };
  handlePageClick = currentPage => {
    associationRegisterService
      .getAll(currentPage.selected + 1)
      .then(response => {
        this.setRegisterState(response);
      })
      .catch(error => console.log(error));
  };

  resetForm = () => {
    let newRegister = { ...this.state.newRegister };
    newRegister = {
      title: '',
      document: '',
      date: ''
    };
    this.setState({ newRegister });
    this.setState({ disabledDocument: false });
  };
  openDeleteDialog = id => {
    let deleteRegister = { ...this.state.deleteRegister };
    deleteRegister = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteRegister });
  };

  closeDeleteDialog = () => {
    let deleteRegister = { ...this.state.deleteRegister };
    deleteRegister = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteRegister });
  };
  handleFormSubmit = event => {
    event.preventDefault();

    associationRegisterService
      .createOrUpdate(this.state.newRegister)
      .then(response => {
        this.resetForm();

        let existingRegisters = this.state.existingRegisters;

        let index = existingRegisters.findIndex(
          existingRegisters => existingRegisters.id === response.id
        );

        if (index !== -1) {
          existingRegisters[index] = response;
        } else {
          existingRegisters.push(response);
        }

        this.setState({
          existingRegisters
        });

        this._notificationSystem.addNotification({
          message: `Registrul a fost ${
            this.state.newRegister.id ? 'editat' : 'adaugat'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  onDropDocument = documents => {
    let newRegister = { ...this.state.newRegister };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newRegister.document = documents[0];

      self.setState({ newRegister });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newRegister = { ...this.state.newRegister };
    newRegister.document = null;

    this.setState({ newRegister });
    this.setState({ disabledDocument: false });
  };

  setRegisterForEdit = register => {
    let newRegister = { ...this.state.newRegister };
    newRegister = Object.assign({}, register);
    this.setState({ newRegister });

    if (newRegister.document && newRegister.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  handleDelete = () => {
    associationRegisterService
      .deleteRegister(this.state.deleteRegister.deleteId)
      .then(() => {
        let existingRegisters = this.state.existingRegisters.slice();
        existingRegisters = existingRegisters.filter(
          register => register.id !== this.state.deleteRegister.deleteId
        );
        this.setState({ existingRegisters });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Registrul a fost sters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la stergere, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  updateField = event => {
    const field = event.target.name;
    const newRegister = this.state.newRegister;
    newRegister[field] = event.target.value;

    this.setState({
      newRegister
    });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Registre
          </h3>
          <h1 className="admin-switch-title default-title">
            Registrul asociațiilor, fundațiilor și federațiilor luate în
            evidență
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adaugă un registru nou</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu registru*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field title-field"
                      type="text"
                      name="title"
                      value={this.state.newRegister.title}
                      onChange={this.updateField}
                    />
                  </label>
                  <h4 className="label-title label-title-provisions">
                    Dată registru*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field date-field"
                      type="date"
                      name="date"
                      value={this.state.newRegister.date}
                      onChange={this.updateField}
                    />
                  </label>
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document registru:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newRegister.document}
                    documentFilename={this.state.newRegister.document_filename}
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newRegister.id
                        ? 'Editează Registru'
                        : 'Adaugă Registru'}
                    </button>
                  </div>
                  {Object.values(this.state.newRegister) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      size="small"
                      color="secondary"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingRegisters.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items-title label-title ">
                    Registre existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingRegisters.map(register => (
                      <div
                        className="existing-items__content-card"
                        key={register.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {register.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setRegisterForEdit(register)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(register.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteRegister.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest registru?"
                    aditionalText="Dupa stergere, registrul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminAssociationRegister;
