import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { humanResourcesService } from '../../services/HumanResourcesService';
import ReactPaginate from 'react-paginate';

class HumanResources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      humanResources: [],
      pagination: {}
    };
  }

  componentWillMount() {
    humanResourcesService
      .getAll()
      .then(jsonResponse => {
        this.setHumanResourcesState(jsonResponse);
      })
      .catch(error => console.log(error));
  }
  toggleAnnouncementBox = currentAnnouncement => {
    let humanResources = this.state.humanResources;

    humanResources.forEach(announcement => {
      if (announcement.id === currentAnnouncement.id) {
        announcement.openedBox = !announcement.openedBox;
      }
    });

    this.setState({
      humanResources
    });
  };
  setHumanResourcesState = response => {
    let humanResources = { ...this.state.humanResources };
    let pagination = { ...this.state.pagination };
    humanResources = response.human_resources;
    pagination = response.pagination;

    this.setState({ humanResources });
    this.setState({ pagination });
  };

  handlePageClick = selectedPage => {
    humanResourcesService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setHumanResourcesState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Resurse umane</title>
          <meta
            name="description"
            content="Resurse umane Vicovu de Jos. Aici sunt prezentate concursurile si rezultatele cu privire la posturile in primarie."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Resurse umane" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Resurse Umane</h4>
          </div>
          <h2 className="standard__main-title">Resurse Umane</h2>
        </div>

        <div className="human-resources">
          <h3 className="standard__submitted-title standard__civil-status-title standard__common-title">
            Concursuri
          </h3>
          {this.state.humanResources.length ? (
            <div>
              <div className="events__wrapper">
                {this.state.humanResources.map(humanResourcesAnnouncement => (
                  <div
                    className="standard__individual-card"
                    key={humanResourcesAnnouncement.id}
                  >
                    <h3 className="standard__card-title">
                      {humanResourcesAnnouncement.title}
                    </h3>
                    {humanResourcesAnnouncement.document_contest_filename ? (
                      <h4
                        className="standard__see-project"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}${humanResourcesAnnouncement.document_contest.url}`
                          )
                        }
                      >
                        Anunt Concurs
                      </h4>
                    ) : null}
                    {humanResourcesAnnouncement.document_selection_filename ? (
                      <h4
                        className="standard__see-project"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}${humanResourcesAnnouncement.document_selection.url}`
                          )
                        }
                      >
                        Selectie Dosare
                      </h4>
                    ) : null}
                    {humanResourcesAnnouncement.document_written_test_filename ? (
                      <h4
                        className="standard__see-project"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}${humanResourcesAnnouncement.document_written_test.url}`
                          )
                        }
                      >
                        Rezultat proba scrisa
                      </h4>
                    ) : null}
                    {humanResourcesAnnouncement.document_interview_filename ? (
                      <h4
                        className="standard__see-project"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}${humanResourcesAnnouncement.document_interview.url}`
                          )
                        }
                      >
                        Rezultat Interviu
                      </h4>
                    ) : null}
                    {humanResourcesAnnouncement.document_final_raport_filename ? (
                      <h4
                        className="standard__see-project"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}${humanResourcesAnnouncement.document_final_raport.url}`
                          )
                        }
                      >
                        Raport Final
                      </h4>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="events__no-events">
              <p className="events__description events__no-description">
                Nu sunt anunturi adaugate momentan.
              </p>
            </div>
          )}
        </div>
        {this.state.pagination.last_page > 1 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pagination.last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    );
  }
}

export default HumanResources;
