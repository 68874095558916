import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import ReactPaginate from 'react-paginate';
import { adoptedDecisionsService } from '../../services/AdoptedDecisionsService';
import '../StandardPage/StandardPage.css';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class AdoptedDecisions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adoptedDecisions: [],
      searchAdoptedDecision: {
        title: '',
        archiveYear: '2023'
      },
      archiveYears: archiveJson,
      pagination: {}
    };
  }

  componentWillMount() {
    adoptedDecisionsService
      .getAll()
      .then(jsonResponse => {
        this.setAdoptedDecisionState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  setAdoptedDecisionState = response => {
    let adoptedDecisions = { ...this.state.adoptedDecisions };
    let pagination = { ...this.state.pagination };
    adoptedDecisions = response.adopted_decisions;
    pagination = response.pagination;

    this.setState({ adoptedDecisions });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchAdoptedDecision = this.state.searchAdoptedDecision;
    searchAdoptedDecision[field] = event.target.value;

    this.setState(
      {
        searchAdoptedDecision
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    adoptedDecisionsService
      .search(this.state.searchAdoptedDecision, pageNumber)
      .then(jsonResponse => {
        this.setAdoptedDecisionState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Hotarari Adoptate</title>
          <meta
            name="description"
            content="Proiectele de hotarari ale primariei Vicovu de Jos. Aici sunt prezentate proiectele de hotarari realizate de primarie."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Proiecte de Hotarari" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Hotărâri Adoptate</h4>
          </div>
          <h2 className="standard__main-title">Hotărâri Adoptate</h2>
        </div>
        <div>
          <div className="standard__wrapper">
            <div className="standard__input-box standard__mp-input-box">
              <input
                className="standard__input standard__mp-input"
                label="Cautare Document"
                placeholder="Căutare Document"
                name="title"
                ovalue={this.state.searchAdoptedDecision.title}
                onChange={this.updateField}
              />
              <img
                src="/images/icons/search.svg"
                className="standard__search-icon mariage-publications__search-icon"
                alt="search-icon"
                onClick={() => this.search()}
              />
              <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                <FormControl>
                  <NativeSelect
                    value={this.state.searchAdoptedDecision.archiveYear}
                    onChange={this.updateField}
                    inputProps={{
                      name: 'archiveYear'
                    }}
                  >
                    {this.state.archiveYears.map(archive => (
                      <option value={archive.value}>{archive.name}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
            <div
              className={
                'standard__cards ' +
                (this.state.adoptedDecisions.length <= 2
                  ? 'standard__projects-wrapper-few'
                  : 'standard__projects-wrapper')
              }
            >
              {this.state.adoptedDecisions.length > 0 ? (
                this.state.adoptedDecisions.map(adoptedDecision => (
                  <div
                    className="standard__individual-card"
                    key={adoptedDecision.id}
                  >
                    <div className="standard__number-box">
                      <h4 className="standard__dispozition-number">
                        Nr. {adoptedDecision.adopted_number}
                      </h4>
                    </div>
                    <h3 className="standard__card-title">
                      {adoptedDecision.title}
                    </h3>
                    <h4 className="standard__card-date">
                      {adoptedDecision.add_date}
                    </h4>
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${adoptedDecision.document.url}`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                    <img
                      src="/images/icons/file.svg"
                      className="standard__card-img"
                      alt="file"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${adoptedDecision.document.url}`
                        )
                      }
                    />
                  </div>
                ))
              ) : (
                <div className="standard__no-projects-box">
                  <p className="standard__no-projects">
                    Nu sunt rezultate pentru căutare.
                  </p>
                </div>
              )}
            </div>
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AdoptedDecisions;
