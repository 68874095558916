// @flow

import React, { Component } from 'react';
import './HamburgerLink.css';
import { Link } from 'react-router-dom';

class HamburgerLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSubMenu: false
    };
  }

  openSubmenu() {
    this.setState({ activeSubMenu: true });
  }

  closeSubmenu() {
    this.setState({ activeSubMenu: false });
  }

  render() {
    return (
      <li className="hamburger__link">
        {this.props.subMenu ? (
          <div>
            <a onClick={() => this.openSubmenu()}>
              <span className="link-title"> {this.props.title} </span>
              <span>
                <img
                  className="hamburger__right-icon"
                  src="/images/icons/right-icon.png"
                  alt="Right Icon"
                />
              </span>
            </a>
            <ul
              className={
                'hamburger__links hamburger__links--submenu' +
                (this.state.activeSubMenu ? ' active' : '')
              }
            >
              <li
                onClick={() => this.closeSubmenu()}
                className="hamburger__link hamburger__link--menu"
              >
                <span>
                  <img
                    className="hamburger__left-icon"
                    src="/images/icons/left-icon.png"
                    alt="leftIcon"
                  />
                </span>
                <span>Inapoi la {this.props.title}</span>
              </li>
              {this.props.subMenu.map(listItem => (
                <HamburgerLink key={listItem.id} {...listItem} />
              ))}
            </ul>
          </div>
        ) : (
          <Link to={this.props.url} onClick={() => this.props.closeMenu()}>
            <span className="link-title"> {this.props.title} </span>
          </Link>
        )}
      </li>
    );
  }
}

export default HamburgerLink;
