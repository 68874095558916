// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class Education extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Infrastructura de Invatament
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text text--final">NR CRT.</div>
                  <div className="text text--final">Unitate de Invatamant</div>
                  <div className="text text--final">Adresa</div>
                  <div className="text text--final">
                    Personalitate jiridica/ Arondata
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">
                    <h4 className="standard__generic">INVATAMANT PRESCOLAR</h4>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">1.</div>
                  <div className="text">
                    Gradinita cu program normal Nr.1 Vicovu de Jos
                  </div>
                  <div className="text">
                    Vicovu de Jos , str. Calea Cernauti , nr. 60
                  </div>
                  <div className="text">Arondata</div>
                </div>
                <div className="table__row">
                  <div className="text">2.</div>
                  <div className="text">
                    Gradinita cu program normal Nr.2 Vicovu de Jos
                  </div>
                  <div className="text">Vicovu de Jos</div>
                  <div className="text">Arondata</div>
                </div>
                <div className="table__row">
                  <div className="text">3.</div>
                  <div className="text">
                    Gradinita cu program normal Nr.3 Vicovu de Jos
                  </div>
                  <div className="text">
                    Vicovu de Jos , str. Calea Cernauti , nr. 564
                  </div>
                  <div className="text">Arondata</div>
                </div>
                <div className="table__row">
                  <div className="text">4.</div>
                  <div className="text">
                    Gradinita cu program normal Nr.4 Vicovu de Jos
                  </div>
                  <div className="text">Vicovu de Jos</div>
                  <div className="text">Arondata</div>
                </div>
                <div className="table__row">
                  <div className="text">5.</div>
                  <div className="text">
                    Gradinita cu program normal Bivolarie
                  </div>
                  <div className="text">
                    Vicovu de Jos , str. Stefan cel Mare
                  </div>
                  <div className="text">Arondata</div>
                </div>
                <div className="table__row">
                  <div className="text">6.</div>
                  <div className="text">
                    Gradinita cu program normal Nr. LUMEA COPIILOR Vicovu de Jos
                  </div>
                  <div className="text">
                    Vicovu de Jos , str. Calea Cernauti , nr. 342
                  </div>
                  <div className="text">Arondata</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">
                    <h4 className="standard__generic">
                      INVATAMANT PRIMAR SI GIMNAZIAL
                    </h4>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">1.</div>
                  <div className="text">
                    Scoala Gimnaziala Nr.1 Vicovu de Jos
                  </div>
                  <div className="text">
                    Vicovu de Jos , str. Calea Cernauti , nr. 342
                  </div>
                  <div className="text">Personalitae juridica</div>
                </div>
                <div className="table__row">
                  <div className="text">2.</div>
                  <div className="text">
                    Scoala Gimnaziala Nr.2 Vicovu de Jos
                  </div>
                  <div className="text">Vicovu de Jos</div>
                  <div className="text">Personalitae juridica</div>
                </div>
                <div className="table__row">
                  <div className="text">3.</div>
                  <div className="text">
                    Scoala Gimnaziala Nr.3 Vicovu de Jos
                  </div>
                  <div className="text">
                    Vicovu de Jos , str. Calea Cernauti , nr. 564
                  </div>
                  <div className="text">Personalitae juridica</div>
                </div>
                <div className="table__row">
                  <div className="text">4.</div>
                  <div className="text">Scoala Gimnaziala Bivolarie</div>
                  <div className="text">
                    Vicovu de Jos , str. Stefan cel Mare , nr. 184
                  </div>
                  <div className="text">Personalitae juridica</div>
                </div>
                <div className="table__row">
                  <div className="text">5.</div>
                  <div className="text">Liceul Tehnologic “ION NISTOR”</div>
                  <div className="text">
                    Vicovu de Jos , str. Calea Cernauti , nr. 342
                  </div>
                  <div className="text">Personalitae juridica</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">
                    <h4 className="standard__generic">INVATAMANT LICEAL</h4>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">1.</div>
                  <div className="text">Liceul Tehnologic “ION NISTOR”</div>
                  <div className="text">
                    Vicovu de Jos , str. Calea Cernauti , nr. 342
                  </div>
                  <div className="text">Personalitae juridica</div>
                </div>
              </div>
            </div>
            <div className="about-vicov__right-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Burse acordate elevilor
              </h3>
              <h4
                className="standard__generic dd-description"
                id="gp-description"
              >
                Elevii de la cursurile de zi din învăţământul de stat pot
                beneficia de burse de merit, de studiu şi de ajutor social.
                Acordarea de burse de la bugetul de stat reprezintă o formă de
                sprijin material vizând atât protecţia socială cât şi stimularea
                elevilor care obţin rezultate foarte bune la învăţătură şi
                disciplină.
              </h4>
              <h4
                className="standard__generic dd-description"
                id="gp-description"
              >
                Bursele pe care le pot primi elevii pot fi de tipul:
              </h4>
              <h5>- Burse de ajutor social</h5>
              <h5>- Burse de merit</h5>
              <h5>- Burse de studiu</h5>
              <h5>- Burse de performanta</h5>

              <h3 className="standard__submitted-title" id="gp-subtitle">
                CULTURA, CULTE, AGREMENT
              </h3>
              <h5>- Numar biblioteci: 2</h5>
              <h5>- Numar case de cultura: 0</h5>
              <h5>- Camine culturale si muzee: 3</h5>
              <h5>- Monumente istorice: 2</h5>
              <h5>- Numar ONG: 0</h5>
              <h5>- Publicatii si reviste: 0</h5>
              <h5>- Formatii de dans (popular, modern): 3</h5>
              <h5>
                - Sarbatori si traditii cu specific local: Zilele Orasului,
                Concert de colinde
              </h5>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Lacasuri de cult pe confesiuni:
              </h4>
              <h5>- Biserici ortodoxe: 6</h5>
              <h5>- Biserici Penticostale: 5</h5>
              <h5>- Biserici Baptiste: 2</h5>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Locuri de agrement
              </h4>
              <h5>- Sali de sport: 1</h5>
              <h5>- Centre SPA</h5>
              <h5>- Terenuri de joaca pentru copii: 2</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Education;
