import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import { Link } from 'react-router-dom';
import './ProposalsForm.css';

class ProposalsForm extends Component {
  render() {
    return (
      <div className="legislation standard-page proposal-form">
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Transparență decizională</h4>
          </div>
          <h2 className="standard__main-title">
            {' '}
            Formular pentru colectarea de propuneri/opinii/recomandări{' '}
          </h2>
        </div>
        <div className="proposal-form__content box">
          <div className="proposal-form__box">
            <img src="/images/icons/pdf.png" alt="file" />
            <h4 className="proposal-form__name">Formularul in format PDF:</h4>
            <Link
              to="/legislation/Formular-on-line-pentru-colectarea-de-propuneri-opinii-recomandari.pdf"
              className="legislation__link"
              target="_blank"
            >
              <h4 className="proposal-form__link">
                Formular pentru colectarea de propuneri, opinii, recomandari
              </h4>
            </Link>
          </div>
          <div className="proposal-form__box">
            <img src="/images/icons/editable.png" alt="file" />
            <h4 className="proposal-form__name">
              Formularul in format editabil (Word):
            </h4>
            <Link
              to="/legislation/Formular-on-line-pentru-colectarea-de-propuneri-opinii-recomandari.docx"
              className="legislation__link"
              target="_blank"
            >
              <h4 className="proposal-form__link">
                Formular pentru colectarea de propuneri, opinii, recomandari
              </h4>
            </Link>
          </div>
          <div className="proposal-form__box">
            <img src="/images/icons/pdf.png" alt="file" />
            <h4 className="proposal-form__name">Formularul in format PDF:</h4>
            <Link
              to="/legislation/Formular-on-line-de-inscriere-in-baza-de-date-a-persoanelor-interesate-de-consultare-conf.-art.7-al.1-din-Legea-52-din-2003.pdf"
              className="legislation__link"
              target="_blank"
            >
              <h4 className="proposal-form__link">
                Formular de inscriere in baza de date a persoanelor interesate
                de consultare conf. art.7 al.1 din Legea 52 din 2003
              </h4>
            </Link>
          </div>
          <div className="proposal-form__box">
            <img src="/images/icons/editable.png" alt="file" />
            <h4 className="proposal-form__name">
              Formularul in format editabil (Word):
            </h4>
            <Link
              to="/legislation/Formular-on-line-de-inscriere-in-baza-de-date-a-persoanelor-interesate-de-consultare-conf.-art.7-al.1-din-Legea-52-din-2003.docx"
              className="legislation__link"
              target="_blank"
            >
              <h4 className="proposal-form__link">
                Formular de inscriere in baza de date a persoanelor interesate
                de consultare conf. art.7 al.1 din Legea 52 din 2003
              </h4>
            </Link>
          </div>
          <h4 className="proposal-form__name">
            Timpul mediu de completare a formularului este de aproximativ 10
            minute.
          </h4>
        </div>
      </div>
    );
  }
}

export default ProposalsForm;
