import React from 'react';
import '../../../resources/AdminStandardPage.css';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import { workAgendaService } from '../../../services/WorkAgendaService';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';

class WorkAgenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledDocuments: false,
      newWorkAgenda: {
        officialName: '',
        document: '',
        add_date:''
      },
      existingWorkAgenda: [],
      deleteWorkAgenda: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };
  }
  componentWillMount() {
    workAgendaService
      .getAll()
      .then(response => {
        this.setWorkAgendaState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = currentPage => {
    workAgendaService
      .getAll(currentPage.selected + 1)
      .then(response => {
        this.setWorkAgendaState(response);
      })
      .catch(error => {});
  };

  setWorkAgendaState = response => {
    let existingWorkAgenda = this.state.existingWorkAgenda;
    existingWorkAgenda = response.work_agendas;
    this.setState({
      existingWorkAgenda
    });
    let pagination = this.state.pagination;
    pagination = response.pagination;
    this.setState({
      pagination
    });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    workAgendaService
      .createOrUpdate(this.state.newWorkAgenda)
      .then(response => {
        this.resetForm();

        let existingWorkAgenda = this.state.existingWorkAgenda;

        let index = existingWorkAgenda.findIndex(
          existingWA => existingWA.id === response.id
        );

        if (index !== -1) {
          existingWorkAgenda[index] = response;
        } else {
          existingWorkAgenda.push(response);
        }

        this.setState({
          existingWorkAgenda
        });

        this._notificationSystem.addNotification({
          message: `Agenda de lucru a fost ${
            this.state.newWorkAgenda.id ? 'editata' : 'adaugata'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  resetForm = () => {
    let newWorkAgenda = { ...this.state.newWorkAgenda };
    newWorkAgenda = {
      officialName: '',
      document: ''
    };
    this.setState({ newWorkAgenda });
    this.setState({ disabledDocument: false });
  };

  updateField = event => {
    const field = event.target.name;
    const newWorkAgenda = this.state.newWorkAgenda;
    newWorkAgenda[field] = event.target.value;

    this.setState({
      newWorkAgenda
    });
  };

  onDropDocument = documents => {
    let newWorkAgenda = { ...this.state.newWorkAgenda };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newWorkAgenda.document = documents[0];

      self.setState({ newWorkAgenda });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newWorkAgenda = { ...this.state.newWorkAgenda };
    newWorkAgenda.document = null;

    this.setState({ newWorkAgenda });
    this.setState({ disabledDocument: false });
  };

  setWorkAgendaForEdit = workAgenda => {
    let newWorkAgenda = { ...this.state.newWorkAgenda };
    newWorkAgenda = Object.assign({}, workAgenda);
    newWorkAgenda.officialName = newWorkAgenda.official_name;
    this.setState({ newWorkAgenda });

    if (newWorkAgenda.document && newWorkAgenda.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteWorkAgenda = { ...this.state.deleteWorkAgenda };
    deleteWorkAgenda = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteWorkAgenda });
  };

  closeDeleteDialog = () => {
    let deleteWorkAgenda = { ...this.state.deleteWorkAgenda };
    deleteWorkAgenda = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteWorkAgenda });
  };

  handleDelete = () => {
    workAgendaService
      .deleteWorkAgenda(this.state.deleteWorkAgenda.deleteId)
      .then(() => {
        let existingWorkAgenda = this.state.existingWorkAgenda.slice();
        existingWorkAgenda = existingWorkAgenda.filter(
          workAgenda => workAgenda.id !== this.state.deleteWorkAgenda.deleteId
        );
        this.setState({ existingWorkAgenda });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Agenda de lucru a fost stearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Administratie
          </h3>
          <h1 className="admin-switch-title default-title">Agenda de lucru</h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__create-project">
          <div className="card">
            <form className="admin-form" onSubmit={this.handleFormSubmit}>
              <h2 className="admin-form__title">
                Adaugă o agenda de lucru noua
              </h2>

              <div className="admin-form__field">
                <h4 className="label-title label-title-provisions">
                  Nume functionar*
                </h4>
                <input
                  className="general-field title-field"
                  type="text"
                  name="officialName"
                  onChange={this.updateField}
                  value={this.state.newWorkAgenda.officialName}
                />
              </div>
              <div className="admin-form__field">
                <h4 className="label-title label-title-provisions">
                  Data document*
                </h4>
                <input
                  className="general-field title-field"
                  type="date"
                  name="add_date"
                  onChange={this.updateField}
                  value={this.state.newWorkAgenda.add_date}
                />
              </div>
              <div className="admin-form__upload">
                <h4 className="label-title label-title-provisions">
                  Document agenda de lucru:
                </h4>
                <AttachmentField
                  onDrop={this.onDropDocument}
                  removeDocument={this.removeDocument}
                  disabledDocuments={this.state.disabledDocument}
                  document={this.state.newWorkAgenda.document}
                  documentFilename={this.state.newWorkAgenda.document_filename}
                  type="application/pdf"
                />
              </div>
              <div className="form-buttons">
                <div className="admin-form__submit">
                  <button
                    type="submit"
                    className="submit-button general-button"
                  >
                    {this.state.newWorkAgenda.id
                      ? 'Editează Agenda de lucru'
                      : 'Adauga Agenda de lucru'}
                  </button>
                </div>
                {Object.values(this.state.newWorkAgenda) && (
                  <button
                    type="button"
                    className="general-button reset-button"
                    onClick={() => this.resetForm()}
                  >
                    Resetează câmpurile
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        {this.state.existingWorkAgenda &&
          this.state.existingWorkAgenda.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Agende de lucru existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingWorkAgenda.map(agenda => (
                      <div
                        className="existing-items__content-card"
                        key={agenda.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {agenda.official_name}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setWorkAgendaForEdit(agenda)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(agenda.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteWorkAgenda.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti aceasta agenda de lucru?"
                    aditionalText="Dupa stergere, aceasta agenda de lucru nu va mai putea fi recuperata."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default WorkAgenda;
