import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import DownloadCard from '../../components/DownloadCard/DownloadCard';
import MetaTags from 'react-meta-tags';
import { socialAidService } from '../../services/SocialAidService';
import ReactPaginate from 'react-paginate';
import './SocialAid.css';

class SocialAid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socialAids: [],
      pagination: {}
    };
  }

  componentWillMount() {
    socialAidService
      .getAll()
      .then(jsonResponse => {
        this.setSocialAidState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    socialAidService
      .getAll(selectedPage.selectedPage + 1)
      .then(jsonResponse => {
        this.setSocialAidState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setSocialAidState = response => {
    let socialAids = { ...this.state.socialAids };
    let pagination = { ...this.state.pagination };
    socialAids = response.social_aids;
    pagination = response.pagination;

    console.log('asd');

    this.setState({ socialAids });
    this.setState({ pagination });

    console.log(this.state.socialAids);
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Ajutor Social</title>
          <meta
            name="description"
            content="Ajutor Social Vicovu de Jos. Aici sunt prezentate documentele necesare pentru ajutorul social."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Ajutor Social" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Asistență socială </h4>
          </div>
          <h2 className="standard__main-title">Ajutor Social</h2>
        </div>

        <div className="standard__wrapper heating-aids sa-wrapper">
          <div className="heating-aids__left-side sa-left-side">
            <h3 className="standard__submitted-title sa-documents-title standard__civil-status-title">
              Acte necesare
            </h3>
            <ol className="standard__document-list sa-list">
              <li className="standard__list-item">Cerere tip</li>
              <li className="standard__list-item">Copie B.I/C.I./C.P.</li>
              <li className="standard__list-item">
                Certificat naștere în copie(copii cu vârste până la 14 ani)
              </li>
              <li className="standard__list-item">
                Copie certificat căsătorie
              </li>
              <li className="standard__list-item">
                Copie hotărâri judecatorești
              </li>
              <li className="standard__list-item">
                Adeverință de salariat cu venitul net din ultima lună
              </li>
              <li className="standard__list-item">
                Cupon de pensie din ultima lună
              </li>
              <li className="standard__list-item">
                Adeverința de venitul agricol
              </li>
              <li className="standard__list-item">
                Adeverință de venit de la administrația finanțelor publice
              </li>
              <li className="standard__list-item">
                Adeverința pentru elevi de la unitățile școlare
              </li>
              <li className="standard__list-item">
                Adeverința oficiul forțelor de muncă
              </li>
              <li className="standard__list-item">
                Copie dispoziție de curator
              </li>
              <li className="standard__list-item">
                Copie decizie de încadrare într-un grad de handicap
              </li>
            </ol>

            <h3 className="standard__submitted-title sa-documents-title standard__civil-status-title">
              Formulare tip necesare
            </h3>
            <div className="standard__cards sa-cards" id="cards-wrapper">
              <DownloadCard
                requiredWidth="sa-required-width"
                title="Angajament de plata"
                url="/documents/ANGAJAMENT_DE_PLATA.pdf"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="Cerere solicitare ajutor social si alocatie de sustinere a familiei"
                url="/documents/cerereSolicitareAjutorSocial.pdf"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="Cuantum venit minim garantat"
                url="/documents/cuantum_venit_minim_garantat.pdf"
              />
            </div>
          </div>
          <div className="heating-aids__right-side sa-right-side">
            <h3 className=" standard__submitted-title heating-aids__main-title sa-families-title">
              Familiile și persoanele beneficiare de ajutor social
            </h3>
            <div className="standard__cards sa-cards">
              {this.state.socialAids.length > 0 ? (
                this.state.socialAids.map(socialAid => (
                  <div key={socialAid.id} className="standard__individual-card">
                    <h3 className="standard__card-title sa-title">
                      {socialAid.title}
                    </h3>
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${socialAid.document.url}`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                  </div>
                ))
              ) : (
                <div className="standard__no-projects-box">
                  <p className="standard__no-projects">
                    Nu sunt rezultate pentru căutare.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.pagination.last_page > 1 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pagination.last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    );
  }
}

export default SocialAid;
