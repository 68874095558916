// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class Services extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Servicii
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Rețeaua de iluminat public
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Sistemul de iluminat public reprezintă ansamblul format din
                puncte de aprindere, cutii de distribuţie, cutii de trecere,
                linii electrice de joasă tenșiune subterane sau aeriene,
                fundaţii, stâlpi, instalaţii de legare la pământ, console,
                corpuri de iluminat, accesorii, conductoare, izolatoare, cleme,
                armături, echipamente de comandă, automatizare și măsurare
                utilizate pentru iluminatul public.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Reţeaua de iluminat public din orașul Vicovu de Jos aparţine
                S.C. EON . și are o lungime de 90.94 km.
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text text--final">Denumire</div>
                  <div className="text text--final">An 2010</div>
                  <div className="text text--final">An 2011</div>
                  <div className="text text--final">An 2012</div>
                  <div className="text text--final">An 2013</div>
                  <div className="text text--final">An 2014</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Stâlpi de iluminat aparţinând companiei
                  </div>
                  <div className="text">1548</div>
                  <div className="text">1548</div>
                  <div className="text">1548</div>
                  <div className="text">1548</div>
                  <div className="text">1548</div>
                </div>
                <div className="table__row">
                  <div className="text">Corpuri de iluminat</div>
                  <div className="text">1548</div>
                  <div className="text">1548</div>
                  <div className="text">1548</div>
                  <div className="text">1548</div>
                  <div className="text">1548</div>
                </div>
              </div>
              <h4 className="standard__generic aa-info" id="gp-description">
                Din cele 1548 corpuri de iluminat, cele pe LED sunt - bucăți, pe
                sodiu 445 bucăți, fluorescente - - bucăți, economice 1103
                bucăți.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                In ceea ce privește consumul de energie, după estimativul pe
                2014 pentru 10 instituții importante și în același timp mari
                consumatoare de energie, se observă o tendință de creștere a
                consumului. Acest lucru presupune luarea unor măsuri de
                optimizare a consumurilor energetice printr-o exploatare
                raţională a instalaţiilor şi eliminarea factorilor de consum
                excesiv din aceste unități.
              </h4>
            </div>
            <div className="about-vicov__right-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Poșta si telecomunicații
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Pe teritoriul orașului Vicovu de Jos funcționează 1 oficiu
                poștal. Orașul este conectat la serviciile de telefonie fixă
                ROMTELECOM, serviciile de telefonie mobilă cu acoperire
                corespunzătoare pentru toate zonele din localitate - VODAFONE,
                ORANGE, COSMOTE, servicii de televiziune prin satelit, DOLCE ,
                FOCUS SAT și BOOM TV, televiziune prin cablu S.C. Stilus S.R.L.,
                S.C. S.R.L
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Rețeaua de internet este asigurată de ROMTELECOM, , SC DIGITAL
                IDEEA SRL și Primaria Orasului Vicovu de Jos .
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
