import AuthenticationService from './AuthenticationService';

export const responseHandleService = {
  handleResponse
};

function handleResponse(response) {
  const authenticationService = new AuthenticationService();

  if (response.status === 401) {
    authenticationService.logout();
  }

  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authenticationService.logout();
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
