import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import { publicDebatesService } from '../../../services/PublicDebatesService';

class AdminDebates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledAnnouncement: false,
      disabledProcedure: false,
      disabledMinute: false,
      disabledCentralizator: false,
      newDebate: {
        title: '',
        document_announcement: '',
        document_procedure: '',
        responsable: '',
        date: '',
        document_minute: '',
        document_centralizator: ''
      },
      existingDebates: [],
      deleteDebate: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }
  componentWillMount() {
    publicDebatesService
      .getAll()
      .then(response => {
        this.setDebateState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setDebateState = response => {
    let existingDebates = this.state.existingDebates;
    existingDebates = response.public_debates;
    this.setState({
      existingDebates
    });
    let pagination = { ...this.state.pagination };
    pagination = response.pagination;
    this.setState({
      pagination
    });
  };
  handlePageClick = currentPage => {
    publicDebatesService
      .getAll(currentPage.selected + 1)
      .then(response => {
        this.setDebateState(response);
      })
      .catch(error => console.log(error));
  };
  updateField = event => {
    const field = event.target.name;
    let newDebate = { ...this.state.newDebate };
    newDebate[field] = event.target.value;
    this.setState({
      newDebate
    });
  };
  resetForm = () => {
    let newDebate = { ...this.state.newDebate };
    (newDebate = {
      title: '',
      document_announcement: '',
      document_procedure: '',
      responsable: '',
      date: '',
      document_minute: '',
      document_centralizator: ''
    }),
      this.setState({ newDebate });
    this.setState({ disabledAnnouncement: false });
    this.setState({ disabledProcedure: false });
    this.setState({ disabledCentralizator: false });
    this.setState({ disabledMinute: false });
  };
  openDeleteDialog = id => {
    let deleteDebate = { ...this.state.deleteDebate };
    deleteDebate = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteDebate });
  };

  closeDeleteDialog = () => {
    let deleteDebate = { ...this.state.deleteDebate };
    deleteDebate = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteDebate });
  };
  handleFormSubmit = event => {
    event.preventDefault();

    publicDebatesService
      .createOrUpdate(this.state.newDebate)
      .then(response => {
        this.resetForm();

        let existingDebates = this.state.existingDebates;

        let index = existingDebates.findIndex(
          existingDebates => existingDebates.id === response.id
        );

        if (index !== -1) {
          existingDebates[index] = response;
        } else {
          existingDebates.push(response);
        }

        this.setState({
          existingDebates
        });

        this._notificationSystem.addNotification({
          message: `Dezbaterea a fost ${
            this.state.newDebate.id ? 'editata' : 'adaugata'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  onDropDocumentAnnouncement = documents => {
    let newDebate = { ...this.state.newDebate };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newDebate.document_announcement = documents[0];

      self.setState({ newDebate });
      self.setState({ disabledAnnouncement: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentAnnouncement = () => {
    let newDebate = { ...this.state.newDebate };
    newDebate.document_announcement = null;

    this.setState({ newDebate });
    this.setState({ disabledAnnouncement: false });
  };
  onDropDocumentProcedure = documents => {
    let newDebate = { ...this.state.newDebate };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newDebate.document_procedure = documents[0];

      self.setState({ newDebate });
      self.setState({ disabledProcedure: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentProcedure = () => {
    let newDebate = { ...this.state.newDebate };
    newDebate.document_procedure = null;

    this.setState({ newDebate });
    this.setState({ disabledProcedure: false });
  };
  onDropDocumentMinute = documents => {
    let newDebate = { ...this.state.newDebate };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newDebate.document_minute = documents[0];

      self.setState({ newDebate });
      self.setState({ disabledMinute: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentMinute = () => {
    let newDebate = { ...this.state.newDebate };
    newDebate.document_minute = null;

    this.setState({ newDebate });
    this.setState({ disabledMinute: false });
  };
  onDropDocumentCentralizator = documents => {
    let newDebate = { ...this.state.newDebate };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newDebate.document_centralizator = documents[0];

      self.setState({ newDebate });
      self.setState({ disabledCentralizator: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentCentralizator = () => {
    let newDebate = { ...this.state.newDebate };
    newDebate.document_centralizator = null;

    this.setState({ newDebate });
    this.setState({ disabledCentralizator: false });
  };

  setDebateForEdit = debate => {
    let newDebate = { ...this.state.newDebate };
    newDebate = Object.assign({}, debate);
    this.setState({ newDebate });

    if (
      newDebate.document_announcement &&
      newDebate.document_announcement.url
    ) {
      this.setState({ disabledAnnouncement: true });
    }

    if (newDebate.document_procedure && newDebate.document_procedure.url) {
      this.setState({ disabledProcedure: true });
    }

    if (newDebate.document_minute && newDebate.document_minute.url) {
      this.setState({ disabledMinute: true });
    }

    if (
      newDebate.document_centralizator &&
      newDebate.document_centralizator.url
    ) {
      this.setState({ disabledCentralizator: true });
    }
  };

  handleDelete = () => {
    publicDebatesService
      .deleteDebate(this.state.deleteDebate.deleteId)
      .then(() => {
        let existingDebates = this.state.existingDebates.slice();
        existingDebates = existingDebates.filter(
          debate => debate.id !== this.state.deleteDebate.deleteId
        );
        this.setState({ existingDebates });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Dezbaterea a fost stearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la stergere, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Transparenta decizionala
          </h3>
          <h1 className="admin-switch-title default-title">
            Dezbateri publice
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adaugă o dezbatere publica:
                </h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu dezbatere*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field title-field"
                      type="text"
                      name="title"
                      value={this.state.newDebate.title}
                      onChange={this.updateField}
                    />
                  </label>
                  <h4 className="label-title label-title-provisions">
                    Dată dezbatere*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field date-field"
                      type="date"
                      name="date"
                      value={this.state.newDebate.date}
                      onChange={this.updateField}
                    />
                  </label>
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Anunt dezbatere:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentAnnouncement}
                    removeDocument={this.removeDocumentAnnouncement}
                    disabledDocuments={this.state.disabledAnnouncement}
                    document={this.state.newDebate.document_announcement}
                    documentFilename={
                      this.state.newDebate.document_announcement_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Procedura inscriere dezbatere:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentProcedure}
                    removeDocument={this.removeDocumentProcedure}
                    disabledDocuments={this.state.disabledProcedure}
                    document={this.state.newDebate.document_procedure}
                    documentFilename={
                      this.state.newDebate.document_procedure_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <h4 className="label-title label-title-provisions">
                  Persoana responsabila*
                </h4>
                <label>
                  <input
                    required
                    className="general-field date-field"
                    type="text"
                    name="responsable"
                    value={this.state.newDebate.responsable}
                    onChange={this.updateField}
                  />
                </label>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Minuta dezbatere:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentMinute}
                    removeDocument={this.removeDocumentMinute}
                    disabledDocuments={this.state.disabledMinute}
                    document={this.state.newDebate.document_minute}
                    documentFilename={
                      this.state.newDebate.document_minute_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Centralizator dezbatere:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentCentralizator}
                    removeDocument={this.removeDocumentCentralizator}
                    disabledDocuments={this.state.disabledCentralizator}
                    document={this.state.newDebate.document_centralizator}
                    documentFilename={
                      this.state.newDebate.document_centralizator_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newDebate.id
                        ? 'Editează Dezbatere'
                        : 'Adaugă Dezbatere'}
                    </button>
                  </div>
                  {Object.values(this.state.newDebate) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      size="small"
                      color="secondary"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingDebates.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items-title label-title ">
                    Rapoarte existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingDebates.map(debate => (
                      <div
                        className="existing-items__content-card"
                        key={debate.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {debate.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setDebateForEdit(debate)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(debate.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteDebate.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti aceasta dezbatere?"
                    aditionalText="Dupa stergere, dezbaterea nu va mai putea fi recuperata."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminDebates;
