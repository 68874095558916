import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { acquisitionsCentralizatorService } from '../../services/AcquisitionsCentralizatorService';
import FormControl from '@material-ui/core/FormControl';
import ReactPaginate from 'react-paginate';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class AcquisitionsCentralizator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      pagination: {},
      archiveYears: archiveJson,
      searchCentralizator: {
        archiveYear: 2023,
        title: ''
      }
    };
  }
  componentWillMount() {
    acquisitionsCentralizatorService
      .getAll()
      .then(jsonResponse => {
        console.log(jsonResponse);
        this.setStructureRegulamentState(jsonResponse);
      })
      .catch(error => console.log(error));
  }
  setStructureRegulamentState = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.public_aquisitions_centralizators;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });

    console.log(this.state.documents);
  };
  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };
  updateField = event => {
    const field = event.target.name;
    const searchCentralizator = this.state.searchCentralizator;
    searchCentralizator[field] = event.target.value;

    this.setState(
      {
        searchCentralizator
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    acquisitionsCentralizatorService
      .search(this.state.searchCentralizator, pageNumber)
      .then(jsonResponse => {
        this.setCentralizatorState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setCentralizatorState = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.public_aquisitions_centralizators;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Centralizatorul achizitiilor publice</title>
          <meta
            name="description"
            content="Centralizatorul achizitiilor publice."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta
            property="og:title"
            content="Centralizatorul achizitiilor publice"
          />
          <meta
            property="og:url"
            content="https://www.primaria-vicovudejos.ro/centralizator-achizitii"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Achizitii</h4>
          </div>
          <h2 className="standard__main-title">
            Centralizatorul Achizițiilor Publice
          </h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchCentralizator.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchCentralizator.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div className="standard__cards standard__projects-wrapper-few">
            {this.state.documents && this.state.documents.length > 0 ? (
              this.state.documents.map(document => (
                <div
                  key={document.id}
                  className="standard__individual-card sa-title"
                >
                  <h3 className="standard__card-title">{document.title}</h3>
                  <h5 className="standard__card-date">{document.add_date}</h5>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${document.document.url}`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${document.document.url}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AcquisitionsCentralizator;
