import axios from 'axios';

export const googleVerificationService = {
  verifyToken
};

function verifyToken(token, v2key = false) {
  const requestOptionsPost = {
    headers: {
      Accept: 'application/json'
    }
  };

  const data = {
    verify_token: {
      token: token,
      v2key
    }
  };

  let url = `${process.env.REACT_APP_API_URL}/api/google_verification/verify_token`;

  return axios.post(url, data, requestOptionsPost);
}
