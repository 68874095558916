import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import './DeleteModal.css';
import '../../resources/StandardPage.css';
import Modal from 'react-modal';
import { googleVerificationService } from '../../services/GoogleVerificationService';
import ReCAPTCHA from 'react-google-recaptcha';
import { dataDeletionService } from '../../services/DataDeletionService';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(13, 8, 32, 0.9)',
    overflow: 'scroll'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto'
  }
};

class DeleteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      googleRecapchaValue: '',
      disableSubmit: false
    };

    this.recaptchaRefDeleteModal = React.createRef();
  }

  updateField = event => {
    let email = this.state.email;
    email = event.target.value;
    this.setState({
      email
    });
  };

  onChange = value => {
    this.setState({ googleRecapchaValue: value });
  };

  resetState = () => {
    this.setState({ email: '' });
    this.recaptchaRefDeleteModal.current.reset();
  };

  deleteData = event => {
    event.preventDefault();
    this.setState({ disableSubmit: true });

    googleVerificationService
      .verifyToken(this.state.googleRecapchaValue, true)
      .then(response => {
        if (response.data.success) {
          dataDeletionService
            .deletePersonalData(this.state.email)
            .then(response => {
              this.props.notificationSystem.addNotification({
                message: response.data.message,
                level: 'success',
                position: 'tc'
              });
              this.setState({ disableSubmit: false });
              this.resetState();
            })
            .catch(error => {
              if (error.response.status === 404) {
                this.props.notificationSystem.addNotification({
                  message: error.response.data.message,
                  level: 'error',
                  position: 'tc'
                });
              } else {
                this.props.notificationSystem.addNotification({
                  message:
                    'A aparut o problema la adaugare, faceti refresh la pagina si incercati din nou sau contactati direct primaria daca problema persista.',
                  level: 'error',
                  position: 'tc'
                });
              }

              this.setState({ disableSubmit: false });
              this.resetState();
            });
        } else {
          this.props.notificationSystem.addNotification({
            message: 'Va rugam completati ReCAPTCHA.',
            level: 'error',
            position: 'tc'
          });
          this.setState({ disableSubmit: false });
        }
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        ariaHideApp={false}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Delete Modal"
      >
        <div className="solicitation-modal delete-modal">
          <h2 className="standard__main-title delete-modal__title ">
            STERGEȚI DATELE DUMNEAVOASTRĂ
          </h2>
          <div className="delete-modal__id-box">
            <h3 className="delete-modal__general-info">
              Introduceți email-ul dumneavoastră:
            </h3>
            <form onSubmit={this.deleteData}>
              <input
                required
                className="delete-modal__id-field"
                type="email"
                onChange={this.updateField}
                value={this.state.email}
                placeholder="Email*"
              />
              <div className="delete-modal__recapcha">
                <ReCAPTCHA
                  ref={this.recaptchaRefDeleteModal}
                  sitekey={process.env.REACT_APP_PP_GOOGLE_VERIFICATION_V2}
                  onChange={this.onChange}
                />
              </div>
              <button
                type="submit"
                className="info-form__button delete-modal__submit"
                disabled={this.state.disableSubmit}
              >
                <h5 className="info-form__button-writing">Ștergeți datele</h5>
              </button>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  modalIsOpen: bool.isRequired,
  closeModal: func.isRequired
};

export default DeleteModal;
