import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import ReactPaginate from 'react-paginate';
import { reportsLaw544Service } from '../../../services/ReportsLaw544Service';

class ReportsLaw544 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newReport: {
        title: '',
        document: '',
        date: ''
      },
      existingReports: [],
      deleteReport: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }
  componentWillMount() {
    reportsLaw544Service
      .getAll()
      .then(response => {
        this.setReportState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setReportState = response => {
    let existingReports = this.state.existingReports;
    existingReports = response.aplicability_reports;
    this.setState({
      existingReports
    });
    let pagination = { ...this.state.pagination };
    pagination = response.pagination;
    this.setState({
      pagination
    });
  };
  handlePageClick = currentPage => {
    reportsLaw544Service
      .getAll(currentPage.selected + 1)
      .then(response => {
        this.setReportState(response);
      })
      .catch(error => console.log(error));
  };

  resetForm = () => {
    let newReport = { ...this.state.newReport };
    newReport = {
      title: '',
      document: '',
      date: ''
    };
    this.setState({ newReport });
    this.setState({ disabledDocument: false });
  };
  openDeleteDialog = id => {
    let deleteReport = { ...this.state.deleteReport };
    deleteReport = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteReport });
  };

  closeDeleteDialog = () => {
    let deleteReport = { ...this.state.deleteReport };
    deleteReport = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteReport });
  };
  handleFormSubmit = event => {
    event.preventDefault();

    reportsLaw544Service
      .createOrUpdate(this.state.newReport)
      .then(response => {
        this.resetForm();

        let existingReports = this.state.existingReports;

        let index = existingReports.findIndex(
          existingReports => existingReports.id === response.id
        );

        if (index !== -1) {
          existingReports[index] = response;
        } else {
          existingReports.push(response);
        }

        this.setState({
          existingReports
        });

        this._notificationSystem.addNotification({
          message: `Raportul a fost ${
            this.state.newReport.id ? 'editat' : 'adaugat'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  onDropDocument = documents => {
    let newReport = { ...this.state.newReport };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newReport.document = documents[0];

      self.setState({ newReport });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newReport = { ...this.state.newReport };
    newReport.document = null;

    this.setState({ newReport });
    this.setState({ disabledDocument: false });
  };

  setReportForEdit = report => {
    let newReport = { ...this.state.newReport };
    newReport = Object.assign({}, report);
    this.setState({ newReport });

    if (newReport.document && newReport.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  handleDelete = () => {
    reportsLaw544Service
      .deleteReport(this.state.deleteReport.deleteId)
      .then(() => {
        let existingReports = this.state.existingReports.slice();
        existingReports = existingReports.filter(
          report => report.id !== this.state.deleteReport.deleteId
        );
        this.setState({ existingReports });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Raportul a fost sters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la stergere, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  updateField = event => {
    const field = event.target.name;
    const newReport = this.state.newReport;
    newReport[field] = event.target.value;

    this.setState({
      newReport
    });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Rapoarte
          </h3>
          <h1 className="admin-switch-title default-title">
            Rapoarte de aplicare a Legii nr. 544/2001
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adaugă un raport nou</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu raport*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field title-field"
                      type="text"
                      name="title"
                      value={this.state.newReport.title}
                      onChange={this.updateField}
                    />
                  </label>
                  <h4 className="label-title label-title-provisions">
                    Dată raport*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field date-field"
                      type="date"
                      name="date"
                      value={this.state.newReport.date}
                      onChange={this.updateField}
                    />
                  </label>
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document dispoziție:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newReport.document}
                    documentFilename={this.state.newReport.document_filename}
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newReport.id
                        ? 'Editează Raport'
                        : 'Adaugă Raport'}
                    </button>
                  </div>
                  {Object.values(this.state.newReport) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      size="small"
                      color="secondary"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingReports.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items-title label-title ">
                    Rapoarte existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingReports.map(report => (
                      <div
                        className="existing-items__content-card"
                        key={report.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {report.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setReportForEdit(report)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(report.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteReport.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest raport?"
                    aditionalText="Dupa stergere, raportul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default ReportsLaw544;
