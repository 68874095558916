import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { goodsInventoriesService } from '../../../services/GoodsInventoriesService';
import ReactPaginate from 'react-paginate';

class AdminGoodsInventories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newGoodsInventory: {
        title: '',
        document: '',
        date:''
      },
      existingGoodsInventories: [],
      deleteGoodsInventory: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    goodsInventoriesService
      .getAll()
      .then(jsonResponse => {
        this.setGoodsInventoriesState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    goodsInventoriesService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setGoodsInventoriesState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setGoodsInventoriesState = response => {
    let existingGoodsInventories = {
      ...this.state.existingGoodsInventories
    };
    let pagination = { ...this.state.pagination };
    existingGoodsInventories = response.goods_inventories;
    pagination = response.pagination;

    this.setState({ existingGoodsInventories });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newGoodsInventory = this.state.newGoodsInventory;
    newGoodsInventory[field] = event.target.value;

    this.setState({
      newGoodsInventory
    });
  };

  onDropDocument = documents => {
    let newGoodsInventory = { ...this.state.newGoodsInventory };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newGoodsInventory.document = documents[0];

      self.setState({ newGoodsInventory });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newGoodsInventory = { ...this.state.newGoodsInventory };
    newGoodsInventory.document = null;

    this.setState({ newGoodsInventory });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newGoodsInventory = { ...this.state.newGoodsInventory };
    newGoodsInventory = {
      title: '',
      document: ''
    };

    this.setState({ newGoodsInventory });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newGoodsInventory.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/goods_inventories/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/goods_inventories/${this.state.newGoodsInventory.id}`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingGoodsInventories = this.state.existingGoodsInventories.slice();

        let index = existingGoodsInventories.findIndex(
          existingGoodsInventories =>
            existingGoodsInventories.id === jsonResponse.id
        );

        if (index !== -1) {
          existingGoodsInventories[index] = jsonResponse;
        } else {
          existingGoodsInventories.push(jsonResponse);
        }

        this.setState({ existingGoodsInventories });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Inventarul a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Inventarul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'goods_inventory[title]',
      this.state.newGoodsInventory.title
    );
    formData.append(
      'goods_inventory[add_date]',
      this.state.newGoodsInventory.date
    );

    if (this.state.newGoodsInventory.document) {
      let document = this.state.newGoodsInventory.document;
      formData.append(`goods_inventory[document]`, document, document.name);
    }

    return formData;
  }

  setGoodsInventoryForEdit = newGoodsInventoryDoc => {
    let newGoodsInventory = { ...this.state.newGoodsInventory };
    newGoodsInventory = Object.assign({}, newGoodsInventoryDoc);
    this.setState({ newGoodsInventory });

    if (newGoodsInventory.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteGoodsInventory = { ...this.state.deleteGoodsInventory };
    deleteGoodsInventory = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteGoodsInventory });
  };

  closeDeleteDialog = () => {
    let deleteGoodsInventory = { ...this.state.deleteGoodsInventory };
    deleteGoodsInventory = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteGoodsInventory });
  };

  handleDelete = () => {
    goodsInventoriesService
      .deleteGoodsInventory(this.state.deleteGoodsInventory.deleteId)
      .then(() => {
        let existingGoodsInventories = this.state.existingGoodsInventories.slice();
        existingGoodsInventories = existingGoodsInventories.filter(
          goodsInventory =>
            goodsInventory.id !== this.state.deleteGoodsInventory.deleteId
        );
        this.setState({ existingGoodsInventories });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Inventarul a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Inventar Bunuri
          </h3>
          <h1 className="admin-switch-title default-title">Inventar Bunuri</h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adauga un inventar bunuri nou
                </h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu inventar bunuri*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newGoodsInventory.title}
                  />
                </div>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Data inventar bunuri*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="date"
                    name="date"
                    onChange={this.updateField}
                    value={this.state.newGoodsInventory.date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document inventar bunuri:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newGoodsInventory.document}
                    documentFilename={
                      this.state.newGoodsInventory.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newGoodsInventory.id
                        ? 'Editează inventar bunuri'
                        : 'Adaugă inventar bunuri'}
                    </button>
                  </div>
                  {Object.values(this.state.newGoodsInventory) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingGoodsInventories.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Inventare bunuri existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingGoodsInventories.map(goodsInventory => (
                      <div
                        className="existing-items__content-card"
                        key={goodsInventory.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {goodsInventory.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setGoodsInventoryForEdit(goodsInventory)
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(goodsInventory.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteGoodsInventory.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest document?"
                    aditionalText="Dupa stergere, documentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminGoodsInventories;
