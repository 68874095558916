import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const secondFactorService = {
  generateQrCodeSecret,
  activateSecondFactor,
  twoFaStatus,
  deactivateSecondFactor
};

function generateQrCodeSecret() {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  let url = `${process.env.REACT_APP_API_URL}/api/two_factor_settings/new`;

  return axios.get(url, requestOptionsDelete);
}

function twoFaStatus() {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  let url = `${process.env.REACT_APP_API_URL}/api/two_factor_settings/check_two_fa`;

  return axios.get(url, requestOptionsDelete);
}

function activateSecondFactor(code, password) {
  let url = `${process.env.REACT_APP_API_URL}/api/two_factor_settings`;

  const requestOptions = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json'
    },
    data: {
      two_fa: {
        code,
        password
      }
    }
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  return axios(requestOptions);
}

function deactivateSecondFactor() {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/two_factor_settings`;

  return axios.delete(url, requestOptionsDelete);
}
