import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import DownloadCard from '../../components/DownloadCard/DownloadCard';
import MetaTags from 'react-meta-tags';
import './HandicapAid.css';

class HandicapAid extends Component {
  render() {
    return (
      <div className="standard aid">
        <MetaTags>
          <title>Ajutor Social</title>
          <meta
            name="description"
            content="Ajutor Social Vicovu de Sus. Aici sunt prezentate documentele necesare pentru ajutorul social."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Sus, Vicovu de Sus, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Ajutor Social" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">
              Ajutor Persoane cu Dizabilități{' '}
            </h4>
          </div>
          <h2 className="standard__main-title">
            Ajutor Persoane cu Dizabilități{' '}
          </h2>
        </div>

        <div className="standard__wrapper heating-aids sa-wrapper">
          <div className="heating-aids__left-side sa-left-side">
            <h3 className="standard__submitted-title sa-documents-title standard__civil-status-title">
              Proiect Tehnologie Asistativă
            </h3>
            <img
              src="/documents/TA/proiect_ta.png"
              className="aid__project-img"
              alt="project TA"
            />
            <div
              className="standard__cards sa-cards aid__cards"
              id="cards-wrapper"
            >
              <DownloadCard
                requiredWidth="sa-required-width"
                title="Brosura Tehnologie Asistativă"
                url="/documents/TA/brosura_tehnologie_asistativa.pdf"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="Scrisoare Tehnologie Asistativă"
                url="/documents/TA/scrisoare_ta.pdf"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="Voucher"
                url="/documents/TA/voucher.png"
              />
            </div>
          </div>
          <div className="heating-aids__right-side sa-right-side">
            <h3 className=" standard__submitted-title heating-aids__main-title sa-families-title">
              Acordarea biletelor de transport sau decontare carburant pentru
              persoanele cu dizabilități
            </h3>
            <div className="standard__cards sa-cards">
              <div className="standard__individual-card aid__individual-card">
                <h3 className="standard__card-title sa-title">
                  Informare bilete de transport sau decontare carburant
                </h3>
                <h4
                  className="standard__see-project"
                  onClick={() =>
                    window.open(
                      '/documents/TA/transport_persoane_cu_dizabilitati.pdf'
                    )
                  }
                >
                  Vizualizează Document
                </h4>
              </div>
              <div className="standard__individual-card aid__individual-card">
                <h3 className="standard__card-title sa-title">
                  Informare bilete de transport interurban
                </h3>
                <h4
                  className="standard__see-project"
                  onClick={() =>
                    window.open('/documents/TA/anunt_persoane_dizabilitati.pdf')
                  }
                >
                  Vizualizează Document
                </h4>
              </div>
              <h3
                className="standard__submitted-title heating-aids__main-title sa-families-title"
                id="aid-subtitle"
              >
                Informatii referitoare la incadrarea in grad de handicap a
                copiilor si a persoanelor adulte cu dizabilitati
              </h3>
              <div className="standard__individual-card aid__individual-card">
                <h3 className="standard__card-title sa-title">
                  Program si acte necesare Serviciu de Evaluare complexa a
                  Copilului cu dizabilitati
                </h3>
                <h4
                  className="standard__see-project"
                  onClick={() =>
                    window.open(
                      '/documents/TA/Program-si-necesar-Acte-SEC-COPII.pdf'
                    )
                  }
                >
                  Vizualizează Document
                </h4>
              </div>
              <div className="standard__individual-card aid__individual-card">
                <h3 className="standard__card-title sa-title">
                  Cerere evaluare si incadrare a copilului cu dizabilitati in
                  grad de handicap/acces la servicii de abilitare si reabilitare
                </h3>
                <h4
                  className="standard__see-project"
                  onClick={() =>
                    window.open(
                      '/documents/TA/Cerere-evaluare-complexa-copii-cu-dizabilitati.pdf'
                    )
                  }
                >
                  Vizualizează Document
                </h4>
              </div>
              <div className="standard__individual-card aid__individual-card">
                <h3 className="standard__card-title sa-title">
                  Evaluarea la domiciliu/resedinta a persoanelor adulte in
                  vederea incadrarii in grad si tip de handicap
                </h3>
                <h4
                  className="standard__see-project"
                  onClick={() =>
                    window.open(
                      '/documents/TA/EVALUAREA-LA-DOMICILIU-A-PERSOANELOR-ADULTE.pdf'
                    )
                  }
                >
                  Vizualizează Document
                </h4>
              </div>
              <div className="standard__individual-card aid__individual-card">
                <h3 className="standard__card-title sa-title">
                  Acte necesare in dosarul pentru evaluare in vederea incadrarii
                  in grad de handicap
                </h3>
                <h4
                  className="standard__see-project"
                  onClick={() =>
                    window.open(
                      '/documents/TA/Continutul-dosarului-in-vederea-evaluarii-complexe.pdf'
                    )
                  }
                >
                  Vizualizează Document
                </h4>
              </div>
              <div className="standard__individual-card aid__individual-card">
                <h3 className="standard__card-title sa-title">
                  Cerere de evaluare complexa in vederea incadrarii in grad de
                  handicap
                </h3>
                <h4
                  className="standard__see-project"
                  onClick={() =>
                    window.open('/documents/TA/CERERE-DE-EVALUARE-COMPLEXA.pdf')
                  }
                >
                  Vizualizează Document
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HandicapAid;
