import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.scss';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import ReactPaginate from 'react-paginate';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { acquisitionsCentralizatorService } from '../../../services/AcquisitionsCentralizatorService';

class AdminAcquisitionsCentralizator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newCentralizator: {
        title: '',
        document: '',
        date: '',
        over5000: false
      },
      existingCentralizators: [],
      deleteCentralizator: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }
  componentWillMount() {
    acquisitionsCentralizatorService
      .getAll()
      .then(response => {
        this.setCentralizatorState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setCentralizatorState = response => {
    let existingCentralizators = this.state.existingCentralizators;
    existingCentralizators = response.public_aquisitions_centralizators;
    this.setState({
      existingCentralizators
    });
    let pagination = { ...this.state.pagination };
    pagination = response.pagination;
    this.setState({
      pagination
    });
  };
  handlePageClick = currentPage => {
    acquisitionsCentralizatorService
      .getAll(currentPage.selected + 1)
      .then(response => {
        this.setCentralizatorState(response);
      })
      .catch(error => console.log(error));
  };
  updateField = event => {
    const field = event.target.name;
    let newCentralizator = { ...this.state.newCentralizator };
    newCentralizator[field] = event.target.value;
    this.setState({
      newCentralizator
    });
  };
  resetForm = () => {
    let newCentralizator = { ...this.state.newCentralizator };
    newCentralizator = {
      title: '',
      document: '',
      date: '',
      over5000: ''
    };
    this.setState({ newCentralizator });
    this.setState({ disabledDocument: false });
  };
  openDeleteDialog = id => {
    let deleteCentralizator = { ...this.state.deleteCentralizator };
    deleteCentralizator = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteCentralizator });
  };

  closeDeleteDialog = () => {
    let deleteCentralizator = { ...this.state.deleteCentralizator };
    deleteCentralizator = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteCentralizator });
  };
  handleFormSubmit = event => {
    event.preventDefault();

    acquisitionsCentralizatorService
      .createOrUpdate(this.state.newCentralizator)
      .then(response => {
        this.resetForm();

        let existingCentralizators = this.state.existingCentralizators;

        let index = existingCentralizators.findIndex(
          existingCentralizators => existingCentralizators.id === response.id
        );

        if (index !== -1) {
          existingCentralizators[index] = response;
        } else {
          existingCentralizators.push(response);
        }

        this.setState({
          existingCentralizators
        });

        this._notificationSystem.addNotification({
          message: `Centralizatorul a fost ${
            this.state.newCentralizator.id ? 'editat' : 'adaugat'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  onDropDocument = documents => {
    let newCentralizator = { ...this.state.newCentralizator };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newCentralizator.document = documents[0];

      self.setState({ newCentralizator });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newCentralizator = { ...this.state.newCentralizator };
    newCentralizator.document = null;

    this.setState({ newCentralizator });
    this.setState({ disabledDocument: false });
  };

  setCentralizatorForEdit = centralizator => {
    let newCentralizator = { ...this.state.newCentralizator };
    newCentralizator = Object.assign({}, centralizator);
    this.setState({ newCentralizator });

    if (newCentralizator.document && newCentralizator.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  handleDelete = () => {
    acquisitionsCentralizatorService
      .deleteCentralizator(this.state.deleteCentralizator.deleteId)
      .then(() => {
        let existingCentralizators = this.state.existingCentralizators.slice();
        existingCentralizators = existingCentralizators.filter(
          centralizator =>
            centralizator.id !== this.state.deleteCentralizator.deleteId
        );
        this.setState({ existingCentralizators });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Centralizatorul a fost sters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la stergere, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  handleCheckBox = () => {
    let newCentralizator = { ...this.state.newCentralizator };
    newCentralizator.over5000 = !newCentralizator.over5000;
    this.setState({
      newCentralizator
    });
  };
  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Centralizator Achiziții
          </h3>
          <h1 className="admin-switch-title default-title">
            Centralizator achiziții
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adaugă un centralizator de achiziții
                </h2>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu document*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newCentralizator.title}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată centralizator*
                  </h4>
                  <input
                    className="general-field date-field"
                    type="date"
                    name="date"
                    onChange={this.updateField}
                    value={this.state.newCentralizator.date}
                  />
                </div>
                <div className="consent">
                  <label>
                    <input
                      id="input-checkbox"
                      type="checkbox"
                      name="over5000"
                      checked={this.state.newCentralizator.over5000}
                      onChange={this.handleCheckBox}
                    />
                  </label>
                  <h5>Valoare peste 5000 de euro</h5>
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newCentralizator.document}
                    documentFilename={
                      this.state.newCentralizator.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newCentralizator.id
                        ? 'Editează Document'
                        : 'Adaugă Document'}
                    </button>
                  </div>
                  {Object.values(this.state.newCentralizator) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingCentralizators &&
            this.state.existingCentralizators.length > 0 && (
              <div className="admin-standard-page__existing-items">
                <div className="card">
                  <div className="existing-items">
                    <h2 className="existing-items__title label-title">
                      Centralizatoare achizitii existente
                    </h2>
                    <div className="existing-items__content">
                      {this.state.existingCentralizators.map(centralizator => (
                        <div
                          className="existing-items__content-card"
                          key={centralizator.id}
                        >
                          <div className="card-content provisions-title-content">
                            <h3 className="major-provisions-title">
                              {centralizator.title}
                            </h3>
                          </div>
                          <div className="existing-items__actions-card">
                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.setCentralizatorForEdit(centralizator)
                              }
                            >
                              <h4 className="action-title"> Editează</h4>
                            </div>

                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.openDeleteDialog(centralizator.id)
                              }
                            >
                              <h4 className="action-title delete-button">
                                {' '}
                                Șterge
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <DeletePUGCertificates
                      opened={this.state.deleteCentralizator.openedDialog}
                      handleClose={this.closeDeleteDialog}
                      handleDelete={this.handleDelete}
                      headline="Sunteti sigur ca doriti sa stergeti acest document?"
                      aditionalText="Dupa stergere, acest document nu va mai putea fi recuperata."
                    />
                  </div>
                  {this.state.pagination.last_page > 1 && (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={this.state.pagination.last_page}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                </div>
              </div>
            )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}
export default AdminAcquisitionsCentralizator;
