import React from 'react';
import '../StandardPage/StandardPage.css';
import { publicDebatesInfoService } from '../../services/PublicDebatesInfoService';

class UserPublicDebatesInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      pagination: {}
    };
  }

  componentWillMount() {
    publicDebatesInfoService
      .getAll()
      .then(jsonResponse => {
        this.setPublicDebatesInfoState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    publicDebatesInfoService
      .getAll(selectedPage.selectedPage + 1)
      .then(jsonResponse => {
        this.setPublicDebatesInfoState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setPublicDebatesInfoState = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.public_debates_informations;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });

    console.log(this.state.documents);
  };
  render() {
    return (
      <div className="standard">
        <title>Documente de interes public</title>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Documente</h4>
          </div>
          <h2 className="standard__main-title">
            Informarea asupra problemelor de interes public care urmează să fie
            dezbătute de autoritățile administrației publice locale
          </h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__cards">
            {this.state.documents && this.state.documents.length > 0 ? (
              this.state.documents.map(document => (
                <div
                  key={document.id}
                  className="standard__individual-card sa-title"
                >
                  <h3 className="standard__card-title">{document.title}</h3>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          document.document.url
                        }`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          document.document.url
                        }`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UserPublicDebatesInfo;
