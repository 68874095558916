// @flow

import React, { Component } from 'react';
import './PresentationBox.css';
import Modal from 'react-modal';
import getComponent from '../DinamicComponentLoad/ComponentIndex.jsx';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxHeight: '80%',
    WebkitOverflowScrolling: 'touch'
  }
};

class PresentationBox extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const Component = getComponent(this.props.componentName);

    return (
      <li className="presentation-box clearfix">
        <a className="presentation-box__link" onClick={() => this.openModal()}>
          <svg
            className="presentation-box__link--image"
            viewBox="0 0 100 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <pattern
                id={'img-' + this.props.id}
                patternUnits="userSpaceOnUse"
                width="100"
                height="100"
              >
                <image
                  href={this.props.imageUrl}
                  x="-25"
                  className={
                    this.props.componentName === 'Education' ? 'centered' : ''
                  }
                />
              </pattern>
            </defs>
            <polygon
              points="50 1 95 25 95 75 50 99 5 75 5 25"
              stroke={
                this.props.componentName === 'DemographyArea' ? 'black' : ''
              }
              fill={'url(#img-' + this.props.id + ')'}
            />
          </svg>
          <h2 className="presentation-box__link--title">{this.props.title}</h2>
        </a>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Presentation Modal"
        >
          {Component && <Component closeModal={this.closeModal} />}
        </Modal>
      </li>
    );
  }
}

export default PresentationBox;
