// @flow

import React, { Component } from 'react';
import './Solicitations.css';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import InfoSolicitations from './Info/Info';
import ContactSolicitaions from './Contact/Contact';

class Solicitations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0
    };
  }

  handleChange = (event, tabValue) => {
    this.setState({
      tabValue
    });
  };

  handleChangeIndex = index => {
    this.setState({
      tabValue: index
    });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-page-header">
          <h2 className="admin-page__profile-title">Solicitări</h2>
        </div>
        <Paper className="paper">
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleChange}
            name="tabValue"
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Solicitări informații" />
            <Tab label="Contact" />
          </Tabs>
        </Paper>
        <SwipeableViews
          axis="x"
          index={this.state.tabValue}
          onChangeIndex={this.handleChangeIndex}
        >
          <div className="admin-page__profile-content solicitations-container">
            <InfoSolicitations />
          </div>
          <div className="admin-page__profile-content solicitations-container">
            <ContactSolicitaions />
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

export default withRouter(Solicitations);
