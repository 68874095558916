import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

class DeletePUGCertificates extends Component {
  render() {
    return (
      <Dialog open={this.props.opened} onClose={() => this.props.handleClose()}>
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            {this.props.headline}
          </DialogTitle>
          <DialogContentText id="alert-dialog-description">
            {this.props.aditionalText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleClose()} color="default">
            Anuleaza
          </Button>
          <Button
            onClick={() => this.props.handleDelete()}
            color="secondary"
            autoFocus
          >
            {this.props.confirmButtonMessage
              ? this.props.confirmButtonMessage
              : 'Sterge'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DeletePUGCertificates;
