// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class GeneralPresentation extends Component {
  render() {
    return (
      <div className="standard">
        <div className="about-vicov__container">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <div className="about-vicov__wrapper">
            <div className="about-vicov__dark-section">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Pozitionare Geografica
              </h3>
              <h4 className="standard__generic" id="gp-description">
                Orasul Vicovu de Sus este situate in partea de N_V a judetului
                Suceava. Este strabatut de DN Radauti-Putna , DN
                Marginea-Frontiera si DJ 209 G intersectie Frontiera-Brodina ,
                precum si o serie de strazi. La sud este delimitat de dealuri
                impadurite ce apartin Ocolului Silvic Marginea , la nord de
                fasia de frontier ce delimiteaza teritoriul romanesc de cel al
                Ucrainei , la est se invecineaza cu localitatile Bilca si Vicovu
                de Jos , iar la vest cu comuna Straja si Putna. Orasul este
                strabatut de la vest la est de raul Suceava care desparte
                localitatea in doua , Vicovu de Sus si Bivolarie.
              </h4>
              <h4 className="standard__generic" id="gp-description">
                Vicovu de Sus a fost declarat oras in anul 2004.
              </h4>
            </div>
            <div className="about-vicov__light-section">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Scurt Istoric
              </h3>
              <h4 className="standard__generic" id="gp-description">
                Primul document descoperit pana astazi care mentioneaza satul “
                Jicovul de Sus “ dateaza din 23 mai 1436 , cand voievozii Ilies
                si Stefan daruiesc boierului Stan Babici si fratilor sai
                domeniul satului cu tot cu venitul pe care il are. Fiind prima
                atestare documentara vom cita aproape in intregime continutul
                documentului , mai ales prin faptul ca se porneste o locuire mai
                timpurie a Vicovului de Sus :
              </h4>
            </div>
            <div className="about-vicov__light-section" id="pergament-box">
              <h4 className="pergament standard__generic" id="gp-description">
                “ Din mila lui Dumnezeu , noi Ilie voievod Domnul Tarii Moldovei
                si fratele domniei mele , Stefan Voievod , facem cunoscut cu
                aceasta carte a noastra , tuturor , care vor vedea-o sau o vor
                auzi citindu-se , pentru acest adevarat boier credinciosul
                nostrum pan Stan babici … … I-am miluit pe dansul si fratii sai
                cu deosebita noastra mila si le-am dat lor in tara noastra a
                Moldovei un sat , anume Jicovul de Sus , sa le fie lor uric cu
                tot venitul , nestricat niciodata in veacul veacului , lor si
                copiilor lor si nepotilor lor si stranepotilor lor si
                rastranepotilor lor si la tot neamul lor , care va fi mai
                apropiat. Iar hotarul acestui sat sa fie hotar pe unde din veac
                a existat …”
              </h4>
            </div>
            <div className="about-vicov__light-section">
              <h4 className="standard__generic" id="gp-description">
                {' '}
                Denumirea localitatii se presupune a deriva de la un afluent al
                raului Suceava care poarta denumirea de Sicova. In legatura cu
                denumirea localitatii s-a formulat si ipoteza derivarii dintr-un
                termen latin stiindu-se ca notiunea de asezare rurala (sat) in
                limba latina este vicus.
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralPresentation;
