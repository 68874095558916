// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import Menu from './Menu/Menu';
import './Header.css';
import '../../resources/StandardPage.css';

const Header = () => {
  return (
    <header className="header">
      <HamburgerMenu />
      <Link className="header__link" to={'/'}>
        <div className="header__logo-box">
          <img
            className="header__logo-img"
            src="/images/bannerImages/stema-vicovu-de-jos.png"
            alt="logo-city"
          />
          <div className="header__city-name">
            <h4 className="header__institution">PRIMĂRIA</h4>
            <h3 className="header__institution-name">VICOVU DE JOS</h3>
          </div>
        </div>
      </Link>
      <Menu />
    </header>
  );
};

export default Header;
