import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm';
import AuthenticationService from '../../services/AuthenticationService';
import './Login.css';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import { googleVerificationService } from '../../services/GoogleVerificationService';

class Login extends Component {
  constructor(props) {
    super(props);

    this.authenticationService = new AuthenticationService();

    this.state = {
      errors: {},
      twoFARequired: false,
      user: {
        email: '',
        password: '',
        pin: ''
      }
    };
  }

  componentWillMount() {
    if (this.authenticationService.loggedIn()) {
      this.props.history.push('/admin');
    }

    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute('6LcvaNIUAAAAAKJqInHlCs3aFajRWhmJncnK9MQ7', {
          action: 'login'
        })
        .then(function(token) {
          googleVerificationService.verifyToken(token).then(response => {
            if (response.data.score < 0.5) {
              window.location.assign('https://www.google.com');
            }
          });
        });
    });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  processForm = event => {
    event.preventDefault();

    this.authenticationService
      .login(this.state.user)
      .then(response => {
        if (response.data.request_pin) {
          this.setState({ twoFARequired: true });
        } else {
          this.authenticationService.setToken(response.data.jwt);
          this.props.history.push('/admin');
        }
      })
      .catch(error => {
        if (error.response.status === 422) {
          this._notificationSystem.addNotification({
            message: error.response.data.error,
            level: 'error',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Email sau parola incorecte.',
            level: 'error',
            position: 'tc'
          });
        }
      });
  };

  changeUser = event => {
    const field = event.target.name;
    const user = this.state.user;
    user[field] = event.target.value;

    this.setState({
      user
    });
  };

  render() {
    return (
      <div className="login-page">
        <LoginForm
          onSubmit={this.processForm}
          onChange={this.changeUser}
          errors={this.state.errors}
          user={this.state.user}
          twoFARequired={this.state.twoFARequired}
        />
        <div className="login-page__contact">
          <a className="login-page__contact-item" href="tel:0747774796">
            Telefon: 0747774796
          </a>
          <a
            className="login-page__contact-item"
            href="mailto:support@prometeus-tech.com"
          >
            Email: support@prometeus-tech.com
          </a>
          <a
            className="login-page__contact-item"
            href="https://www.prometeus-tech.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.prometeus-tech.com
          </a>
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default withRouter(Login);
