import React, { Component } from 'react';
import '../../resources/AdminStandardPage.css';
import './ChangePassword.css';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import AuthenticationService from '../../services/AuthenticationService';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: {
        currentPassword: '',
        newPassword: '',
        passwordConfirmation: ''
      }
    };

    this.authenticationService = new AuthenticationService();
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  updateField = event => {
    const field = event.target.name;
    const password = this.state.password;
    password[field] = event.target.value;

    this.setState({
      password
    });
  };

  handlePasswordChange = event => {
    event.preventDefault();

    const headers = {
      Accept: 'application/json'
    };

    if (this.authenticationService.loggedIn()) {
      headers['Authorization'] =
        'Bearer ' + this.authenticationService.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/users/change_password`;
    const formattedData = this.buildFormData();

    fetch(url, {
      method: 'POST',
      body: formattedData,
      headers
    })
      .then(this.handleResponse)
      .then(jsonResponse => {
        this.setState({ currentUser: jsonResponse });

        this._notificationSystem.addNotification({
          message: 'Parola a fost schimbata cu success',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message: error,
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'password[current_password]',
      this.state.password.currentPassword
    );
    formData.append('password[password]', this.state.password.newPassword);
    formData.append(
      'password[password_confirmation]',
      this.state.password.passwordConfirmation
    );

    return formData;
  }

  handleResponse = response => {
    if (response.status === 401) {
      const error = response.statusText;
      this.authenticationService.logout();

      return Promise.reject(error);
    }

    return response.json().then(data => {
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
        }

        const error = (data && data.error) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  };

  render() {
    return (
      <div className="change-password-page">
        <div className="change-password-page__content">
          <h3 className="change-password-page__title">Schimbă parola</h3>
          <form onSubmit={this.handlePasswordChange}>
            <div className="admin-form__field-box">
              <h4 className="label-title label-title-password">
                Parola curentă*
              </h4>
              <div className="admin-form__field change-password-form">
                <input
                  required
                  className="change-password-field general-field"
                  label="Parola Curenta"
                  name="currentPassword"
                  margin="normal"
                  type="password"
                  onChange={this.updateField}
                  value={this.state.password.currentPassword}
                  style={{ width: '50%' }}
                />
              </div>
            </div>
            <div className="admin-form__field-box">
              <h4 className="label-title label-title-password">Parola nouă*</h4>
              <div className="admin-form__field">
                <input
                  required
                  className="change-password-field general-field"
                  label="Parola Noua"
                  name="newPassword"
                  margin="normal"
                  type="password"
                  onChange={this.updateField}
                  value={this.state.password.newPassword}
                  style={{ width: '50%' }}
                />
              </div>
            </div>
            <div className="admin-form__field-box">
              <h4 className="label-title label-title-password">
                Confirmare parolă*
              </h4>
              <div className="admin-form__field">
                <input
                  required
                  className="change-password-field general-field"
                  error={(
                    this.state.password.newPassword !==
                    this.state.password.passwordConfirmation
                  ).toString()}
                  label="Confirmare Parola"
                  name="passwordConfirmation"
                  margin="normal"
                  type="password"
                  onChange={this.updateField}
                  value={this.state.password.passwordConfirmation}
                  style={{ width: '50%' }}
                />
              </div>
            </div>
            <div className="admin-form__submit">
              <button
                type="submit"
                className="general-button change-password-button"
              >
                Schimbă Parola
              </button>
            </div>
          </form>
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default ChangePassword;
