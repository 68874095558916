import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class TransparentEarnings extends Component {
  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Institutii</title>
          <meta
            name="description"
            content="Institutii si institutii subordonate primariei. Aici sunt prezentate institutiile si institutiile subordonate primariei."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Institutii" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Instituții</h4>
          </div>
          <h2 className="standard__main-title">
            Instituții și instituții subordonate
          </h2>
        </div>
        <div className="standard__wrapper">
          <div className="pug-page__content">
            <div className="standard__no-projects-box">
              <p className="standard__no-projects">
                Informații în curs de actualizare.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransparentEarnings;
