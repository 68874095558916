import React, { Component } from 'react';
import ContactModal from '../../../../components/ContactModal/ContactModal';
import { contactService } from '../../../../services/ContactService';
import ReactPaginate from 'react-paginate';

class ContactSolicitaions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactModalIsOpen: false,
      contactModal: {},
      existingContacts: [],
      pagination: {}
    };
  }

  componentWillMount() {
    contactService
      .getAll()
      .then(jsonResponse => {
        this.setContactState(jsonResponse.data);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    contactService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setContactState(jsonResponse.data);
      })
      .catch(error => console.log(error));
  };

  setContactState = response => {
    let existingContacts = this.state.existingContacts;
    let pagination = { ...this.state.pagination };
    existingContacts = response.contacts;
    pagination = response.pagination;

    this.setState({ existingContacts });
    this.setState({ pagination });
  };

  contactOpenModal = solicitation => {
    this.setState({ contactModalIsOpen: true });
    this.setState({ contactModal: solicitation });
  };

  closeModal = () => {
    this.setState({ contactModalIsOpen: false });
  };

  updateStatusValue = event => {
    let contactModal = { ...this.state.contactModal };

    contactModal.status = event.target.value;

    this.setState({ contactModal });
  };

  updateStatus = event => {
    event.preventDefault();

    contactService
      .updateStatus(this.state.contactModal)
      .then(jsonResponse => {
        this._notificationSystem.addNotification({
          message: 'Statusul a fost editat cu success.',
          level: 'success',
          position: 'tc'
        });

        this.setState({ showMailButton: true });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div>
        <table className="mariage-publications__table solicitations-table">
          <thead>
            <tr>
              <td width="100px" className="mariage-publications__table-title">
                Nr.crt.
              </td>
              <td width="100px" className="mariage-publications__table-title">
                ID Solicitare
              </td>
              <td width="100px" className="mariage-publications__table-title">
                Nume contactant
              </td>
              <td width="100px" className="mariage-publications__table-title">
                Status
              </td>
            </tr>
          </thead>
          <tbody>
            {this.state.existingContacts.map((contact, index) => (
              <tr key={contact.id}>
                <td className="mariage-publications__table-title">
                  <h4 className="mariage-publications__wed-name">
                    {index + 1}
                  </h4>
                </td>
                <td
                  width="100px"
                  className="mariage-publications__table-title solicitations-id"
                  onClick={() => this.contactOpenModal(contact)}
                >
                  {contact.id}
                </td>
                <td className="mariage-publications__table-title">
                  {contact.name}
                </td>

                <td className="mariage-publications__table-title">
                  <h4 className="mariage-publications__wed-name">
                    {contact.status || '-'}
                  </h4>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {this.state.pagination.last_page > 1 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pagination.last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}

        <ContactModal
          eventObject={this.state.contactModal}
          modalIsOpen={this.state.contactModalIsOpen}
          closeModal={this.closeModal}
          updateStatus={this.updateStatus}
        />
      </div>
    );
  }
}

export default ContactSolicitaions;
