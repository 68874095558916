// @flow

const componentTypes = {
  GeneralPresentation: require('../AboutVicov/GeneralPresentation/GeneralPresentation')
    .default,
  AdministrativeArea: require('../AboutVicov/AdministrativeArea/AdministrativeArea')
    .default,
  DemographyArea: require('../AboutVicov/DemographyArea/DemographyArea')
    .default,
  AccessWays: require('../AboutVicov/AccessWays/AccessWays').default,
  WaterSupply: require('../AboutVicov/WaterSupply/WaterSupply').default,
  Services: require('../AboutVicov/Services/Services').default,
  HealthSocialCare: require('../AboutVicov/HealthSocialCare/HealthSocialCare')
    .default,
  Education: require('../AboutVicov/Education/Education').default,
  Economy: require('../AboutVicov/Economy/Economy').default,
  Agriculture: require('../AboutVicov/Agriculture/Agriculture').default,
  Environment: require('../AboutVicov/Environment/Environment').default
};

export default componentTypes;
