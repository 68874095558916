import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const mariagePublicationsService = {
  getAll,
  search,
  deleteMarriagePublication
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/mariage_publications/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function search(searchMariagePublication, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/mariage_publications/search?husband=${
    searchMariagePublication.husband
  }&wife=${searchMariagePublication.wife}&archiveYear=${
    searchMariagePublication.archiveYear
  }&page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function deleteMarriagePublication(marriagePublicationId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/mariage_publications/${marriagePublicationId}`;

  return axios.delete(url, requestOptionsDelete);
}
