import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const workAgendaService = {
  getAll,
  createOrUpdate,
  deleteWorkAgenda,
  search
};

const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/work_agendas/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function createOrUpdate(newWorkAgenda) {
  const createRequestOptions = {
    method: newWorkAgenda.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newWorkAgenda)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    createRequestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/work_agendas${
    newWorkAgenda.id ? `/${newWorkAgenda.id}` : ''
  }`;

  return fetch(url, createRequestOptions).then(
    responseHandleService.handleResponse
  );
}

function createBodyRequest(workAgenda) {
  let formData = new FormData();

  formData.append('work_agenda[official_name]', workAgenda.officialName);

  const document = workAgenda.document;

  if (document) {
    if (document.url) {
      formData.append(`work_agenda[document]`, document.url);
    } else {
      formData.append(`work_agenda[document]`, document, document.name);
    }
  }

  return formData;
}

function search(searchWorkAgendas, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/work_agendas/search?official_name=${
    searchWorkAgendas.official_name
  }&archiveYear=${searchWorkAgendas.archiveYear}&page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function deleteWorkAgenda(workAgendaId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/work_agendas/${workAgendaId}`;

  return axios.delete(url, requestOptionsDelete);
}
