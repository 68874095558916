import React, { Component } from 'react';
import './Users.css';
import { usersService } from '../../services/UsersService';
import { errorService } from '../../services/ErrorService';
import ReactPaginate from 'react-paginate';
import DeletePUGCertificates from '../Dialogs/DeleteDialog/DeleteDialog';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      pagination: {},
      deleteUser: {
        openedDialog: false,
        deleteId: 0
      }
    };
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;

    usersService
      .getUsers()
      .then(response => {
        let users = this.state.users;

        users = response.data.users.slice();

        this.setState({ users });

        let pagination = { ...this.state.pagination };
        pagination = response.data.pagination;
        this.setState({
          pagination
        });
      })
      .catch(error => {
        if (error.response.status === 401) {
          errorService.handleUnauthorized();
          this.props.history.push('/admin/login');
        }
      });
  }

  openDeleteDialog = id => {
    let deleteUser = { ...this.state.deleteUser };
    deleteUser = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteUser });
  };

  closeDeleteDialog = () => {
    let deleteUser = { ...this.state.deleteUser };
    deleteUser = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteUser });
  };

  handleDelete = () => {
    usersService
      .deleteUser(this.state.deleteUser.deleteId)
      .then(() => {
        let users = this.state.users.slice();
        users = users.filter(
          user => user.id !== this.state.deleteUser.deleteId
        );
        this.setState({ users });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Utilizatorul a fost sters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        if (error.response.status === 409) {
          this._notificationSystem.addNotification({
            message: error.response.data.error,
            level: 'error',
            position: 'tc'
          });

          return;
        }

        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la stergere, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div className="change-password-page">
        {this.state.users && this.state.users.length > 0 && (
          <div className="admin-standard-page__existing-items">
            <div className="card">
              <div className="existing-items">
                <h2 className="existing-items__title label-title">
                  Utilizatori existenti
                </h2>
                <div className="existing-items__content">
                  {this.state.users.map(user => (
                    <div className="existing-items__content-card" key={user.id}>
                      <div className="card-content provisions-title-content">
                        <h3 className="major-provisions-title">
                          {user.username} - {user.email}
                        </h3>
                      </div>
                      <div className="existing-items__actions-card">
                        <div
                          className="provisions-title-content"
                          onClick={() => this.openDeleteDialog(user.id)}
                        >
                          <h4 className="action-title delete-button">
                            {' '}
                            Șterge
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <DeletePUGCertificates
                  opened={this.state.deleteUser.openedDialog}
                  handleClose={this.closeDeleteDialog}
                  handleDelete={this.handleDelete}
                  headline="Sunteti sigur ca doriti sa stergeti acest user?"
                  aditionalText="Dupa stergere, acest user nu va mai putea fi recuperat."
                />
              </div>
              {this.state.pagination.last_page > 1 && (
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pagination.last_page}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
          </div>
        )}
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default Users;
