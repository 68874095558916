import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const programsService = {
  getAll,
  createOrUpdate,
  deleteProgram
};
const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/programs/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function createOrUpdate(newProgram) {
  const createRequestOptions = {
    method: newProgram.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newProgram)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    createRequestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/programs${
    newProgram.id ? `/${newProgram.id}` : ''
  }`;

  return fetch(url, createRequestOptions).then(
    responseHandleService.handleResponse
  );
}

function createBodyRequest(program) {
  let formData = new FormData();

  formData.append('program[title]', program.title);

  const document = program.document;

  if (document) {
    if (document.url) {
      formData.append(`program[document]`, document.url);
    } else {
      formData.append(`program[document]`, document, document.name);
    }
  }

  return formData;
}

function deleteProgram(programId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/programs/${programId}`;

  return axios.delete(url, requestOptionsDelete);
}
