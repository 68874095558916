// @flow

import React, { Component } from 'react';
import './MenuAdmin.css';
import MenuAdminData from './menu-admin.json';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

class MenuAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: MenuAdminData
    };

    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(menuId) {
    let menu = this.state.menu;

    menu.forEach(menuItem => {
      if (menuItem.id === menuId) {
        menuItem.open = !menuItem.open;
      }
    });

    this.setState({ menu });
  }

  render() {
    let iconsObject = {
      faUserCheck: faUserCheck,
      faInfoCircle: faInfoCircle,
      faMapMarkerAlt: faMapMarkerAlt,
      faCog: faCog,
      faUserFriends: faUserFriends,
      faGavel: faGavel,
      faEuroSign: faEuroSign,
      faChartLine: faChartLine,
      faUsers: faUsers,
      faStar: faStar
    };

    return (
      <div className="menu-admin">
        <div className="admin-header">
          <img
            className="symbol-town"
            src="/images/bannerImages/stema-vicovu-de-jos.png"
            alt="Logo"
          />
          <h1 className="admin-title-header">Admin Vicovu de Jos</h1>
        </div>
        {this.state.menu.map(menuCategory => (
          <ul key={menuCategory.id} className="menu-admin__list">
            <div className="category-icon">
              <FontAwesomeIcon
                icon={iconsObject[menuCategory.icon]}
                className="category-name-icon"
              />
              <li
                className="menu-admin__list-item"
                onClick={() => this.togglePanel(menuCategory.id)}
              >
                {menuCategory.name}
              </li>
            </div>
            {menuCategory.open
              ? menuCategory.items.map(menuItem => (
                  <li
                    key={menuItem.id}
                    className="menu-admin__list-item  menu-admin__list-item--submenu"
                  >
                    <Link to={menuItem.url}>{menuItem.name}</Link>
                  </li>
                ))
              : undefined}
          </ul>
        ))}
      </div>
    );
  }
}

export default MenuAdmin;
