// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class Economy extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Economia
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Structura agentilor economici, in functie de domeniul de
                activitate
              </h3>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text text--final">NR CRT.</div>
                  <div className="text text--final">Domeniul de activitate</div>
                  <div className="text text--final">Numar Societati</div>
                  <div className="text text--final">
                    Pondere in totalul societatilor
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">1.</div>
                  <div className="text">Comert cu ridicata si cu amanuntul</div>
                  <div className="text">19</div>
                  <div className="text">15.44</div>
                </div>
                <div className="table__row">
                  <div className="text">2.</div>
                  <div className="text">
                    Repararea autovehiculelor si motocicletelor
                  </div>
                  <div className="text">4</div>
                  <div className="text">3.25</div>
                </div>
                <div className="table__row">
                  <div className="text">3.</div>
                  <div className="text">Hoteluri si restaurante</div>
                  <div className="text">8</div>
                  <div className="text">6.50</div>
                </div>
                <div className="table__row">
                  <div className="text">4.</div>
                  <div className="text">
                    Activitati profesionale, stiintifice si tehnice
                  </div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">5.</div>
                  <div className="text">
                    Agricultura, Silvicultura si Pescuit
                  </div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">6.</div>
                  <div className="text">Industria prelucratoare</div>
                  <div className="text">67</div>
                  <div className="text">54.47</div>
                </div>
                <div className="table__row">
                  <div className="text">7.</div>
                  <div className="text">
                    Distributia apei; Salubritate; Gestionarea deseurilor,
                    activitati de decontaminare
                  </div>
                  <div className="text">5</div>
                  <div className="text">4.06</div>
                </div>
                <div className="table__row">
                  <div className="text">8.</div>
                  <div className="text">Transport si depozitare</div>
                  <div className="text">5</div>
                  <div className="text">4.06</div>
                </div>
                <div className="table__row">
                  <div className="text">9.</div>
                  <div className="text">
                    Activitati de servicii administrative si activitati de
                    servicii suport
                  </div>
                  <div className="text">11</div>
                  <div className="text">8.94</div>
                </div>
                <div className="table__row">
                  <div className="text">10.</div>
                  <div className="text">Informatii si comunicatii</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">11.</div>
                  <div className="text">Constructii</div>
                  <div className="text">6</div>
                  <div className="text">4.87</div>
                </div>
                <div className="table__row">
                  <div className="text">11.</div>
                  <div className="text">
                    Intermedieri financiare si Asigurari
                  </div>
                  <div className="text">3</div>
                  <div className="text">2.42</div>
                </div>
                <div className="table__row">
                  <div className="text">12.</div>
                  <div className="text">
                    Activitati de spectacole culturale si recreative
                  </div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">13.</div>
                  <div className="text">Alte activitati de servicii</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text" />
                  <div className="text text--final">TOTAL</div>
                  <div className="text text--final">123</div>
                  <div className="text" />
                </div>
              </div>
              <h4
                className="standard__generic dd-description"
                id="gp-description"
              >
                Din totalul celor 123 societăți active, 32% sunt
                microintrepinderi, respectiv au maxim 9 angajați şi o cifră de
                afaceri anuală şi/sau active totale anuale de maxim 2 milioane
                EUR.
              </h4>
            </div>
            <div className="about-vicov__right-content">
              <h4
                className="standard__generic dd-description"
                id="gp-description"
              >
                Evoluția societăților din orașul Vicovu de Jos în perioada
                2011-2014
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text text--final">Tipuri de societati</div>
                  <div className="text text--final">2014</div>
                  <div className="text text--final">2013</div>
                  <div className="text text--final">2012</div>
                  <div className="text text--final">2011</div>
                </div>
                <div className="table__row">
                  <div className="text">Microintreprinderi</div>
                  <div className="text">26</div>
                  <div className="text">20</div>
                  <div className="text">29</div>
                  <div className="text">17</div>
                </div>
                <div className="table__row">
                  <div className="text">Intreprinderi mici</div>
                  <div className="text">97</div>
                  <div className="text">99</div>
                  <div className="text">82</div>
                  <div className="text">87</div>
                </div>
                <div className="table__row">
                  <div className="text">Intreprinderi inactive</div>
                  <div className="text">17</div>
                  <div className="text">22</div>
                  <div className="text">14</div>
                  <div className="text">8</div>
                </div>
                <div className="table__row table__row--green">
                  <div className="text text--final">
                    Total societati existente, din care:
                  </div>
                  <div className="text">123</div>
                  <div className="text">119</div>
                  <div className="text">111</div>
                  <div className="text">104</div>
                </div>
                <div className="table__row">
                  <div className="text"> - noi infiintate</div>
                  <div className="text">21</div>
                  <div className="text">8</div>
                  <div className="text">6</div>
                  <div className="text">17</div>
                </div>
                <div className="table__row">
                  <div className="text"> - active</div>
                  <div className="text">123</div>
                  <div className="text">119</div>
                  <div className="text">111</div>
                  <div className="text">104</div>
                </div>
              </div>
              <h4
                className="standard__generic dd-description"
                id="gp-description"
              >
                Principalii agenți economici care își desfășoară activitatea în
                orașul Vicovu de Jos sunt prezentați în tabelul de mai jos.
              </h4>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">Nr. crt.</div>
                  <div className="text text--final">Denumire societate</div>
                  <div className="text text--final">Localitatea</div>
                  <div className="text text--final">Nr. angajati</div>
                  <div className="text text--final">Domeniul de activitate</div>
                </div>
                <div className="table__row">
                  <div className="text">1.</div>
                  <div className="text">SC MARELBO PROD COM SRL</div>
                  <div className="text">
                    Vicovu de Jos Bivolarie str. Marelbo
                  </div>
                  <div className="text">484</div>
                  <div className="text">Productie incaltaminte</div>
                </div>
                <div className="table__row">
                  <div className="text">2.</div>
                  <div className="text">SC NICOLIS PROD COM SRL</div>
                  <div className="text">
                    Vicovu de Jos Bivolarie str. Victoriei
                  </div>
                  <div className="text">430</div>
                  <div className="text">Productie incaltaminte</div>
                </div>
                <div className="table__row">
                  <div className="text">3.</div>
                  <div className="text">SC STINOR PROD COM SRL</div>
                  <div className="text">Vicovu de Jos str. Calea Cernauti</div>
                  <div className="text">28</div>
                  <div className="text">Comert cu amanuntul</div>
                </div>
                <div className="table__row">
                  <div className="text">4.</div>
                  <div className="text">SC CUCO DOR SRL</div>
                  <div className="text">
                    Vicovu de Jos Bivolarie str. Stefan cel Mare
                  </div>
                  <div className="text">22</div>
                  <div className="text">Depozit materiale constructii</div>
                </div>
                <div className="table__row">
                  <div className="text">5.</div>
                  <div className="text">SC MOCASINO SHOES SRL</div>
                  <div className="text">Vicovu de Jos Bivolarie str. Garii</div>
                  <div className="text">74</div>
                  <div className="text">Productie incaltaminte</div>
                </div>
                <div className="table__row">
                  <div className="text">6.</div>
                  <div className="text">SC PROLACT SRL</div>
                  <div className="text">Vicovu de Jos str. Laurei</div>
                  <div className="text">56</div>
                  <div className="text">
                    Prelucrare lapte si produse lactate
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">7.</div>
                  <div className="text">SC SERVICE AUTO SRL</div>
                  <div className="text">Vicovu de Jos Bivolarie str. Garii</div>
                  <div className="text">22</div>
                  <div className="text">Reparatii auto</div>
                </div>
                <div className="table__row">
                  <div className="text">8.</div>
                  <div className="text">SC EURO TEHNIC SRL</div>
                  <div className="text">Vicovu de Jos str. Calea Cernauti</div>
                  <div className="text">14</div>
                  <div className="text">Peco</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Economy;
