import decode from 'jwt-decode';
import axios from 'axios';
import moment from 'moment';

export default class AuthenticationService {
  constructor(domain) {
    this.domain = domain || process.env.REACT_APP_API_URL;
  }

  login = user => {
    const requestOptionsPost = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    const data = {
      api_user: {
        email: user.email,
        password: user.password
      }
    };

    if (user.pin) {
      data.api_user.otp_attempt = user.pin;
    }

    let url = `${process.env.REACT_APP_API_URL}/api/login`;

    return axios.post(url, data, requestOptionsPost);
  };

  getProfile = () => {
    return decode(this.getToken());
  };

  fetch = (url, options) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json'
    };

    if (this.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.getToken();
    }

    return fetch(url, {
      headers,
      ...options
    })
      .then(this._checkStatus)
      .then(response => response.json());
  };

  loggedIn() {
    const token = this.getToken();
    return token !== 'undefined' && !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (moment.unix(decoded.exp) < moment()) {
        return true;
      }

      return false;
    } catch (err) {
      return false;
    }
  }

  setToken(token) {
    localStorage.setItem('auth_token', token);
  }

  getToken() {
    return localStorage.getItem('auth_token');
  }

  logout() {
    localStorage.removeItem('auth_token');
  }

  _checkStatus(response) {
    if (response.stats < 200 && response.status >= 300) {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }

    return response;
  }

  handleResponse(response) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      this.authenticationService.logout();

      const error = (response && response.error) || response.statusText;
      return Promise.reject(error);
    }

    return response;
  }
}
