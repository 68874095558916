// @flow

import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

import { compose, withProps } from 'recompose';

const ContactMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAaamqPezKtwlEXQMrZPAPZeTshPushkZA&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `500px`, width: '100%' }} />,
    containerElement: <div className="google-location" />,
    mapElement: <div style={{ height: `500px`, width: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: props.lat, lng: props.long }}
  >
    <Marker position={{ lat: props.lat, lng: props.long }} />
  </GoogleMap>
));

export default ContactMap;
