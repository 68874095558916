// @flow

import React, { Component } from 'react';
import './SubMenu.css';
import { Link } from 'react-router-dom';

class SubMenu extends Component {
  render() {
    return (
      <div
        className={
          'menu__wrapper ' +
          (this.props.menu.fixedHeight
            ? `fixed-height${this.props.menu.fixedHeight} `
            : '') +
          (this.props.buttonHovered === this.props.menu.id ? 'active' : '')
        }
      >
        {this.props.menu.subMenu &&
          this.props.menu.subMenu.map(subMenuItem => (
            <div key={subMenuItem.id} className="submenu__column">
              <div className="submenu__title">
                <Link
                  to={subMenuItem.url}
                  target={subMenuItem.target}
                  onClick={() => this.props.closeMenu()}
                >
                  <span> {subMenuItem.title} </span>
                </Link>
              </div>
              <ul className="submenu__links">
                {subMenuItem.subMenu &&
                  subMenuItem.subMenu.map(link =>
                    link.target ? (
                      <li key={link.id} className="submenu__link">
                        <a
                          onClick={() => {
                            this.props.closeMenu();
                            window.open(link.url, '_blank');
                          }}
                          className="title-item-asd"
                        >
                          {link.title}
                        </a>
                      </li>
                    ) : (
                      <li key={link.id} className="submenu__link">
                        <Link
                          to={link.url}
                          onClick={() => this.props.closeMenu()}
                        >
                          <span> {link.title} </span>
                        </Link>
                      </li>
                    )
                  )}
              </ul>
            </div>
          ))}
        <div className="columnSeparator" />
      </div>
    );
  }
}

export default SubMenu;
